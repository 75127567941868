import React from "react";
import useStore from "./store";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import CircleProgressbarWithInfo from "components/CircleProgressbar";
import { shallow } from "zustand/shallow";
import { Skeleton } from "@mui/material";
import { getCommentAndColor } from "./helpers";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '70%',
    minHeight: '280px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  status: {
    width: '100%',
    padding: 20,
    background: 'linear-gradient(284.04deg, #BE93C5 0%, #7BC6CC 100%)',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }, progressContainer: {
    width: '49%',
    border: '2px solid',
    borderColor: theme.palette.neutral['clr-200'],
    borderRadius: '4px',
    padding: '20px',
    height: '100%',
  },
  queFeedback: {
    width: '100%',
    padding: 10,
    border: '2px solid',
    borderColor: theme.palette.neutral['clr-200'],
    borderRadius: '4px',
    marginTop: '20px',
  }, queContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
    flexWrap: 'wrap',
    gap: '4px',
  }, question: {
    width: '28px',
    height: '28px',
    borderRadius: '4px',
    backgroundColor: theme.palette.danger['clr-700'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
  }, rectSkeleton: {
    margin: '5px 0px', borderRadius: '4px',
  }, circularSkeleton: {
    margin: '5px 0px',
    height: 164, width: 164,
  }, skeletonWrapper: {
    display: 'flex', alignItems: 'center', flexDirection: 'column'
  }
}));

function LowScoreFeedback({ lowScoredQuestions = [], score=0, category='speech' }) {
  const classes = useStyles();

  const data = getCommentAndColor(score);

  return (
    <Box className={classes.queFeedback}>

      {
        lowScoredQuestions.length ?
          <>
            <Typography variant="body01-bold" color='neutral.clr-600'>
              Improve in these lowest scored questions to boost your score
            </Typography>

            <Box className={classes.queContainer}>
              {
                lowScoredQuestions.map(({ questionNo }, index) => (
                  <Box className={classes.question} key={index}>
                    <Typography variant='body02-bold' color='shades.clr-white-900'>
                      Q{questionNo}
                    </Typography>
                  </Box>
                ))
              }
            </Box>
          </> 
          :
          <Typography variant="body01-bold" color='neutral.clr-600'>
            {
              data[category]
            }
          </Typography>
      }
    </Box>
  );
}

export default function WizardScores({ showComparison, contentChange, speechChange, loading = false }) {
  const classes = useStyles();

  const content = useStore(state => state.content, shallow);
  const speech = useStore(state => state.speech, shallow);
  const questions = useStore(state => state.questions, shallow);

  const { speechLowScores, contentLowScores } = React.useMemo(() => {
    const speechLowScores = [];
    const contentLowScores = [];

    const totalAttemptedQuestions = questions.reduce(
      (count, item) => count + (!item.isError ? 1 : 0)
      , 0
    );

    if(totalAttemptedQuestions>=5){
      for (let question of questions) {
        if (!question.isError) {
          const speechScore = question?.communication?.ratings?.OVERALL;
          const contentScore = question?.content?.ratings?.OVERALL;
          
          if (speechScore < 50)
            speechLowScores.push({ questionNo: question.serialNumber + 1, score: speechScore });
          
          if (contentScore < 50)
            contentLowScores.push({ questionNo: question.serialNumber + 1, score: contentScore });
        }
      }
    }

    const sortedSpeechLowScores = speechLowScores
      .sort((a, b) => a.score - b.score)
      .slice(0, 3);

    const sortedContentLowScores = contentLowScores
      .sort((a, b) => a.score - b.score)
      .slice(0, 3);

    return { speechLowScores: sortedSpeechLowScores, contentLowScores: sortedContentLowScores };
  }, [questions]);

  return (
    <Box className={classes.container}>
      <Box className={classes.progressContainer}>
        {
          loading ?
            <Box className={classes.skeletonWrapper}>
              <Skeleton variant='circular' className={classes.circularSkeleton} animation='wave' />
              <Skeleton
                variant='rectangular' height={30} width={200}
                className={classes.rectSkeleton} animation='wave'
              />
              <Skeleton
                variant='rectangular' height={30} width={200}
                className={classes.rectSkeleton} animation='wave'
              />
              <Skeleton variant='rectangular' height={100} width='100%'
                className={classes.rectSkeleton} animation='wave'
              />
            </Box> :
            <>
              <CircleProgressbarWithInfo
                fontVariant="h4-semiBold"
                size={164}
                value={speech}
                category='speech'
                label='Speech score'
                tooltip='Your overall Speech Score of this attempt'
                percentageChange={speechChange}
                showComparison={showComparison}
              />

              <LowScoreFeedback lowScoredQuestions={speechLowScores} score={speech} category="speech"/>
            </>
        }
      </Box>

      <Box className={classes.progressContainer}>

        {
          loading ?
            <Box className={classes.skeletonWrapper}>
              <Skeleton variant='circular' className={classes.circularSkeleton} animation='wave' />
              <Skeleton
                variant='rectangular' height={30} width={200}
                className={classes.rectSkeleton} animation='wave'
              />
              <Skeleton
                variant='rectangular' height={30} width={200}
                className={classes.rectSkeleton} animation='wave'
              />
              <Skeleton variant='rectangular' height={100} width='100%'
                className={classes.rectSkeleton} animation='wave'
              />
            </Box> :
            <>
              <CircleProgressbarWithInfo
                fontVariant="h4-semiBold"
                size={164}
                value={content}
                label='Content score'
                category='content'
                tooltip='Your overall Content Score of this attempt'
                percentageChange={contentChange}
                showComparison={showComparison}
              />

              <LowScoreFeedback lowScoredQuestions={contentLowScores} score={content} category="content"/>
            </>
        }
      </Box>
    </Box>
  );
}