import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { 
  Box, DialogTitle,
  Divider,
  FormControlLabel,
  FormLabel, Radio,
  RadioGroup, Typography
} from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';
import lodash from 'lodash';

import DialogHeader from 'components/DialogHeader';
import { useLoading, useSnackbar } from 'contexts';
import { addPathwaysToUser, getFilteredPathways } from 'services/pathwayService';
import { Session, getTwoDigitNo } from 'utils';
import { getTags } from 'services/tagService';
import { TagCategories } from 'components/Pathway/constants';
import PathwaySelectionCards, { PathwaySelectionCardSkeleton } from 'components/PathwaySelectionCards';
import HorizontalScrollNavigator from 'components/HorizontalScrollNavigator';
import { NoResultComponent } from 'components/Home/TodoList';
import RequestPathwayDialog from './RequestPathwayDialog';
import { SimpleSelect } from 'components/CustomSelectFields';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme=>({
  content: {
    height: '600px', overflow: 'auto', 
    padding: '0px', 
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  filterRow: {
    width: '100%', height: '140px', 
    display: 'flex', alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4,7)
  },
  form: {
    display: 'flex', alignItems: 'center',
    gap: theme.spacing(6)
  },
  radioForm: {
    display: 'flex', alignItems: 'center', 
    gap: theme.spacing(4) 
  },
  filterPathways: {
    padding: theme.spacing(3,5),
    marginTop: theme.spacing(2),
  },
  pathwaysHeader: { 
    display: 'flex', justifyContent: 'space-between', 
    padding: theme.spacing(3,0)
  },
  footer: { 
    padding: theme.spacing(4,8), 
    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    boxShadow: '0px 0px 4px 0px #00000040',
  },
  jobRoleType: {
    ...theme.typography['h6-medium'],
    color: theme.palette.neutral['clr-900']
  },
  radioLabel: {
    ...theme.typography['body01-semiBold'],
    textTransform: 'capitalize',
    '& .MuiFormControlLabel-label': { fontSize: '14px' }
  },
}));

const filterTags = (tagArray, category) => 
    tagArray
    .filter(tag => tag.category === category)
    .map((tag) => ({ id: tag._id, option: tag.name, ...tag }));

export default function AddPathwayDialog({ 
  openDialog, onClose, onSubmit=()=>{}, batch, ...props 
}) {
  const classes = useStyles();
  const theme = useTheme();
  const snackbar = useSnackbar();
  const loading = useLoading();

  const [loader, setLoader] = React.useState(false);
  const [open, setOpen] = React.useState(openDialog);
  const [jobRoles, setJobRoles] = React.useState([])
  const [jobRoleTypes, setJobRoleTypes] = React.useState([])
  const [companies, setCompanies] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const [pathways, setPathways] = React.useState([]);
  const [selectedPathways, setSelectedPathways] = React.useState([]);
  const [openRequestPathway, setOpenRequestPathway] = React.useState(false);
  
  const handleClose = () => {
    onClose(false);
  };

  const handlePathwaySelect= (id) => {
    if(selectedPathways.includes(id)) {
      setSelectedPathways(selectedPathways.filter(pathway=>  pathway !== id));
    } else {
      setSelectedPathways([...selectedPathways, id]);
    }
  };

  const handleChangeFilters = (name, data) => {
    if (data) {
        setFilters(prevFilters => ({ ...prevFilters, [name]: data._id }));
    } else {
        setFilters(prevFilters => {
            const { [name]: _, ...newFilters } = prevFilters;
            return newFilters;
        });
    }
  };

  const handleSubmit = async () => {
    try{
      loading.show();
      const requestData = {
        user: Session.userId,
        batch: batch?._id,
        pathways: selectedPathways
      }
      await addPathwaysToUser(requestData);

      snackbar.success("Pathway(s) added Successfully");
      onSubmit();
      handleClose();
    }catch (error) {
      console.log(error);
      snackbar.error("Uh Oh! Unable to add Pathway(s)", true);
    } finally{
      loading.hide();
    }
  };

  const getJobRoleAndCompany = async () => {
    try{
      const {tags} = await getTags({
          category: [TagCategories.JOB_ROLE, TagCategories.COMPANY, TagCategories.JOB_ROLE_TYPE]
        });

      setJobRoles(lodash.uniqBy(filterTags(tags, TagCategories.JOB_ROLE), 'code'));
      setCompanies(filterTags(tags, TagCategories.COMPANY));
      setJobRoleTypes(filterTags(tags, TagCategories.JOB_ROLE_TYPE));
    }catch (error) {
      console.log(error);
      snackbar.error("Uh Oh! Unable to get job Roles", true);
    }
  };

  React.useEffect(()=> {
    (async() => {
      try{
        setSelectedPathways([]);
        setLoader(true);
        
        const data = await getFilteredPathways(filters);
        setPathways(data);
      }catch (error) {
        console.log(error);
        snackbar.error("Uh Oh! Unable to get Pathways", true);
      } finally{
        setLoader(false);
      }
    })();
  },[open, filters]);

  React.useEffect(()=>{
    getJobRoleAndCompany();
  },[open]);

  React.useEffect(()=>{
    setFilters({});
    setOpen(openDialog);
  },[openDialog]);

  return (
    <>
    <Dialog
      onClose={handleClose}
      maxWidth={'lg'}
      fullWidth={true}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 0}}>
        <DialogHeader
          handleClose={handleClose} 
          title="Add New Pathway to Your Dashboard"
          subtitle="You can add multiple Pathways at once"
          sx={{backgroundColor : theme.palette.neutral['clr-50']}}
        />
      </DialogTitle>

      {/* content */}
      <DialogContent className={classes.content}>
        <Box>
          <Box className={classes.filterRow}>
            <Box style={{display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h6-bold'>Apply&nbsp;Filters</Typography>
              <Box className={classes.radioForm}>
                <FormLabel id="job-role-type" className={classes.jobRoleType}>I am looking for</FormLabel>
                <RadioGroup
                  row 
                  aria-labelledby="job-role-type"
                  name="jobRoleType"
                  value={filters?.jobRoleType || null}
                  onChange={(e) => handleChangeFilters('jobRoleType', {_id: e.target.value})}
                >
                  {
                    jobRoleTypes.map((item)=>{
                      return (
                        <FormControlLabel 
                          key={item._id} value={item._id} 
                          control={<Radio />} 
                          label={<span className={classes.radioLabel}>{item.name} Job</span>}
                        />
                      )
                    })
                  }
                </RadioGroup>
              </Box>
            </Box>
            <Box className={classes.form}>
              <SimpleSelect
                value={filters?.jobRole}
                options={jobRoles}
                label='Job Role'
                sx={{width: '200px'}}
                menuStyle= {{width: '200px'}}
                onSelect={(data)=> handleChangeFilters('jobRole', {_id: data?._id})}
              />
              <SimpleSelect
                defaultValue={filters?.company}
                options={companies}
                label='Target Company'
                sx={{width: '200px'}}
                menuStyle= {{width: '200px'}}
                onSelect={(data)=> handleChangeFilters('company', {_id: data?._id})}
              />
            </Box>
          </Box>
          <Divider/>
          <Box className={classes.filterPathways}>
            <Box className={classes.pathwaysHeader}>
              <Typography variant='h5-semiBold'>My Dream Pathway's</Typography>
              <Typography 
                variant='body01-link'
                style={{ cursor: 'pointer' }}
                color='primary.main'
                onClick={()=>setOpenRequestPathway(true)}
              > 
                Didn't find pathway you want?
              </Typography>
            </Box>
            <Divider/>
            <Box mt={8}>
              <HorizontalScrollNavigator
                  NoResultComponent={NoResultComponent}
                  NoResultComponentProps={{ 
                    clearFilters: () => {setFilters({})}, 
                    description: "Couldn't find your Pathway" 
                  }}
                  childrensLength={pathways.length}
              >
                {

                  loader ?
                    Array.from(new Array(3)).map(()=>{
                      return <PathwaySelectionCardSkeleton/>
                    })
                  :
                  pathways.map((pathway,i)=>{
                    return (
                      <PathwaySelectionCards
                        key={i}
                        pathway={pathway}
                        handlePathwaySelect={handlePathwaySelect}
                        selected={ selectedPathways.includes(pathway._id) }
                      />
                    )
                  })
                }
              </HorizontalScrollNavigator>
            </Box>
          </Box>
          
        </Box>
        <Box className={classes.footer}>
          <Typography variant='h6-semiBold'>
            Your Selected Pathways: &nbsp;
            <span style={{ color: theme.palette.primary.main}}>
            {getTwoDigitNo(selectedPathways.length)}
            </span>&nbsp;
          </Typography>
          <CustomButton
            size='medium'
            variant='contained' 
            onClick={handleSubmit}
            disabled={!selectedPathways.length}
          >
            Add Pathway(s)
          </CustomButton>
        </Box>
      </DialogContent>
    </Dialog>
    <RequestPathwayDialog 
      openDialog={openRequestPathway} 
      onClose={setOpenRequestPathway}
      closeAddPathway={handleClose}
    />
    </>
  );
}