import Question from './Question';
import useStore from './store';
import TopNavigation from './TopNavigation';
import SampleAnswerForm from './SampleAnswerForm';
import PersonalizedFeedback from './PersonalizedFeedback';
import Box from '@mui/material/Box';

import { makeStyles } from '@mui/styles';
import { defaults } from './store';
import { useState } from 'react';
import { useMemo } from 'react';
import { Typography } from '@mui/material';
import ParameterwiseFeedback from './ParameterwiseFeedback';
import { CategoryMap, SubCategoryMap, templateTypes } from './constants';

const useStyles = makeStyles((theme) => ({
    analysisContainer: {
        border: '2px solid',
        borderColor: theme.palette.neutral['clr-200'],
        borderRadius: '4px',
        backgroundColor: theme.palette.shades['clr-white-900'],
        padding: theme.spacing(5,3),
        display: 'flex',
    },
    errorState: {
        borderRadius: '4px',
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-500'],
        width: '100%',
        height: '280px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: '10px',
    },
    personalizedAns: {
        border: '2px dotted',
        borderColor: '#009970',
        borderRadius: '4px',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
    }
}));

const defaultTagState = {
  RELEVANCE: [
    {
      category: 'RELEVANCE',
      subCategory: 'JARGON_WORDS',
      value: [],
    }
  ],
  WORD_CHOICE: [
    {
      category: 'WORD_CHOICE',
      subCategory: 'INFORMAL_WORDS',
      value: [],
    },
    {
      category: 'WORD_CHOICE',
      subCategory: 'RARE_USED_WORDS',
      value: [],
    }
  ],
}

export function PersonalizedAnswer() {
  const classes = useStyles();
  const activeQuestion = useStore(state => state.questions?.[state.currentQuestion]);

  return activeQuestion?.personalizedAnswer ? (
      <Box className={classes.personalizedAns} mb={4}>
          <Typography variant="h6-semiBold">
              {'Your improved response'}
          </Typography>
          <Typography variant='body01-medium' color='neutral.clr-900' mt={1}>
              {activeQuestion?.personalizedAnswer}
          </Typography>
      </Box>
  ) : null;
}

const commParametersOrder = ['FLUENCY', 'CONFIDENCE', 'DELIVERY', 'WORD_CHOICE'];

const sortByOrder = (arr, order) => {
  return arr.sort((a, b) => {
      const indexA = order.indexOf(a.category);
      const indexB = order.indexOf(b.category);
      return indexA - indexB;
  });
};

function CategoriesAnalysis({ question }) {
    const [overall, setOverall] = useState({content:0, speech: 0});
    const activeQuestion = useStore(state => state.questions?.[state.currentQuestion]);

    const {content, communication} = useMemo(
      () => {
        const {
            communication: { ratings: _commRatings, tags: commTags },
            content: { ratings: _contRatings, tags: contTags },
        } = question?.isError ? defaults.questions[0] : question || { communication: {}, content: {} };

        const commRatings = { ..._commRatings };
        const contRatings = { ..._contRatings };

        setOverall({
          content: contRatings.OVERALL,
          speech: commRatings.OVERALL,
        })

        delete commRatings.OVERALL;
        delete contRatings.OVERALL;

        const contTagMap = contTags?.reduce((acc, contTag) => {
          acc[contTag.category] = (acc[contTag.category]) 
            ? [...acc[contTag.category], { ...contTag }] 
            : [{ ...contTag }]

          return acc;
        }, {});

        const content = Object.keys(contRatings).map(
            (key) => ({ 
              category: key, score: contRatings[key],
              tags: key === CategoryMap.RELEVANCE 
                  ? (contTagMap[key] || defaultTagState.RELEVANCE) 
                  : null
            })
        );

        const commTagMap = commTags?.reduce((acc, commTag) => {
            acc[commTag.category] = (acc[commTag.category]) 
              ? [...acc[commTag.category], { ...commTag }] 
              : [{ ...commTag }]

            return acc;
        }, {});

        let wordChoiceData = defaultTagState.WORD_CHOICE;
        for(let i=0;i<(Object.keys(commTagMap?.['WORD_CHOICE'] || {}) || []).length;i++){
          const tag = Object.values(commTagMap?.['WORD_CHOICE'])[i];

          if(tag.subCategory === SubCategoryMap.INFORMAL_WORDS){
            wordChoiceData[0].value = tag?.value;
          }
          if(tag.subCategory=== SubCategoryMap.RARE_USED_WORDS){
            wordChoiceData[1].value = tag?.value;
          }
        }

        const communication = Object.keys(commRatings).map(
            (key) => ({
                category: key,
                score: commRatings[key],
                tags: key === CategoryMap.CONFIDENCE 
                  ? null :  key === CategoryMap.WORD_CHOICE 
                    ? wordChoiceData : (commTagMap[key] || [])
            })
        );

        return { content, communication: sortByOrder(communication, commParametersOrder) }
    }, [question, activeQuestion]);

    return (
      <>
        <ParameterwiseFeedback
            id='speech-analysis'
            parameter='Speech'
            height='330px'
            featureData={communication}
            overall={overall.speech}
        />
        <ParameterwiseFeedback
            id='content-analysis'
            parameter='Content'
            featureData={content}
            overall={overall.content}
        />
      </>
    );
}

function ErrorState({ statusCode }) {
    const classes = useStyles();

    return (
        <Box className={classes.errorState} id='tips-for-improvement-container'>
            <img
                src='https://assets.languify.in/images/mic-disable.svg'
                alt='Not Recorded Error'
            />
            <Typography variant='h6-medium' color='neutral.clr-500' lineHeight={'26px'}>
                {
                    (statusCode === 301) ?
                        <>Having skipped this question, <br />
                            we are unable to provide you with personalized feedback.</> :
                        <>As your response is not recorded properly,
                            <br /> we are unable to provide you with personalized feedback.</>
                }

            </Typography>
        </Box>
    );
}

function Analysis(props) {
    const classes = useStyles();
    const activeQuestion = useStore(state => state.questions?.[state.currentQuestion]);
    const template = useStore(state => state.template);

    return (
        <>
            <TopNavigation />
            <br />
            <Question interactiveDemo={props.interactiveDemo || false} />
            <br />
            {(activeQuestion?.isError) ?
                <ErrorState statusCode={activeQuestion.statusCode} /> :
                <>
                  <Box className={classes.analysisContainer}>
                      <PersonalizedFeedback />
                  </Box>
                  <Box id='parameters-feedback'>
                    <CategoriesAnalysis question={activeQuestion}/>
                  </Box>
                </>
            }
            <br />

            {
              template.type === templateTypes.PATHWAY || template.type === templateTypes.PERSONALIZED ?
                <PersonalizedAnswer/> : null
            }

            <SampleAnswerForm />

        </>
    );
}

export default Analysis;