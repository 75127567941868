import * as React from 'react';
import Typography from '@mui/material/Typography';
import InfoIconWithTooltip, { ClickableTooltip } from 'components/InfoIconWithTooltip';
import ParameterTag from './ParameterTag';
import Chip from 'components/Chip';
import { shallow } from 'zustand/shallow';
import useStore from './store';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { Box, Divider, Skeleton } from '@mui/material';
import { getCommentAndColor } from './helpers';
import { makeStyles } from '@mui/styles';
import {
  Categories, CategoriesDescription, CategoriesMetrics,
  CategoryMap, CategoryScoreTag, CategoryTips, InterviewQuestionTypes, PaceEnergyDynamicTips, SubCategories,
  SubCategoriesDescription, SubCategoryData, SubCategoryMap
} from './constants';
import { useCallback, useState, useEffect } from 'react';
import { round } from 'utils';
import { getBlogsByScore } from 'services/blogService';
import { useServices } from 'contexts';
import { useNavigateWithClient } from 'hooks';
import { LinkButton } from 'components/CustomButton';

const useStyles = makeStyles((theme) => ({
  'blogContainer': {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'column',
    width: 200,
    height: 150,
    borderRadius: '4px',
    marginBottom: '5px',
    cursor: 'pointer',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  'imageSection': {
    width: 200,
    height: 100,
    borderRadius: '4px',
    border: '2px solid',
    borderColor: theme.palette.neutral['clr-200'],
    overflow: 'hidden',
    '&:hover': {
      borderColor: theme.palette.primary['clr-100']
    },
  },
  blogTitle: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  mistakeCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: '4px',
    width: 'fit-content',
    minWidth: 'fit-content',
    backgroundColor: '#F4F0FF',
    ...theme.typography['body01-semiBold'],
  },
  divider: {
    borderColor: theme.palette.neutral['clr-200'],
    margin: theme.spacing(2, 0),
  },
  subCategory: {
    display: 'flex',
    alignItems: 'center',
    gap: 4
  },
  root: {
    border: '2px solid',
    marginTop: '20px', borderRadius: '4px',
    borderColor: theme.palette.neutral['clr-400'],
    padding: theme.spacing(5, 4)
  },
  contentBody: {
    display: 'flex', justifyContent: 'space-between',
    width: '100%', gap: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      minHeight: 'content-fit',
      height: '500px',
    }
  },
  parameterHead: {
    marginBottom: theme.spacing(3),
  },
  featureBtns: {
    width: '30%', display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(2)
    }
  },
  featureBtn: {
    width: '100%', display: 'flex', justifyContent: 'space-between',
    border: '2px solid',
    borderColor: theme.palette.neutral['clr-500'],
    padding: theme.spacing(3.5, 2.5),
    borderRadius: '4px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '48%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(2),
      padding: theme.spacing(1.5)
    }
  },
  scoreAndGrade: {
    width: '100%',
    display: 'flex', justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gap: theme.spacing(2)
    }
  },
  selectedFeatureBtn: {
    borderColor: theme.palette.primary.main,
    boxShadow: '0px 2px 8px 0px #1961BF99',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      '&::after': {
        content: '""',
        position: 'absolute',
        right: '-20px',
        zIndex: 10,
        top: '50%',
        transform: 'translateY(-50%) rotate(90deg)',
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid transparent',
        borderBottom: `10px solid ${theme.palette.primary.main}`
      }
    }
  },
  featureContent: {
    width: '70%',
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-400'],
    height: '100%',
    borderRadius: '4px',
    padding: theme.spacing(4),
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F0F5FA',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BFCAD9',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#8692A3',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#636F7F',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  tipsBox: {
    border: '2px solid',
    borderColor: theme.palette.secondary['clr-600'],
    padding: theme.spacing(2),
    borderRadius: '4px',
  },
  successMessageBox: {
    padding: theme.spacing(4, 0),
    display: 'flex', gap: theme.spacing(4),
    alignItems: 'center'
  },
  fluencyCatBtn: {
    padding: '2px 8px',
    display: 'flex', gap: theme.spacing(1),
    alignItems: 'center',
    border: '2px solid',
    borderRadius: '4px',
    borderColor: theme.palette.neutral['clr-400'],
    cursor: 'pointer'
  },
  selectedCatBtn: {
    borderColor: theme.palette.primary.main,
  },
  warning: {
    display: 'flex',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.danger['clr-100'],
    padding: theme.spacing(2),
    borderRadius: '4px',
    marginBottom: theme.spacing(2),
    alignItems: 'center'
  }
}));

const SuccessTip = ({ message }) => {
  const classes = useStyles();
  return (
    <Box className={classes.successMessageBox}>
      <img
        src='https://assets.languify.in/images/happy-star-5oQwy83AOj.svg'
        alt='succes-start-img'
      />
      <Typography variant='body01-bold' color={'primary.main'}>{message}</Typography>
    </Box>
  )
}

const Tips = ({ featureData }) => {
  const classes = useStyles();
  const [isGuestApp, isDemoApp] = useStore(
    state => [state.isGuestApp, state.isDemoApp], shallow
  );

  const { tipsData = [], successMessage } = React.useMemo(() => {
    let tips = [], successMessage = null;
    switch (featureData.category) {
      case CategoryMap.FLUENCY:
        if(featureData.score > 90) break;
        tips.push(
          'Try to minimize above fluency mistakes, as it will help you improve your overall fluency.'
        )
        break;

      case CategoryMap.DELIVERY:
        let pace = 'ideal', energy = 'ideal';
        featureData.tags.forEach(feature => {
          if (feature.subCategory === SubCategoryMap.PACE) {
            pace = SubCategoryData[SubCategoryMap.PACE].getTag(feature.value);
          } else {
            energy = SubCategoryData[SubCategoryMap.ENERGY].getTag(feature.value);
          }
        });
        if (pace === 'ideal' && energy === 'ideal') {
          successMessage = PaceEnergyDynamicTips[`${pace}_${energy}`]
        } else {
          tips.push(PaceEnergyDynamicTips[`${pace}_${energy}`]);
        }
        break;

      case CategoryMap.WORD_CHOICE:
        const grad = getCommentAndColor(featureData.score).label;
        const  tip = CategoryTips?.[featureData.category]?.[grad];
        if (grad === 'VERY_GOOD' && !featureData.tags[0].value.length) {
          successMessage = tip;
        } else {
          tips.push(...tip)
        }
        break;

      case CategoryMap.CONFIDENCE:
      case CategoryMap.SIMILARITY:
      case CategoryMap.RELEVANCE:
      case CategoryMap.LOGIC_FLOW:
        const grade = getCommentAndColor(featureData.score).label;
        const generateTip = CategoryTips?.[featureData.category]?.[grade];
        if (grade === 'VERY_GOOD') {
          successMessage = generateTip;
        } else {
          tips.push(...generateTip)
        }
        break;

      default:
        return { tipsData: [], successMessage: null };;
    }

    return { tipsData: tips, successMessage };
  }, [featureData]);

  const handleScroll = () => {
    const box = document.getElementById(`${featureData.category}-feature`);
    if (box) {
      box.scrollTop = box.scrollHeight;
    }
  };

  return tipsData.length ? (
    <Box className={classes.tipsBox}>
      <Typography variant='body01-semiBold'>How to improve:-</Typography>
      <ul style={{ margin: '8px 0px 0px 0px', paddingLeft: '30px' }}>
        {
          tipsData.map((tip, i) => {
            return <li key={i}><Typography variant='body01-semiBold'>{tip}</Typography></li>
          })
        }
        <li>
          <Typography variant='body01-semiBold'>
            For in-depth learning you can read the blog. &nbsp;
          </Typography>
          {
            (isGuestApp && !isDemoApp) ? null :
              <LinkButton style={{ padding: 0, display: 'inline', whiteSpace: 'nowrap' }} onClick={handleScroll}>
                Read now
              </LinkButton>
          }
        </li>
      </ul>
    </Box>
  ) : (
    !successMessage ? null : <SuccessTip message={successMessage} />

  )
}

function Resources({ blogs = [], loading = false }) {
  const classes = useStyles();
  const navigate = useNavigateWithClient();

  const isGuestApp = useStore(state => state.isGuestApp, shallow);

  const handleClick = useCallback((blogId) => navigate(`/blog/${blogId}`), []);

  return (
    blogs.length ? <>
      <Box display='flex' alignItems='center' gap={1} mt={4} mb={2}>
        <img src='https://assets.languify.in/images/star-badge.png' alt='start-badge' />
        <Typography variant='body01-bold' color='success.clr-700' id='tips-for-improvement'>
          Level up with these resources!
        </Typography>
      </Box>
      {
        loading ?
          <Box>
            <Skeleton variant="rectangular" width={200} height='90px' />
            <Skeleton variant="rectangular" width={200} height='15px' style={{ marginTop: '4px' }} />
          </Box>
          :
          blogs.map(({ title, imageUrl, _id }, i) => (
            <ClickableTooltip
              key={i}
              title={
                <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <WarningAmberIcon style={{ color: '#664100' }} />
                  <Typography variant='body02-semiBold'>
                    Only available to<br />registered students
                  </Typography>
                </Box>
              }
              placement='top'
              {...(!isGuestApp ? { open: false } : {})}
            >
              <Box className={classes.blogContainer} key={i}
                onClick={() => {
                  if (!isGuestApp) handleClick(_id)
                }}
              >
                <Box>
                  <Typography variant='body02-bold'>
                    Blog
                  </Typography>
                </Box>
                <Box width='40%' className={classes.imageSection}>
                  <img src={
                    !isGuestApp
                      ? imageUrl
                      : "https://assets.languify.in/images/padlock.png"
                  }
                    alt="Blog"
                    height={'100%'}
                    width={'100%'}
                  />
                </Box>
                <Box>
                  <Typography variant='body02-bold' color={'primary.clr-300'} className={classes.blogTitle}>
                    {title}
                  </Typography>
                </Box>
              </Box>
            </ClickableTooltip>
          ))
      }
    </> : null
  )
}

function TagLine({ featureData }) {
  const { tag, color, img } = React.useMemo(() => {
    const data = getCommentAndColor(featureData.score);
    const tag = data.label === 'VERY_GOOD' ? null
      : CategoryScoreTag?.[featureData.category]?.[data.label];

    return { tag, color: data.color, img: data.img }
  }, [featureData]);

  return tag && (
    <Box my={2} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <img src={img} alt='emoji' />
      <Typography variant='body01-bold' style={{ color }}>
        {tag}
      </Typography>
    </Box>
  )
}

function SubFeatures({ tags, category }) {
  const classes = useStyles();

  const [selectedCat, setSelectedCat] = useState();

  const filterCategories = React.useMemo(() => Object.keys(SubCategories), []);

  const { fluencySubCategory = [], categoryValueMap = {}, showFluencyParameter = true } = React.useMemo(() => {
    let fluencySubCategory = [];
    let categoryValueMap = {};
    let showFluencyParameter = false;
    if (category === CategoryMap.FLUENCY) {
      showFluencyParameter = tags.length >= 3;
    
      for (let i = 0; i < tags.length && !showFluencyParameter; i++) {
        categoryValueMap[tags[i].subCategory] = tags[i];
    
        if (tags.length < 3) {
          const { subCategory, count, normPercentage } = tags[i];
          if (
            (subCategory === SubCategoryMap.PAUSES && count) ||
            (subCategory === SubCategoryMap.DISFLUENCY && normPercentage)
          ) {
            showFluencyParameter = true;
            fluencySubCategory.push(subCategory);
          }
        }
      }
    }

    setSelectedCat(fluencySubCategory[0]);

    return { fluencySubCategory, categoryValueMap, showFluencyParameter };
  }, [tags, category]);

  return category === CategoryMap.FLUENCY ?
      showFluencyParameter ? (
        <Box my={4}>
          <Typography variant='body01-bold' color='neutral.clr-600'>
            Click to see why your score was affected
          </Typography>
          <Box mt={1} style={{ display: 'flex', gap: '4px' }}>
            {
              fluencySubCategory.map((subCat, i) => {
                return (
                  <Box
                    className={`${classes.fluencyCatBtn} ${subCat === selectedCat && classes.selectedCatBtn}`}
                    onClick={() => setSelectedCat(subCat)}
                    key={i}
                  >
                    <Typography variant='body02-bold' color={subCat === selectedCat ? 'primary.main' : 'neutral.clr-900'}>
                      {SubCategories[subCat]}
                    </Typography>
                    <InfoIconWithTooltip
                      placement='right'
                      title={SubCategoriesDescription[subCat]}
                      fontSize='14px'
                      style={{ color: subCat === selectedCat && '#003E8C' }}
                    />
                  </Box>
                )
              })
            }
          </Box>
          <ParameterTag tag={categoryValueMap[selectedCat]} />
        </Box>
      ) 
      : <SuccessTip message={'Well done! Your speech was quite fluent in this response. Keep practicing to maintain this level. Additionally, you can read the blog below to further enhance your performance!'} />
    :
    (
      tags.map((tag, index) => (
        filterCategories.includes(tag.subCategory) ?
          <Box key={index} my={2}>
            {
              category === CategoryMap.DELIVERY ? null :
                <Box className={classes.subCategory} mt={3} >
                  <Typography variant='body01-bold' color='neutral.clr-700'>
                    {SubCategories[tag.subCategory]}
                  </Typography>
                  <InfoIconWithTooltip
                    placement='right'
                    title={SubCategoriesDescription[tag.subCategory]}
                    fontSize='small'
                  />
                </Box>
            }
            <ParameterTag tag={tag} />
          </Box>
          : null
      ))
    );
}

export default function ParameterwiseFeedback(
  { parameter, height = '240px', featureData, overall, id='analysis' }
) {
  const classes = useStyles();
  const service = useServices();

  const currentQuestion = useStore(state => state.questions?.[state.currentQuestion]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(featureData[0].category);
  const [disableSimilarity, setDisableSimilarity] = useState(false);
  const [isGuestApp, isDemoApp] = useStore(state => [state.isGuestApp, state.isDemoApp], shallow);

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const parseScore = (value) => {
    const normalizedValue = round(value / 10);
    return normalizedValue < 10 ? `0${normalizedValue}/10` : `${normalizedValue}/10`;
  }

  useEffect(() => {
    let isMounted = true;

    setLoading(true);
    (async () => {
      try {
        const blogFilters = {
          category: selectedCategory,
          score: round(featureData[selectedCategoryIndex].score / 10),
        }

        const { blogs } = await service.callService(
          getBlogsByScore, blogFilters
        );

        if (isMounted) setBlogs(blogs);
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) setLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [featureData, selectedCategoryIndex, selectedCategory, currentQuestion]);

  useEffect(() => {
    if (
      currentQuestion.type === InterviewQuestionTypes[1]._id &&
      selectedCategory === CategoryMap.SIMILARITY
    ) {
      setDisableSimilarity(true);
    } else {
      setDisableSimilarity(false);
    }
  }, [parameter, selectedCategory, selectedCategoryIndex, currentQuestion]);

  return (
    <Box className={classes.root} id={id}>
      <Box className={classes.parameterHead}>
        <Typography variant='h6-semiBold'>
          {parameter} performance in this question: &nbsp;
          <span style={{ color: getCommentAndColor(overall).fontColor }}>
            {overall}/100
          </span>
        </Typography>
        <Divider sx={{ color: '#8692A3', marginTop: '12px' }} />
      </Box>
      <Box height={height} className={classes.contentBody} >
        <Box className={classes.featureBtns}>
          {
            featureData.map((d, index) => {
              let { color, bgColor, fontColor, tag } = getCommentAndColor(d.score);
              if (currentQuestion.type === InterviewQuestionTypes[1]._id && d.category === CategoryMap.SIMILARITY) {
                color = '#4E5661'
                bgColor = '#E1E3E5'
                tag = '--'
              }
              return (
                <Box
                  className={
                    `${classes.featureBtn} 
                  ${index === selectedCategoryIndex && classes.selectedFeatureBtn}`
                  }
                  onClick={() => {
                    setSelectedCategoryIndex(index);
                    setSelectedCategory(d.category);
                  }}
                  key={index}
                >
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body01-bold' mr={1} style={{ whiteSpace: 'nowrap' }}>
                      {Categories[d.category]}
                    </Typography>
                    <InfoIconWithTooltip
                      placement='right'
                      title={CategoriesDescription[d.category]}
                      fontSize='small'
                    />
                  </Box>
                  <Box className={classes.scoreAndGrade}>
                    <Typography variant='body01-bold' ml={2} color={fontColor || color}>
                      {currentQuestion.type === InterviewQuestionTypes[1]._id &&
                        d.category === CategoryMap.SIMILARITY ? '--/10' : parseScore(d.score)
                      }
                    </Typography>
                    <Chip content={tag} bgColor={bgColor} color={fontColor || color} />
                  </Box>
                </Box>
              )
            })
          }
        </Box>

        {/* feature content */}
        <Box className={classes.featureContent} id={`${selectedCategory}-feature`}>
          <Typography variant='body01-bold'>
            {
              CategoriesMetrics[selectedCategory]
            }
          </Typography>

          <Divider sx={{ margin: '8px 0px 12px 0px', borderColor: '#CCD4DE' }} />

          {
            (featureData[selectedCategoryIndex].tags)
              ? <SubFeatures
                  tags={featureData[selectedCategoryIndex].tags}
                  category={selectedCategory}
                />
              : disableSimilarity ?
                <Box className={classes.warning}>
                  <img src='https://assets.languify.in/images/alert-tringle-icon.svg' alt='alert' />
                  <Typography variant='body02-bold'>
                    Similarity can not be measured for Situational questions!
                  </Typography>
                </Box>
                : <TagLine featureData={featureData[selectedCategoryIndex]} />
          }

          {
            disableSimilarity ?
              <Box className={classes.tipsBox}>
                <Typography variant='body02-bold'>
                  Situational questions depend on personal interpretation and context, leading to varied responses. In contrast, technical questions have specific, factual answers that align closely with ideal responses.
                </Typography>
              </Box>
              :
              <Tips featureData={featureData[selectedCategoryIndex]} />
          }
          {
            disableSimilarity || (isGuestApp && !isDemoApp) ? null :
              <>
                <Divider sx={{ margin: '8px 0px 12px 0px', borderColor: '#CCD4DE' }} />
                <Resources blogs={blogs} loading={loading} />
              </>
          }
        </Box>
      </Box>
    </Box>
  );
}
