import React, { useState } from 'react';
import {shallow} from 'zustand/shallow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import PendingIcon from '@mui/icons-material/Pending';
import { makeStyles } from '@mui/styles';
import AnswerWithHighlights from './AnswerWithHighlights';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CenterFlexBox from 'components/CenterFlexBox';
import useStore from './store';
import { useSnackbar } from 'contexts';
import { addBookmark, deleteBookmark } from 'services';
import { BootstrapTooltip } from 'components/InfoIconWithTooltip';
import Chip from 'components/Chip';
import { DemoApplications } from './constants';
import { scrollToElementById } from 'utils';

const useStyles = makeStyles((theme) => ({
    root: {
        border: '2px solid',
        borderColor: theme.palette.neutral['clr-200'],
        borderRadius: '4px',
        backgroundColor: theme.palette.shades['clr-white-900'],
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
    },
    head: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    warning: {
        backgroundColor: theme.palette.warning['clr-100'],
        padding: theme.spacing(3),
        borderRadius: '4px',
    },
    lowTag: {
      padding: '6px 12px',
      backgroundColor: theme.palette.danger['clr-100'],
      width: 'fit-content',
      marginTop: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    }
}));

function BookmarkButton({
    hasBookmark, onBookmark = () => { }, onBookmarkRemove = () => { }, loading
}) {

    return (
        hasBookmark ?
          <BootstrapTooltip title={"Remove Bookmark"} placement='top'>
            <IconButton onClick={onBookmarkRemove}
                style={{ backgroundColor: '#F4F0FF' }} size='small'
                disabled={loading} id='bookmark-question'
            >

                {loading ?
                    <PendingIcon style={{ color: '#8270DB' }} fontSize='inherit' /> :
                    <BookmarkIcon style={{ color: '#8270DB' }} fontSize='inherit' />
                }
            </IconButton>
          </BootstrapTooltip>
          :
          <BootstrapTooltip title={"Bookmark Questions"} placement='top'>
            <IconButton onClick={onBookmark}
                style={{ backgroundColor: '#F4F0FF' }} size='small' disabled={loading}
                id='bookmark-question'
            >
                {
                    loading ?
                        <PendingIcon style={{ color: '#8270DB' }} fontSize='inherit' /> :
                        <BookmarkBorderIcon style={{ color: '#8270DB' }} fontSize='inherit' />
                }
            </IconButton>
          </BootstrapTooltip>
    )
}

function Question(props) {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const theme = useTheme();

    const currentQuestion = useStore(state => state.currentQuestion, shallow);
    const question = useStore(state => state.questions[state.currentQuestion], shallow);
    const questions = useStore(state => state.questions, shallow);
    const setQuestions = useStore(state => state.setQuestions, shallow);
    const attemptId = useStore(state => state.attemptId);
    const template = useStore(state => state.template);
    const isGuestApp = useStore(state => state.isGuestApp);
    const demoApplication = useStore(state => state.demoApplication);
    const allAttempts = useStore(state => state.allAttempts, shallow);
    const content = useStore(state => state.content, shallow);
    const speech = useStore(state => state.speech, shallow);
    const [loading, setLoading] = useState(false);
    const [lowScored, setlowScore] = useState([false, false]); //considering [speach, content] values

    const currentAttemptNo = React.useMemo(() => {
      const attemptIndex = allAttempts.findIndex(attempt => attempt._id === attemptId);
      return allAttempts.length - attemptIndex;
    }, [attemptId, allAttempts]);

    const addToBookmark = async () => {
        setLoading(true);
        try {
            const bookmark = await addBookmark({
                interviewQuestion: question._id,
                interviewAnswer: question?.answerId,
                interviewTemplate: template._id,
                interviewAttempt: attemptId,
                attemptNumber: currentAttemptNo
            });
            if (bookmark) {
                const updatedQuestions = [...questions];
                updatedQuestions[currentQuestion].bookmark = bookmark._id;
                setQuestions(updatedQuestions);
            }
            snackbar.success("Bookmark Added");
        } catch (error) {
            console.error("add Bookmark error :- ", error);
            snackbar.error(error.message, "error");
        } finally {
            setLoading(false);
        }
    };

    const removeBookmark = async () => {
        setLoading(true);
        try {
            const bookmarkRemoved = await deleteBookmark(question.bookmark);
            if (bookmarkRemoved) {
                const updatedQuestions = [...questions];
                updatedQuestions[currentQuestion].bookmark = null;
                setQuestions(updatedQuestions);
            }
            snackbar.success("Bookmark Removed");
        } catch (error) {
            console.error("remove Bookmark error :- ", error);
            snackbar.error(error.message, "error");
        } finally {
            setLoading(false);
        }
    };

    const { skipped, error } = React.useMemo(() => {
        return {
            skipped: question?.isError && question.statusCode === 301,
            error: question?.isError,
        }
    }, [question]);

    const handleScroll = () => {
      scrollToElementById(lowScored[1] ? 'content-analysis' : 'speech-analysis');
    }

    React.useEffect(()=>{
      setlowScore([speech<50, content<50]);
    }, [speech, content, currentQuestion]);

    return (
      <Box className={classes.root} id='question-section'>
        <Box className={classes.head} mb={1} id='bookmark-question-container'>
          <Box display='flex' gap={theme.spacing(2)} alignItems='center'>
            <Typography color='primary.clr-200' variant='h6-semiBold'>
                Question
            </Typography>
            {
              demoApplication !== DemoApplications.inprep
                ? <Chip
                    content={`${question?.type} question`}
                    bgColor={theme.palette.primary['clr-50']}
                    color={theme.palette.primary.main}
                    sx={{ textTransform: 'capitalize', padding: theme.spacing(1,2), borderRadius: '4px' }}
                  />
                : null
            }
          </Box>

          {
            !isGuestApp &&
            <BookmarkButton
                hasBookmark={question?.bookmark}
                onBookmark={addToBookmark}
                onBookmarkRemove={removeBookmark}
                loading={loading}
            />
          }
        </Box>

        <Typography variant="body01-bold" color='neutral.clr-900' mb={3}>
            {question?.question}
        </Typography>

        {
          (!error) && <AnswerWithHighlights question={question} show={false} />
        }
        {
          !error && (lowScored[0] || lowScored[1]) &&
          <Box className={classes.lowTag}>
            <Typography variant="body01-bold" color='danger.clr-700'>
              Your&nbsp;
              {
              ['speech', 'content'].map((tag, i)=> {
                if(lowScored[i]){
                  return lowScored[i-1] ?  ` and ${tag}` : tag;
                }
                return '';
              })
              }
              &nbsp;score is low.&nbsp;
              <span
                onClick={()=> handleScroll()}
                style={{ color: '#003E8C', textDecoration: 'underline', cursor: 'pointer' }}
              >
                Lets know why!
              </span>
            </Typography>
            <span
              onClick={()=> handleScroll()}
              style={{ cursor: 'pointer' }}
            >
              <img src='https://assets.languify.in/images/scroll-down-icon.png' alt='scroll-down'/>
            </span>
          </Box>
        }

        {
          (error) &&
          <CenterFlexBox justifyContent='flex-start' gap={1} className={classes.warning}>
            <WarningAmberRoundedIcon style={{ color: 'warning.clr-700' }} fontSize='small' />
            <Typography variant="body01-bold" color='warning.clr-700'>
              {
                (skipped) ?
                    'You skipped this question!' :
                    'It seems your answer was not recorded properly!'
              }
            </Typography>
          </CenterFlexBox>
        }

      </Box>
    );
}

export default Question;