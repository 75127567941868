import React, { useState, useEffect } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import { Box, Divider } from '@mui/material';
import { WhiteBoxHeader } from 'components/AppShell';
import { useTheme } from '@mui/material/styles';
import { SearchTextField } from 'components/CustomFields';
import { FilterChips } from 'components/Filter';

import HorizontalScrollNavigator from 'components/HorizontalScrollNavigator';
import TemplateList from 'components/TemplateList';
import { NoResultComponent } from './TodoList';
import { useServices } from 'contexts';
import {  getPersonolizedTodoTemplates } from 'services';
import { getTwoDigitNo, Session } from 'utils';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8.5),
  }
}));

const filters = [
  { field: 'expiresSoon', type: 'bool', label: 'Expires Soon' },
  { field: 'new', type: 'bool', label: 'New Published' },
];

const PersonalizedInterviewList = ({ batch, removedFromBatch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const service = useServices();

  const [interviewList, setInterviewList] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const isPersonalizedInterview = true;

  const sortPersonalizedTodoList = (a, b) => {
    const getTotalAttempts = (template) => {
      if (template._id) {
        return template.totalAttemptsCount || 0;
      }
      return template.draft.totalAttemptsCount || 0;
    };
  
    const getCreatedAt = (template) => {
      if (template._id) {
        return dayjs(template.createdAt);
      }
      return dayjs(template.draft.createdAt);
    };
  
    const attemptsA = getTotalAttempts(a);
    const attemptsB = getTotalAttempts(b);
  
    if (attemptsA !== attemptsB) {
      return attemptsA - attemptsB;
    }
    if (getCreatedAt(a).isBefore(getCreatedAt(b))) {
      return 1;
    }
    if (getCreatedAt(a).isAfter(getCreatedAt(b))) {
      return -1;
    }
  
    return 0;
  };

  useEffect(() => {
    if (!batch) {
      return;
    }
    const fetchDrafts = async () => {
      try {
        setLoader(true);
        const templates = await service.callService(getPersonolizedTodoTemplates, batch);

        const modifiedTemplates = templates.map(template => {
          const draft = template.draft;
          const { publishOn, expiresOn, attempts } = draft.data.INTERVIEW_DETAILS;

          draft.new = false;
          draft.expiresSoon = false;

          if (dayjs(expiresOn).year() === 9999) {
            draft.new = !attempts;
            draft.expiresSoon = false;
          } else {
            const startDate = dayjs(publishOn);
            const endDate = dayjs(expiresOn);
            const completedDate = dayjs();
            const percentageValue = 0.16;

            const timeElapsedInMinutes = endDate.diff(startDate, "minute");
            const percentOfMinuteElapsed = percentageValue * timeElapsedInMinutes;
            const thresholdDate = startDate.add(percentOfMinuteElapsed, 'minute');
            const thresholdDateForExpire = endDate.subtract(percentOfMinuteElapsed, 'minute');

            draft.new = completedDate.isBefore(thresholdDate) && !attempts;
            draft.thresholdDateForExpire = thresholdDateForExpire;
            draft.expiresSoon = completedDate.isAfter(thresholdDateForExpire);
          }

          return { ...template, draft };
        });

        modifiedTemplates.sort((a, b) => sortPersonalizedTodoList(a, b));

        if (
          modifiedTemplates.length &&
          Session.user.metadata?.isB2cUser &&
          (modifiedTemplates[0]?.draft.maxAllowedAttempts === modifiedTemplates[0]?.draft.attempts)
        ) {
          setShowBanner(true);
        }

        setInterviewList(modifiedTemplates);
        setFilteredTemplates(modifiedTemplates);
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    };

    fetchDrafts();
  }, [batch]);

  useEffect(() => {
    const isFilterApplied = Object.keys(appliedFilters).length > 0;
  
    const filtered = interviewList.filter((t) => {
      const nameToSearch = t._id ? t.name : t.draft.name;
  
      return (
        (!isFilterApplied ||
          ((t.draft.expiresSoon && appliedFilters.expiresSoon) ||
            (t.draft.new && appliedFilters.new))) &&
        (searchQuery === '' || nameToSearch.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    });
  
    setFilteredTemplates(filtered);
  }, [interviewList, appliedFilters, searchQuery]);
  

  const handleFilterChange = ({ field }) => {
    setAppliedFilters(prevFilters => {
      const newFilters = { ...prevFilters };

      if (newFilters[field]) {
        delete newFilters[field];
      } else {
        newFilters[field] = true;
      }

      return newFilters;
    });
    setSearchQuery("");
  };

  const isFilterApplied = Object.keys(appliedFilters).length > 0;

  return (
    <>
      {interviewList.length > 0 ? (
        <Box className={classes.container}>
        <WhiteBoxHeader
          id='personalized-interview-list'
          heading="Mock Interviews"
          sx={{ marginBottom: theme.spacing(4) }}
          subheading={
            <>
              Total : &nbsp;
              <span style={{color: theme.palette.primary.main}}>
                {getTwoDigitNo(filteredTemplates.length)}
              </span>
            </>
          }
        >
          <SearchTextField
            placeholder="Search assessment..."
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={!interviewList.length}
          />
        </WhiteBoxHeader>
        <Divider />
        <FilterChips
          title="Topic-based filters"
          filters={filters}
          appliedFilters={appliedFilters}
          onChange={handleFilterChange}
        />
        <Box height={280}>
          {interviewList.length || loader ? (
            <HorizontalScrollNavigator
              NoResultComponent={NoResultComponent}
              NoResultComponentProps={{
                description: isFilterApplied
                  ? "No assessment found for the selected filter!"
                  : "No assessment found",
                clearFilters: isFilterApplied ? () => setAppliedFilters({}) : undefined
              }}
              childrensLength={filteredTemplates.length}
              scrollWidth={1400}
            >
              <TemplateList
                defaultTemplates={interviewList}
                templates={filteredTemplates}
                loader={loader}
                bgColor={theme.palette.shades['clr-white-900']}
                cardTile='https://assets.languify.in/images/interview-icon.png'
                isPersonalizedInterview={isPersonalizedInterview}
                disabled={removedFromBatch}
              />
            </HorizontalScrollNavigator>
          ) : (
            <NoResultComponent
              description="No assessments found for your personalized interviews!"
            />
          )}
        </Box>
      </Box>
      ): <></>}
    </>
  );
}

export default PersonalizedInterviewList;
