import { regexPatternsDAF1, regexPatternsDAF2 } from 'components/CreateInterview/constants';

const cleanText = (str, key) => {
  if (key === "state") return str.trim();
  if (key === "district") return str.replace(/(?:\s*State.*|$)/i, "").trim();
  if (key === "stream") return str.trim().split(" ")[0];
  return str.trim();
};

export const extractFieldDetailsFromDaf = (text, isDAF2) => {
  const regexPatterns = isDAF2 ? regexPatternsDAF2 : regexPatternsDAF1;

  const extractedDetails = Object.entries(regexPatterns).reduce(
    (acc, [key, regex]) => {
      const match = text.match(regex);
      if (match) acc[key] = cleanText(match[1], key);
      return acc;
    },
    {}
  );

  return extractedDetails;
};



