import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    padding: '40px',
    backgroundColor: 'white',
    maxWidth: '500px',
    boxShadow: '0 0 100px 0 rgba(0,0,0,0.20), 30px 30px 50px 0 rgba(0,0,0,0.06)',
    borderRadius: '5px',
    zIndex : 100,
  },
  dateBox: {
    position: 'relative',
    display: 'flex',
    alignItems:'center',
    justifyContent:'space-between',
    backgroundColor: 'none',
    padding: '10px 15px',
    border : `1px solid ${theme.palette.neutral['clr-500']}`,
    borderRadius: theme.spacing(1),
    height:'48px',
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-50']
    },
    '&.active': {
      fontWeight: 'bold',
      backgroundColor: theme.palette.shades['clr-white-900']
    },
  },
  modal: {
    width: '310px',
    padding: '15px',
    position: 'absolute',
    overflow: 'hidden',
    userSelect: 'none',
    backgroundColor: 'white',
    borderRadius: '5px',
    zIndex : 10,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
    '&:before': {
      position: 'absolute',
      display: 'block',
      top: 0,
      left: '-5px',
      right: '-5px',
      height: '60px',
      content: '""',
    },
    '&.hidden': {
      opacity: 0,
      pointerEvents: 'none',
      transform: 'scale(0.92)',
      transformOrigin: 'left center',
    },
  },
  pickerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '8px',
    padding: '8px',
  },
  pickerItem: {
    padding: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  calendar: {
    width: '280px',
    tableLayout: 'fixed',
    position: 'relative',
    '& th, & td': {
      textAlign: 'center',
    },
    '& th': {
      cursor: 'default',
      borderRadius: 0,
      height: '50px',
      borderColor: 'hsl(157,79%,49%)',
    },
    '& td': {
      cursor: 'pointer',
      color: '#666',
      borderRadius: '50%',
      height: '38px',
      '&:hover': {
        backgroundColor: '#eee',
      },
      '&.active': {
        backgroundColor: 'hsl(157,79%,49%)',
        color: 'white',
      },
      '&.previous-month, &.next-month': {
        color: '#bbb',
      },
    },
  },
  topBar: {
    position: 'relative',
    color: '#666',
    backgroundColor: 'white',
    width: '195px',
    margin: '0 auto',
    marginBottom: '3px',
    padding: '5px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  navigationButton: {
    cursor: 'pointer',
    padding: '4px 8px',
    position: 'relative',
    zIndex: 10,
    pointerEvents: 'auto',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderRadius: '4px',
    },
  },
  monthYearContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  monthYearGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '8px',
    padding: '8px',
  },
  monthYearItem: {
    padding: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  yearSelector: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px',
    marginBottom: '8px',
  },
  label: {
    ...theme.typography['body01-bold'],
    textTransform: 'capitalize', marginBottom: theme.spacing(2)
  },
  disabledItem: {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  disabled: {
    '&:hover': {
      borderColor: theme.palette.neutral['clr.200'],
      cursor: 'not-allowed'
    }
  },
  error : {
    borderColor : theme.palette.danger.main
  },
  supportiveTxt: {
    display: 'block',
    marginTop: theme.spacing(1),
    ...theme.typography['body02-medium'],
    color: theme.palette.neutral['clr-400'],
  },
}));

const CustomDatePicker = ({
  id,
  type = 'month-year', // 'date', 'month', or 'year'
  initialDate = null,
  onChange,
  label = 'Select Date',
  placeholder = 'Select year',
  yearRange = 10, 
  width = '100%',
  minDate = null,
  endYear = null,
  disabled = false,
  error = null,
  helperText = '',
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [viewDate, setViewDate] = useState(initialDate || new Date());
  const [modalVisible, setModalVisible] = useState(false);

  const currentDate = new Date();
  const maxYear = endYear || currentDate.getFullYear();

  const formatters = {
    date: new Intl.DateTimeFormat('en-US', { 
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    month: new Intl.DateTimeFormat('en-US', { 
      month: 'long',
      year: 'numeric'
    }),
    year: new Intl.DateTimeFormat('en-US', { 
      year: 'numeric'
    }),
    'month-year': new Intl.DateTimeFormat('en-US', {
      month: 'long',
      year: 'numeric'
    }),
  };

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      
      const modal = document.getElementById(`picker-modal-${id}`);
      if (modal && !modal.contains(event.target) && 
          !event.target.closest('.picker-trigger')) {
        setModalVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleTriggerClick = () => {
    if (!disabled) {  
      setModalVisible(true);
    }
  };

  const isDateDisabled = (date) => {
    if (!minDate) return false;
    return date < minDate;
  };

  const isDateInFuture = (date) => {
    const maxDate = new Date(maxYear, 11, 31); 
    return date > maxDate;
  };

  const handleSelection = (value, selectionType) => {
    let newDate = new Date(selectedDate || viewDate);
    
    if (type === 'month-year') {
      if (selectionType === 'month') {
        newDate.setMonth(value);
      } else if (selectionType === 'year') {
        newDate.setFullYear(value);
      }
    
      if (isDateDisabled(newDate) || isDateInFuture(newDate)) {
        return;
      }
      
      setSelectedDate(newDate);

      const formattedDate = new Intl.DateTimeFormat('en-US', { 
        month: 'short', 
        year: 'numeric' 
      }).format(newDate);

      onChange?.(formattedDate);
      return;
    }

    switch (type) {
      case 'date':
        newDate.setDate(value);
        break;
      case 'month':
        newDate.setMonth(value);
        break;
      case 'year':
        newDate.setFullYear(value);
        break;
    }

    if (isDateDisabled(newDate) || isDateInFuture(newDate)) {
      return;
    }
    
    setSelectedDate(newDate);
    const formattedDate = new Intl.DateTimeFormat('en-US', { 
      year: 'numeric' 
    }).format(newDate);

    onChange?.(formattedDate);
    setModalVisible(false);
  };

  const generateYearRange = () => {
    const minYear = minDate ? minDate.getFullYear() : maxYear - yearRange;
    
    if (type === 'year') {
      const viewYear = viewDate.getFullYear();
      const startYear = Math.floor(viewYear / 12) * 12;
      const years = Array.from({ length: 12 }, (_, i) => startYear + i);
      return years;
    }
    
    const years = [];
    for (let year = minYear; year <= maxYear; year++) {
      years.push(year);
    }
    return years;
  };

  const renderDatePicker = () => (
    <table className={classes.calendar}>
      <thead>
        <tr>
          <th>S</th>
          <th>M</th>
          <th>T</th>
          <th>W</th>
          <th>T</th>
          <th>F</th>
          <th>S</th>
        </tr>
      </thead>
      <tbody>
        {generateCalendarDays().map((week, i) => (
          <tr key={i}>
            {week.map((day, j) => (
              <td
                key={j}
                className={`
                  ${day.current ? '' : 'other-month'}
                  ${day.date === selectedDate.getDate() ? 'active' : ''}
                `}
                onClick={() => handleSelection(day.date)}
              >
                {day.date}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderMonthPicker = () => (
    <div className={classes.pickerGrid}>
      {monthNames.map((month, index) => (
        <div
          key={month}
          className={`${classes.pickerItem} ${
            index === selectedDate.getMonth() ? 'active' : ''
          }`}
          onClick={() => handleSelection(index)}
        >
          {month.slice(0, 3)}
        </div>
      ))}
    </div>
  );

  const renderYearPicker = () => (
    <div className={classes.pickerGrid}>
      {generateYearRange().map((year) => {
        const yearDate = new Date(year, 0, 1);
        const isDisabled = isDateDisabled(yearDate) || isDateInFuture(yearDate);

        return (
          <div
            key={year}
            className={`
              ${classes.pickerItem}
              ${isDisabled ? classes.disabledItem : ''}
              ${year === selectedDate?.getFullYear() ? 'active' : ''}
            `}
            onClick={() => !isDisabled && handleSelection(year)}
          >
            {year}
          </div>
        );
      })}
    </div>
  );

  const renderMonthYearPicker = () => (
    <div className={classes.monthYearContainer}>
      <div className={classes.yearSelector}>
        <span
          className={`${classes.navigationButton} ${!canNavigatePrevious() ? classes.disabledItem : ''}`}
          onClick={() => {
            const newDate = new Date(viewDate);
            newDate.setFullYear(viewDate.getFullYear() - 1);
            if (minDate ? newDate.getFullYear() >= minDate.getFullYear() : true) {
              setViewDate(newDate);
            }
          }}
        >
          ‹
        </span>
        <Typography variant='body01-bold'>{viewDate.getFullYear()}</Typography>
        <span
          className={`${classes.navigationButton} ${!canNavigateNext() ? classes.disabledItem : ''}`}
          onClick={() => {
            const newDate = new Date(viewDate);
            newDate.setFullYear(viewDate.getFullYear() + 1);
            if (newDate.getFullYear() <= maxYear) {
              setViewDate(newDate);
            }
          }}
        >
          ›
        </span>
      </div>
      <div className={classes.monthYearGrid}>
        {monthNames.map((month, index) => {
          const monthDate = new Date(viewDate.getFullYear(), index, 1);
          const isDisabled = isDateDisabled(monthDate) || isDateInFuture(monthDate);

          return (
            <div
              key={month}
              className={`
                ${classes.monthYearItem}
                ${isDisabled ? classes.disabledItem : ''}
                ${
                  viewDate?.getFullYear() === selectedDate?.getFullYear() &&
                  index === selectedDate.getMonth()
                    ? 'active'
                    : ''
                }
              `}
              onClick={() => {
                if (!isDisabled) {
                  const newDate = new Date(viewDate);
                  newDate.setMonth(index);
                  setSelectedDate(newDate);
                  const formattedDate = new Intl.DateTimeFormat('en-US', { 
                    month: 'short', 
                    year: 'numeric' 
                  }).format(newDate);
                  onChange?.(formattedDate);
                  setModalVisible(false);
                }
              }}
            >
              <Typography variant='body01-medium'>{month.slice(0, 3)}</Typography>
            </div>
          );
        })}
      </div>
    </div>
  );


  const canNavigatePrevious = () => {
    const newDate = new Date(viewDate);
    switch (type) {
      case 'date':
        newDate.setMonth(viewDate.getMonth() - 1);
        break;
      case 'month-year':
        newDate.setFullYear(viewDate.getFullYear() - 1);
        return minDate ? newDate.getFullYear() >= minDate.getFullYear() : true;
      case 'month':
        newDate.setFullYear(viewDate.getFullYear() - 1);
        break;
      case 'year': {
        const currentViewYear = viewDate.getFullYear();
        const currentRange = Math.floor(currentViewYear / 12) * 12;
        const previousRangeStart = currentRange - 12;
        return minDate ? previousRangeStart < maxYear : true;
      }
    }
    return minDate ? newDate >= minDate : true;
  };

  const canNavigateNext = () => {
    const newDate = new Date(viewDate);
    switch (type) {
      case 'date':
        newDate.setMonth(viewDate.getMonth() + 1);
        break;
      case 'month-year':
        newDate.setFullYear(viewDate.getFullYear() + 1);
        return newDate.getFullYear() <= maxYear;
      case 'month':
        newDate.setFullYear(viewDate.getFullYear() + 1);
        break;
      case 'year': {
        const currentViewYear = viewDate.getFullYear();
        const currentRange = Math.floor(currentViewYear / 12) * 12;
        const nextRangeStart = currentRange + 12;
        return nextRangeStart <= maxYear;
      }
    }
    return newDate <= new Date(maxYear, 11, 31);
  };

  const renderNavigationBar = () => (
    <div className={classes.topBar}>
      <span 
        className={`${classes.navigationButton} ${!canNavigatePrevious() ? classes.disabledNavigation : ''}`}
        onClick={() => {
          if (!canNavigatePrevious()) return;
          const newDate = new Date(viewDate);
          switch (type) {
            case 'date':
              newDate.setMonth(viewDate.getMonth() - 1);
              break;
            case 'month':
              newDate.setFullYear(viewDate.getFullYear() - 1);
              break;
            case 'year': {
              const currentViewYear = viewDate.getFullYear();
              const currentRange = Math.floor(currentViewYear / 12) * 12;
              newDate.setFullYear(currentRange - 12);
              break;
            }
          }
          setViewDate(newDate);
        }}
      >
        ‹
      </span>
      <Typography>
        {type === 'date' && `${monthNames[viewDate.getMonth()]} ${viewDate.getFullYear()}`}
        {type === 'month' && viewDate.getFullYear()}
        {type === 'year' && (() => {
          const startYear = Math.floor(viewDate.getFullYear() / 12) * 12;
          const endYear = startYear + 11;
          return `${startYear} - ${endYear}`;
        })()}
      </Typography>
      <span 
        className={`${classes.navigationButton} ${!canNavigateNext() ? classes.disabledNavigation : ''}`}
        onClick={() => {
          if (!canNavigateNext()) return;
          const newDate = new Date(viewDate);
          switch (type) {
            case 'date':
              newDate.setMonth(viewDate.getMonth() + 1);
              break;
            case 'month':
              newDate.setFullYear(viewDate.getFullYear() + 1);
              break;
            case 'year': {
              const currentViewYear = viewDate.getFullYear();
              const currentRange = Math.floor(currentViewYear / 12) * 12;
              newDate.setFullYear(currentRange + 12);
              break;
            }
          }
          setViewDate(newDate);
        }}
      >
        ›
      </span>
    </div>
  );

  const generateCalendarDays = () => {
    const firstDay = new Date(viewDate.getFullYear(), viewDate.getMonth(), 1);
    const lastDay = new Date(viewDate.getFullYear(), viewDate.getMonth() + 1, 0);
    const firstDayOfWeek = firstDay.getDay();
    const daysInMonth = lastDay.getDate();

    let days = [];
    let week = [];

    for (let i = firstDayOfWeek - 1; i >= 0; i--) {
      const prevDate = new Date(firstDay);
      prevDate.setDate(prevDate.getDate() - i - 1);
      week.push({ date: prevDate.getDate(), current: false });
    }

    for (let i = 1; i <= daysInMonth; i++) {
      week.push({ date: i, current: true });
      if (week.length === 7) {
        days.push(week);
        week = [];
      }
    }

    if (week.length > 0) {
      const remaining = 7 - week.length;
      for (let i = 1; i <= remaining; i++) {
        week.push({ date: i, current: false });
      }
      days.push(week);
    }

    return days;
  };
  
  return (
    <Box position='relative'>
      <Typography 
        className={`${classes.label}`}
      >
        {label}
      </Typography>

      <div
        className={`${classes.dateBox} picker-trigger ${disabled ? classes.disabled : error ? classes.error : ''}`}
        style={{
          width: width,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        onClick={handleTriggerClick}
      >
        {selectedDate
          ? <Typography 
              variant='body01-medium' 
              color={disabled ? 'neutral.clr-400' : 'inherit'}
            >
              {formatters[type].format(selectedDate)}
            </Typography>
          : <Typography variant='body01-medium' color='neutral.clr-400'>
              {placeholder}
            </Typography>}
        <img 
          src="https://assets.languify.in/images/calendar-plus-icon.svg" 
          alt="calendar"
          style={{ 
            cursor: disabled ? 'not-allowed' : 'pointer',
            opacity: disabled ? 0.5 : 1 
          }}
        />
      </div>

      {!disabled && (
        <div
          id={`picker-modal-${id}`}
          className={`${classes.modal} ${!modalVisible ? 'hidden' : ''}`}
          style={{ top: 85, right: 0 }}
        >
          {type !== 'month-year' && renderNavigationBar()}
          {type === 'date' && renderDatePicker()}
          {type === 'month' && renderMonthPicker()}
          {type === 'year' && renderYearPicker()}
          {type === 'month-year' && renderMonthYearPicker()}
        </div>
      )}
        {
        helperText && 
          <Typography 
            className={ classes.supportiveTxt }
            style={{ color: theme.palette.danger.main}}
          >
            {helperText}
          </Typography>
        }
    </Box>
  );
};

export default CustomDatePicker;