import AppShell from "components/AppShell";
import PerformanceReview from "components/PerformanceReview";

export default function InteractiveDemoPerformance() {

  return (
    <AppShell headerLabel="Feedback" showSidebar={false} showDemoAppNavbar={false}>
      <PerformanceReview />
    </AppShell>
  );
}