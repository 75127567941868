import React, { useState } from 'react';
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import CustomButton from 'components/CustomButton';
import { DafInterviewSteps, dafPreferences } from './constants';
import useStore from './store';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    alignItems:'center'
  },
  bottomButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '76px',
    position: 'absolute',
    width: '100%',
    boxShadow: `0px 0px 8px 0px #00000040`,
    top: '416px',
    right: '0px',
    backgroundColor: theme.palette.shades['clr-white-900'],
  },
  preferenceBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    maxHeight: '300px',
    overflowY: 'auto',
    paddingBottom : '75px'
  },
  preferenceItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginLeft: '135px',
    position:'relative'
  },
  serialNumberBox: {
    position: 'absolute',
    height: '32px',
    width: '32px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '-45px',
  },
}));

const DafPreferenceSelection = ({ setDafActiveStep }) => {
  const classes = useStyles();

  const setDafExtractedData = useStore((store) => store.setDafExtractedData);
  const dafExtractedData = useStore((store) => store.dafExtractedData);

  const [selectedPreferences, setSelectedPreferences] = useState([]);

  const handleCheckboxChange = (preference) => {
    setSelectedPreferences((prev) => {
      if (prev.includes(preference)) {
        return prev.filter((item) => item !== preference);
      } else if (prev.length < 5) {
        return [...prev, preference];
      }
      return prev;
    });
  };

  const getSerialNumber = (preference) => {
    const index = selectedPreferences.indexOf(preference);
    return index !== -1 ? index + 1 : '';
  };

  const handleNextStep = () => {
    setDafExtractedData({
      ...dafExtractedData,
      preferences: selectedPreferences, 
    });
    setDafActiveStep(DafInterviewSteps.SELECT_DURATION_TOPIC._id);
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column" gap="4px" width='484px'>
        <Typography variant="h5-medium" color="neutral.clr-900">
          Select top 5 preferences in a sequence
        </Typography>
        <Typography variant="body01-bold" color="neutral.clr-600">
          Interviewer will ask questions based on your choice
        </Typography>
      </Box>
      <Box className={classes.preferenceBox}>
        {dafPreferences.map((preference, index) => (
          <Box key={index} className={classes.preferenceItem}>
            {getSerialNumber(preference) && (
              <Box className={classes.serialNumberBox}>
                <Typography variant="body01-bold" color="primary.main">
                  {getSerialNumber(preference)}
                </Typography>
              </Box>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedPreferences.includes(preference)}
                  onChange={() => handleCheckboxChange(preference)}
                  disabled={
                    !selectedPreferences.includes(preference) &&
                    selectedPreferences.length >= 5
                  }
                />
              }
              label={
                <Typography variant="body01-medium" color="neutral.clr-900">
                  {preference}
                </Typography>
              }
            />
          </Box>
        ))}
      </Box>
      <Box className={classes.bottomButton}>
        <CustomButton
          sx={{ width: "484px" }}
          size="medium"
          onClick={handleNextStep}
          disabled={selectedPreferences.length < 5}
        >
          Next
        </CustomButton>
      </Box>
    </Box>
  );
};

export default DafPreferenceSelection;

