import * as Yup from 'yup';
import { generateMonthYearOptions } from 'utils';

export const DocumentOptions = {
  JD_RESUME :{
    _id : 'JD_&_RESUME',
    name : 'JD_&_RESUME',
    label : 'Practice with available Job descriptions & your Resume',
    img:'https://assets.languify.in/images/DS_Jd&Resume.png'
  },
  JD : {
    _id : 'JD',
    name : 'JD',
    label : 'Practice with available Job descriptions',
    subLabel: '(if you don’t have resume)',
    img:'https://assets.languify.in/images/DS_Jd.png'
  },
  RESUME : {
    _id : 'RESUME',
    name : 'RESUME',
    label : 'Practice for Job role with your resume',
    subLabel: '(Not company specific)',
     img:'https://assets.languify.in/images/DS_Resume.png'
  },
  CASE_STUDY : {
    _id : 'CASE_STUDY',
    name : 'CASE_STUDY',
    label : 'Report/DAF Interview',
    subLabel : '(prepare with DAF, Product teardown, Case study, Project reports & more)',
    img : 'https://assets.languify.in/images/DS_Report.png'
  }
}

export const InterviewSteps = {
    JOB_DETAILS : {
      _id: 'JOB_DETAILS',
      name : 'Job Details'
    },
    SELECT_RESUME : {
      _id : 'SELECT_RESUME',
      name : 'Select Resume'
    },
    PREVIEW : {
      _id : 'PREVIEW',
      name : 'Preview'
    },
}

export const MockHeadingTemplate = {
  selectDocument : {
    head : 'We understand your uniqueness: let’s make your prep reflect that!' 
  },
  clientMock : {
    head : 'Ace Your Interviews with Company Focused Mock Prep',
    subHead : 'Practice with custom mock interviews assigned by your teacher, designed to mirror the unique expectations of companies'
  },
  personalizedMock : {
    head : 'Personalize Your Practice for the Companies You Target',
    subHead : 'Create personalized interviews that align with your dream company’s specific requirements and culture'
  }
}

export const DafInterviewSteps = {
  SELECT_REPORT : {
    _id : 'SELECT_REPORT',
    name : 'SELECT_REPORT'
  },
  FORM_PREVIEW : {
    _id : 'FORM_PREVIEW',
    name : 'FORM PREVIEW'
  },
  SELECT_PREFERENCE : {
    _id : 'SELECT_PREFERENCE',
    name : 'SELECT_PREFERENCE'
  },
  SELECT_DURATION_TOPIC : {
    _id : 'SELECT_DURATION_TOPIC',
    name : 'SELECT DURATION & TOPIC'
  }
}

export const ReportOptions = [
  {
    _id : 'exam',
    name : 'Detailed Application Form (DAF-1&2)'
  },
  {
    _id : 'report',
    name : 'Product Teardowns'
  },
  {
    _id : 'case_study',
    name : 'Case Study'
  },
  {
    _id : 'project',
    name : 'Project Reports'
  },
  {
    _id : 'other',
    name : 'Other'
  }
]

export const DafTimeOptions = {
  first_option : {
    _id : '20_mins',
    name : '20_minutes',
    label : '20 minutes',
    subLabel : '10 Questions',
    value : 10
  },
  second_option : {
    _id : '40_mins',
    name : '40_minutes',
    label : '40 minutes',
    subLabel : '20 Questions',
    value : 20
  },
  third_option : {
    _id : '60_mins',
    name : '60_minutes',
    label : '60 minutes',
    subLabel : '30 Questions',
    value : 30
  }
}

export const PersonalizedTemplateType = {
  exam : "exam",
  report : "report",
  resume : "resume",
  jobDescription : "jobDescription",
  jd_resume : "jd_resume",
}

export const dafTopics = [
  { 
    _id: 'allTopics', 
    topic: 'Let interviewer cover all the topics in interview',
  },
  { 
    _id: 'personal_background', 
    topic: 'Personal Background',
    description : `Questions explore the candidate's education, and professional background with a focus on strengths, weaknesses, and experiences. Avoid overly formal or invasive questions and aim to make the candidate comfortable.` 
  },
  { 
    _id: 'current_affairs', 
    topic: 'Current Affairs',
    description : 'Focus on recent national and international issues, emphasizing relevant government schemes (mention scheme name), their pros, cons, and challenges. Avoid subjective opinion questions and date references but ensure relevance to recent events or schemes from November to December 2024.'
  },
  // { 
  //   id: 'optional_subject', 
  //   topic: 'Optional Subject',
  //   description : `Questions should relate to the candidate’s chosen optional subject {optional_subject} and its administrative relevance. In the 'domain' field of your response, mention {optional_subject} for these type of questions`
  // },
  { 
    _id: 'hobbies&interests',
    topic: 'Hobbies and Interests',
    description: 'Delve into hobbies and interests mentioned in the DAF, linking them to governance where applicable. Incorporate recent trends or innovations in these areas.'
  },
  { 
    _id: 'government_issues', 
    topic: 'Administrative and Governance Issues',
    description : 'Cover topics like good governance, ethical responsibilities, and successful governance practices. Questions should be slightly informal and discussion-friendly.'
  },
  { 
    _id: 'situational&behavioral',
    topic: 'Situational and Behavioral Questions',
    description : 'Pose real-world scenarios to assess problem-solving, decision-making, and ethical judgment in a relaxed tone. Avoid high-pressure phrasing.'
  },
  { 
    _id: 'india_history',
    topic: `India's History, Culture, and Heritage`,
    description : 'Include significant historical events, cultural diversity, and heritage preservation efforts. Ensure questions are conversational and engaging.' 
  },
  { 
    _id: 'science_tech',
    topic: `Science and Technology`,
    description : 'Explore recent technological developments, their application in governance, and their societal impact. Avoid technical jargon and focus on practical understanding.' 
  },
  { 
    _id: 'economic_social', 
    topic: `Economic and Social Development`,
    description : 'Discuss government schemes, socio-economic challenges, and their implications, with a focus on pros, cons, and implementation challenges. Avoid overly formal language.'
  },
  { 
    _id: 'envoirnment_ecology',
    topic: `Environment and Ecology`,
    description : `Cover climate change, conservation, and India's environmental policies or challenges. Ensure questions are engaging and linked to governance where relevant.`
  },
  { 
    _id: 'internal_external_security', 
    topic: `Internal and External Security`,
    description : 'Explore topics like border management, terrorism, and cybersecurity with a practical focus. Keep the tone inquisitive and approachable.'
  },
  { 
    _id: 'miscellaneous', 
    topic: `Miscellaneous`,
    description : `Pose abstract, opinion based or philosophical questions and explore the candidate's motivations and vision as a civil servant. Use an informal and friendly tone.`
  },
];

export const errorStateTexts = {
  pdf_upload_fail : 'Failure while uploading pdf',
  necessary_field_fail : 'We couldn’t find the required fields in your document. Please ensure you’re uploading the correct DAF file and try again.',
  not_pdf_file : 'Please make sure you’re uploading a PDF file only.',
  scanned_document : 'It seems we’re having trouble reading the content. Please ensure that the PDF you’re uploading is not locked, not an image-based file, and not a scanned copy.',
  daf_one_error : 'It seems you’re trying to upload DAF 2 instead of DAF 1. Please ensure you’re uploading the correct DAF file and try again.',
  daf_two_error : 'It seems you’re trying to upload DAF 1 instead of DAF 2. Please ensure you’re uploading the correct DAF file and try again.'
}

export const regexPatternsDAF1 = {
  name: /Name\s*:\s*([A-Za-z\s]+)(?=\s*Have\b)/,
  state: /State\s*:\s*\|\s*([A-Za-z\s]+)(?=\s*Pin\s*Code\s*:)/,
  district: /District\s*:\s*\|\s*([A-Za-z\s]+)(?=\s*State\b)/,
  stream: /Stream at Graduation Level\s*[:\-]*\s*([A-Za-z\s]+)/,
  prizes:
    /Prizes,\s*Medals,\s*Scholarships\s*etc\.\s*\|\s*((?:.|\n)*?)(?=\(b\))/,
  sports: /Sports\/N\.C\.C\.\setc\.\s*\|\s*((?:.|\n)*?)(?=\(c\))/,
  leadership:
    /Position\(s\)\s*of\s*distinction\s*Leadership\s*held\s*in\s*School\/College\s*\|\s*((?:.|\n)*?)(?=\(d\))/,
  extraCurricular:
    /Other\s*extracurricular\s*activities\s*and\s*interests\s*\(Such\s*as\s*hobbies\s*etc\.\)\s*\|\s*((?:.|\n)*?)(?=\bRoll\b)/,
  optionalSubject:
    /Optional Subject for paper VI & VII\s*\|\s*(.*?)(?=\s*(12\.|Roll))/,
  attemptTaken:
    /How many times have you appeared at the Civil Services\(Pre\) Examination so far, including the current Examination held in May, 2023\s*\|\s*([A-Za-z0-9\s]+)(?=\s*18\(b\)\.)/,
  educationalQualifications:
    /NO\s*14\.2\s*Educational Qualifications\s*:\s*Commencing with Matriculation or equivalent examination till Graduation:- \(Please upload scanned copy of all Certificates\/Degree\)\s*((?:.|\n)*?)(?=\s*15\.\(i\)\s*Have\s+you\s+ever\s+been\s+employed\?)/,
  employmentDetails:
    /Details of your employments \(in chronological order till date\)\s*((?:.|\n)*?)(?=\s*15\.\(ii\))/,
  preferences:
    /Service in Civil Services Examination, 2023\. S\.N\.\s*Name of Services\/Post\s*Order of Preference\s*((?:.|\n)*?)(?=\s*Roll\s*No\.)/,
    dafNumber:
    /UNION PUBLIC SERVICE COMMISSION([\s\S]*?)CIVIL SERVICES/,
};

export const regexPatternsDAF2 = {
  name: /Name\s*:\s*([A-Za-z\s]+)(?=\s*(Email\s*:|$))/,
  state: /State\s*:\s*\|\s*([A-Za-z\s]+)(?=\s*Pin\s*Code\s*:)/,
  district: /District\s*(?::|\|)\s*\|\s*([A-Za-z\s]+)/,
  stream: /Stream at Graduation Level\s*[:\-]*\s*([A-Za-z\s]+)/,
  prizes:
    /Prizes,\s*Medals,\s*Scholarships\s*etc\.\s*\|\s*((?:.|\n)*?)(?=\(b\))/,
  sports: /Sports\/N\.C\.C\.\setc\.\s*\|\s*((?:.|\n)*?)(?=\(c\))/,
  leadership:
    /Position\(s\)\s*of\s*distinction\s*Leadership\s*held\s*in\s*School\/College\s*\|\s*((?:.|\n)*?)(?=\(d\))/,
  extraCurricular:
    /Other\s*extracurricular\s*activities\s*and\s*interests\s*\(Such\s*as\s*hobbies\s*etc\.\)\s*\|\s*((?:.|\n)*?)(?=\bRoll\b)/,
  optionalSubject:
    /Optional Subject for paper VI & VII\s*\|\s*(.*?)(?=\s*(12\.|Roll))/,
  attemptTaken:
    /How many times have you appeared at the Civil Services\(Pre\) Examination so far, including the current Examination held in May, 2023\s*\|\s*([A-Za-z0-9\s]+)(?=\s*18\(b\)\.)/,
  educationalQualifications:
    /Educational Qualifications\s*:\s*Commencing with Matriculation or equivalent examination till Graduation:-\s*((?:.|\n)*?)(?=\s*(?:6|9)\.\s+Having\s+regard\s+to\s+answers)/,
  employmentDetails:
    /Details of your employments \(in chronological order till date\)\s*((?:.|\n)*?)(?=\s*(?:15|10)\.\(ii\))/,
  preferences:
    /Service in Civil Services Examination, 2023\. S\.N\.\s*Name of Services\/Post\s*Order of Preference\s*((?:.|\n)*?)(?=\s*Roll\s*No\.)/,
    dafNumber:
    /UNION PUBLIC SERVICE COMMISSION([\s\S]*?)CIVIL SERVICES/,
};

export const dafEducationFields = {
  postGraduation: [
    {
      id: 'collegeName',
      type: 'text',
      label: 'College name',
      placeholder: "e.g. Shri Ram College of Commerce",
      validation: Yup.string().required('Your college name missing!'),
    },
    {
      id: 'universityName',
      type: 'text',
      label: 'University name',
      placeholder: "e.g. Delhi University",
      validation: Yup.string().required('Your university name missing!'),
    },
    {
      id: 'degree',
      type: 'text',
      label: 'Degree',
      placeholder: "e.g. B.Com",
      validation: Yup.string().required('Please enter your degree!'),
    },
    {
      id: 'stream',
      type: 'text',
      label: 'Stream',
      placeholder: "e.g. Management",
      validation: Yup.string().required('Please mention your stream!'),
    },
    {
      id: 'startYear',
      type: 'startYearPicker',
      label: 'Start year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select Start year!')
    },
    {
      id: 'endYear',
      type: 'endYearPicker',
      label: 'End year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select End year!')
    },
    {
      id: 'gradingSystem',
      type: 'select',
      label: 'Grading system',
      options: [
        { _id: 'percentage', name: 'Percentage' },
        { _id: 'cgpa_10', name: 'CGPA (on scale of 10)' },
        { _id: 'cgpa_4', name: 'CGPA (on scale of 4)' },
      ],
      placeholder: 'Choose an option',
      validation: Yup.string().required('Please choose a grading system!'),
    },
    {
      id: "grade",
      type: "number",
      label: "Your Grades",
      placeholder: "Enter grades",
      validation: Yup.string()
        .required("Please enter valid grades!")
        .test(
          "gradesValidation",
          function (value) {
            const { gradingSystem } = this.parent;
    
            // Validation based on the grading system
            if (gradingSystem === "percentage") {
              // Allow percentages between 0 and 100 with up to 2 decimal places
              if (/^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a percentage between 0 and 100 with up to 2 decimal places.",
              });
            } else if (gradingSystem === "cgpa_10") {
              // Allow CGPA out of 10 with up to 2 decimal places
              if (/^(?:10(?:\.0{1,2})?|[0-9](?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a CGPA between 0 and 10 with up to 2 decimal places.",
              });
            } else if (gradingSystem === "cgpa_4") {
              // Allow CGPA out of 4 with up to 2 decimal places
              if (/^(?:4(?:\.0{1,2})?|[0-3](?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a CGPA between 0 and 4 with up to 2 decimal places.",
              });
            }
    
            return this.createError({
              path: "grade",
              message: "Invalid grading system selected.",
            });
          }
        ),
    },    
    {
      id: 'courseType',
      type: 'radio',
      label: 'Course type',
      options: [
        { _id : 'full_time', name : 'full_time', label : 'Full-time' },
        { _id : 'part_time', name : 'part_time', label : 'Part-time' },
        { _id : 'distance_learning', name : 'distance_learning', label : 'Distance Learning' },
      ],
      optionWidth: 150,
      validation: Yup.string()
        .required("Please choose course type!")
    },
  ],
  graduation : [
    {
      id: 'collegeName',
      type: 'text',
      label: 'College name',
      placeholder: "e.g. Shri Ram College of Commerce",
      validation: Yup.string().required('Your college name missing!'),
    },
    {
      id: 'universityName',
      type: 'text',
      label: 'University name',
      placeholder: "e.g. Delhi University",
      validation: Yup.string().required('Your university name missing!'),
    },
    {
      id: 'degree',
      type: 'text',
      label: 'Degree',
      placeholder: "e.g. B.Com",
      validation: Yup.string().required('Please enter your degree!'),
    },
    {
      id: 'stream',
      type: 'text',
      label: 'Stream',
      placeholder: "e.g. Management",
      validation: Yup.string().required('Please mention your stream!'),
    },
    {
      id: 'startYear',
      type: 'startYearPicker',
      label: 'Start year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select Start year!')
    },
    {
      id: 'endYear',
      type: 'endYearPicker',
      label: 'End year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select End year!')
    },
    {
      id: 'gradingSystem',
      type: 'select',
      label: 'Grading system',
      options: [
        { _id: 'percentage', name: 'Percentage' },
        { _id: 'cgpa_10', name: 'CGPA (on scale of 10)' },
        { _id: 'cgpa_4', name: 'CGPA (on scale of 4)' },
      ],
      placeholder: 'Choose an option',
      validation: Yup.string().required('Please choose a grading system!'),
    },
    {
      id: 'grade',
      type: 'number',
      label: 'Your Grades',
      placeholder: "Enter grades",
      validation: Yup.string()
      .required("Please enter valid grades!")
      .test(
        "gradesValidation",
        function (value) {
          const { gradingSystem } = this.parent;
  
          // Validation based on the grading system
          if (gradingSystem === "percentage") {
            // Allow percentages between 0 and 100 with up to 2 decimal places
            if (/^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a percentage between 0 and 100 with up to 2 decimal places.",
            });
          } else if (gradingSystem === "cgpa_10") {
            // Allow CGPA out of 10 with up to 2 decimal places
            if (/^(?:10(?:\.0{1,2})?|[0-9](?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a CGPA between 0 and 10 with up to 2 decimal places.",
            });
          } else if (gradingSystem === "cgpa_4") {
            // Allow CGPA out of 4 with up to 2 decimal places
            if (/^(?:4(?:\.0{1,2})?|[0-3](?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a CGPA between 0 and 4 with up to 2 decimal places.",
            });
          }
  
          return this.createError({
            path: "grade",
            message: "Invalid grading system selected.",
          });
        }
      ),
    },
    {
      id: 'courseType',
      type: 'radio',
      label: 'Course type',
      options: [
        { _id : 'full_time', name : 'full_time', label : 'Full-time' },
        { _id : 'part_time', name : 'part_time', label : 'Part-time' },
        { _id : 'distance_learning', name : 'distance_learning', label : 'Distance Learning' },
      ],
      optionWidth: 150,
      validation: Yup.string()
        .required("Please choose course type!")
    },
  ],
  seniorSecondary : [
    {
      id: 'board',
      type: 'text',
      label: 'Board',
      placeholder: "e.g. Central Board of Secondary Education",
      validation: Yup.string().required('Please enter your board!'),
    },
    {
      id: 'passingYear',
      type: 'startYearPicker',
      label: 'Passing out year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select Passing year!')
    },
    {
      id: 'gradingSystem',
      type: 'select',
      label: 'Grading system',
      options: [
        { _id: 'percentage', name: 'Percentage' },
        { _id: 'cgpa_10', name: 'CGPA (on scale of 10)' },
        { _id: 'cgpa_4', name: 'CGPA (on scale of 4)' },
      ],
      placeholder: 'Choose an option',
      validation: Yup.string().required('Please choose a grading system!'),
    },
    {
      id: 'grade',
      type: 'number',
      label: 'Your Grades',
      placeholder: "Enter grades",
      validation: Yup.string()
      .required("Please enter valid grades!")
      .test(
        "gradesValidation",
        function (value) {
          const { gradingSystem } = this.parent;
  
          // Validation based on the grading system
          if (gradingSystem === "percentage") {
            // Allow percentages between 0 and 100 with up to 2 decimal places
            if (/^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a percentage between 0 and 100 with up to 2 decimal places.",
            });
          } else if (gradingSystem === "cgpa_10") {
            // Allow CGPA out of 10 with up to 2 decimal places
            if (/^(?:10(?:\.0{1,2})?|[0-9](?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a CGPA between 0 and 10 with up to 2 decimal places.",
            });
          } else if (gradingSystem === "cgpa_4") {
            // Allow CGPA out of 4 with up to 2 decimal places
            if (/^(?:4(?:\.0{1,2})?|[0-3](?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a CGPA between 0 and 4 with up to 2 decimal places.",
            });
          }
  
          return this.createError({
            path: "grade",
            message: "Invalid grading system selected.",
          });
        }
      ),
    },
  ],
  secondary : [
    {
      id: 'board',
      type: 'text',
      label: 'Board',
      placeholder: "e.g. Central Board of Secondary Education",
      validation: Yup.string().required('Please enter your board!'),
    },
    {
      id: 'passingYear',
      type: 'startYearPicker',
      label: 'Passing out year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select Passing year!')
    },
    {
      id: 'gradingSystem',
      type: 'select',
      label: 'Grading system',
      options: [
        { _id: 'percentage', name: 'Percentage' },
        { _id: 'cgpa_10', name: 'CGPA (on scale of 10)' },
        { _id: 'cgpa_4', name: 'CGPA (on scale of 4)' },
      ],
      placeholder: 'Choose an option',
      validation: Yup.string().required('Please choose a grading system!'),
    },
    {
      id: 'grade',
      type: 'number',
      label: 'Your Grades',
      placeholder: "Enter grades",
      validation: Yup.string()
      .required("Please enter valid grades!")
      .test(
        "gradesValidation",
        function (value) {
          const { gradingSystem } = this.parent;
  
          // Validation based on the grading system
          if (gradingSystem === "percentage") {
            // Allow percentages between 0 and 100 with up to 2 decimal places
            if (/^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a percentage between 0 and 100 with up to 2 decimal places.",
            });
          } else if (gradingSystem === "cgpa_10") {
            // Allow CGPA out of 10 with up to 2 decimal places
            if (/^(?:10(?:\.0{1,2})?|[0-9](?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a CGPA between 0 and 10 with up to 2 decimal places.",
            });
          } else if (gradingSystem === "cgpa_4") {
            // Allow CGPA out of 4 with up to 2 decimal places
            if (/^(?:4(?:\.0{1,2})?|[0-3](?:\.\d{1,2})?)$/.test(value)) {
              return true;
            }
            return this.createError({
              path: "grade",
              message: "Enter a CGPA between 0 and 4 with up to 2 decimal places.",
            });
          }
  
          return this.createError({
            path: "grade",
            message: "Invalid grading system selected.",
          });
        }
      ),
    },
  ],
  diploma : [
    {
      id: 'collegeName',
      type: 'text',
      label: 'College name',
      placeholder: "e.g. Shri Ram College of Commerce",
      validation: Yup.string().required('Your college name missing!'),
    },
    {
      id: 'stream',
      type: 'text',
      label: 'Stream',
      placeholder: "e.g. Management",
      validation: Yup.string().required('Please mention your stream!'),
    },
    {
      id: 'startYear',
      type: 'startYearPicker',
      label: 'Start year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select Start year!')
    },
    {
      id: 'endYear',
      type: 'endYearPicker',
      label: 'End year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select End year!')
    },
    {
      id: 'gradingSystem',
      type: 'select',
      label: 'Grading system',
      options: [
        { _id: 'percentage', name: 'Percentage' },
        { _id: 'cgpa_10', name: 'CGPA (on scale of 10)' },
        { _id: 'cgpa_4', name: 'CGPA (on scale of 4)' },
      ],
      placeholder: 'Choose an option',
      validation: Yup.string().required('Please choose a grading system!'),
    },
    {
      id: "grade",
      type: "number",
      label: "Your Grades",
      placeholder: "Enter grades",
      validation: Yup.string()
        .required("Please enter valid grades!")
        .test(
          "gradesValidation",
          function (value) {
            const { gradingSystem } = this.parent;
    
            // Validation based on the grading system
            if (gradingSystem === "percentage") {
              // Allow percentages between 0 and 100 with up to 2 decimal places
              if (/^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a percentage between 0 and 100 with up to 2 decimal places.",
              });
            } else if (gradingSystem === "cgpa_10") {
              // Allow CGPA out of 10 with up to 2 decimal places
              if (/^(?:10(?:\.0{1,2})?|[0-9](?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a CGPA between 0 and 10 with up to 2 decimal places.",
              });
            } else if (gradingSystem === "cgpa_4") {
              // Allow CGPA out of 4 with up to 2 decimal places
              if (/^(?:4(?:\.0{1,2})?|[0-3](?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a CGPA between 0 and 4 with up to 2 decimal places.",
              });
            }
    
            return this.createError({
              path: "grade",
              message: "Invalid grading system selected.",
            });
          }
        ),
    },    
  ],
  phd : [
    {
      id: 'collegeName',
      type: 'text',
      label: 'College name',
      placeholder: "e.g. Shri Ram College of Commerce",
      validation: Yup.string().required('Your college name missing!'),
    },
    {
      id: 'stream',
      type: 'text',
      label: 'Stream',
      placeholder: "e.g. Management",
      validation: Yup.string().required('Please mention your stream!'),
    },
    {
      id: 'specialization',
      type: 'text',
      label: 'Specialization in',
      placeholder: "e.g. Management Science",
      validation: Yup.string().required('Please mention your speacialization!'),
    },
    {
      id: 'startYear',
      type: 'startYearPicker',
      label: 'Start year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select Start year!')
    },
    {
      id: 'endYear',
      type: 'endYearPicker',
      label: 'End year',
      datePickerType: 'year',
      width: '100%',
      endYear: 2030,
      validation: Yup.date().required('Please select End year!')
    },
    {
      id: 'gradingSystem',
      type: 'select',
      label: 'Grading system',
      options: [
        { _id: 'percentage', name: 'Percentage' },
        { _id: 'cgpa_10', name: 'CGPA (on scale of 10)' },
        { _id: 'cgpa_4', name: 'CGPA (on scale of 4)' },
      ],
      placeholder: 'Choose an option',
      validation: Yup.string().required('Please choose a grading system!'),
    },
    {
      id: "grade",
      type: "number",
      label: "Your Grades",
      placeholder: "Enter grades",
      validation: Yup.string()
        .required("Please enter valid grades!")
        .test(
          "gradesValidation",
          function (value) {
            const { gradingSystem } = this.parent;
    
            // Validation based on the grading system
            if (gradingSystem === "percentage") {
              // Allow percentages between 0 and 100 with up to 2 decimal places
              if (/^(?:100(?:\.0{1,2})?|[0-9]{1,2}(?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a percentage between 0 and 100 with up to 2 decimal places.",
              });
            } else if (gradingSystem === "cgpa_10") {
              // Allow CGPA out of 10 with up to 2 decimal places
              if (/^(?:10(?:\.0{1,2})?|[0-9](?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a CGPA between 0 and 10 with up to 2 decimal places.",
              });
            } else if (gradingSystem === "cgpa_4") {
              // Allow CGPA out of 4 with up to 2 decimal places
              if (/^(?:4(?:\.0{1,2})?|[0-3](?:\.\d{1,2})?)$/.test(value)) {
                return true;
              }
              return this.createError({
                path: "grade",
                message: "Enter a CGPA between 0 and 4 with up to 2 decimal places.",
              });
            }
    
            return this.createError({
              path: "grade",
              message: "Invalid grading system selected.",
            });
          }
        ),
    },    
  ],
}

export const dafEmploymentFields = [
  {
    id: "jobTitle",
    type: "text",
    label: "Job title",
    placeholder: "e.g. Sales Manager",
    validation: Yup.string().required("Please add your job title!"),
  },
  {
    id: "companyName",
    type: "text",
    label: "Company name",
    placeholder: "e.g. TCS",
    validation: Yup.string().required("Please add your company name!"),
  },
  {
    id: "location",
    type: "text",
    label: "Location",
    placeholder: "e.g. Mumbai",
    validation: Yup.string().required("Please add location!"),
  },
  {
    id: 'startDate',
    type: 'startYearPicker',
    label: 'Start month and year',
    datePickerType: 'month-year',
    width: '100%',
    endYear: 2030,
    validation: Yup.date().required('Please add starting month & year!')
  },
  {
    id: 'endDate',
    type: 'endYearPicker',
    label: 'End month and year',
    datePickerType: 'month-year',
    width: '100%',
    endYear: 2030,
    validation: Yup.date().required(`Please select an end date or check 'I am working here'`)
  },
  {
    id: "currentWorking",
    type: "checkbox",
    label: "I am working here.",
    validation: Yup.boolean()
  },
  {
    id: 'employmentType',
    type: 'radio',
    label: 'Employment Type',
    options: [
      { _id: 'permanent', name: 'Permanent' , label : 'Permanent' },
      { _id: 'temporary', name: 'Temporary' , label : 'Temporary' },
      { _id: 'ad_hoc', name: 'Ad hoc' , label : 'Ad hoc' },
    ],
    optionWidth: 150,
    validation: Yup.string().required('Please select your employment type'),
  },
];

 export const dafPreferences = [
    'Indian Administrative Service',
    'Indian Foreign Service',
    'Indian Police Service',
    'Indian Audit and Accounts Service, Group "A"',
    'Indian Civil Accounts Service ,Group "A"',
    'Indian Corporate Law Service ,Group "A"',
    'Indian Defence Accounts Service ,Group "A"',
    'Indian Defence Estates Service, Group "A"',
    'Indian Information Service, Group "A"',
    'Indian Postal Service,Group "A"',
    'Indian Post & Telecommunication Accounts and Finance Service, Group "A"',
    'Indian Railway Protection Force Service, Group "A"',
    'Indian Railway Management Service, Group "A"',
    'Indian Revenue Service (Customs & Indirect Taxes) Group "A"',
    'Indian Revenue Service, (Income Tax) Group "A"',
    'Indian Trade Service, Group "A" (Gr. III)',
    `Armed Forces Headquarters Civil Service , Group 'B'(Section Officer's Grade)`,
    `Delhi , Andaman and Nicobar Islands, Lakshadweep, Daman & Diu and Dadra &
      Nagar Haveli Civil Service (DANICS), Group "B"`,
    `Delhi , Andaman and Nicobar Islands, Lakshadweep, Daman & Diu and Dadra &
      Nagar Haveli Police Service (DANIPS), Group "B"`,
      'Pondicherry Civil Service (PONDICS), Group "B"',
      'Pondicherry Police Service (PONDIPS), Group "B"',
  ];


