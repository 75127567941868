import React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useStore from './store';
import Chip from 'components/Chip';
import Meter from 'components/Gauge/Meter';
import VerticalProgressBar from './VerticalProgressBar';
import InfoIconWithTooltip, { BootstrapTooltip } from 'components/InfoIconWithTooltip';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { shallow } from 'zustand/shallow';
import { Divider, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SimpleSelect } from 'components/CustomSelectFields';
import { templateTypes } from './constants';
import { getCommentAndColor } from './helpers';
import {
  calculateTimeDifference, getTwoDigitNo, 
  isMobile, round, scrollToElementById, 
  Session
} from 'utils';

const useStyles = makeStyles((theme) => ({
  sectionRoot: {
    display: 'flex', width: '100%', height: 'content-fit',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
    }
  },
  overallSections: {
    padding: theme.spacing(6, 5),
    height: '100%',
    borderLeft: '1px solid',
    borderColor: theme.palette.neutral['clr-200'],
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderLeftWidth: 0,
      borderTop: '1px solid',
      borderColor: theme.palette.neutral['clr-200'],
    }
  },
  meterBox: {
    width: '20%',
    height: '100%',
    padding: '16px 22px', display: 'flex',
    flexDirection: 'column', alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  comparisonChip: {
    display: "flex",
    alignItems: "center",
    borderRadius: '4px',
    padding: theme.spacing(1, 2),
    textDecoration: 'underline',
    border: '2px solid',
    borderColor: theme.palette.success['clr-700'],
    color: theme.palette.success['clr-700'],
    ...theme.typography['body02-bold']
  },
  percentageUp: {
    borderColor: theme.palette.success['clr-700'],
    color: theme.palette.success['clr-700'],
  },
  percentageDown: {
    borderColor: theme.palette.danger['clr-500'],
    color: theme.palette.danger['clr-500'],
  },
}));

function Subtitle({ head = '', value = '' }) {
  return (
    <Box>
      <Typography variant='body01-semiBold'>{head}:</Typography>&nbsp;
      <Typography variant='body01-bold'>{value}</Typography>
    </Box>
  )
}

function HeaderSkeleton() {
  return (
    <Box display='flex' flexDirection='column' gap='8px' justifyContent='center'>
      <Skeleton variant="rectangular" width={200} height={30} />
      <Divider />
      <Skeleton variant="rectangular" width={300} height={30} />
    </Box>
  )
}

function PathwayHeader({ template = {} }) {
  const pathway = useStore(state => state.pathway, shallow);
  const module = useStore(state => state.module, shallow);

  return (
    <Box>
      <Box style={{ display: 'flex', gap: '16px', borderBottom: '2px solid #CCD4DE' }}>
        <Subtitle head={'Pathway'} value={pathway?.name} />
        <Subtitle head={'Module'} value={module?.name} />
      </Box>
      <Box>
        <Typography variant='h5-medium' color='neutral.clr-800'>Assessment:</Typography>&nbsp;
        <Typography variant='h5-medium' color='primary.main'>{template?.name}</Typography>
      </Box>
    </Box>
  )
}

function TandTHeader({ template = {}, attempt = {} }) {
  const [timeTaken, setTimeTaken] = React.useState('00 secs');

  React.useEffect(() => {
    const time = calculateTimeDifference(attempt.startedAt, attempt.finishedAt);
    (time.minutes < 1) ? setTimeTaken(`${getTwoDigitNo(time.seconds)} secs`)
      : setTimeTaken(`${getTwoDigitNo(time.minutes)}:${getTwoDigitNo(time.seconds)} mins`);
  }, [attempt]);

  return (
    <Box>
      <Box style={{ borderBottom: '2px solid #CCD4DE', padding: '2px 0px' }}>
        <Typography variant='h5-medium' color='neutral.clr-800'>
          Assessment: <b>{template?.name}</b>
        </Typography>
      </Box>
      <Box style={{ display: 'flex', gap: '16px', }}>
        <Subtitle head={'Type'} value={template?.type} />
        <Subtitle head={'Time Taken'} value={`${timeTaken}`} />
        <Subtitle head={'Attempted on'} value={dayjs(attempt.createdAt).format('DD/MM/YYYY')} />
      </Box>
    </Box>
  )
}

const chipSx = { padding: '2px 8px', borderRadius: '4px' }

const ComparisonChip = ({ change }) => {
  const classes = useStyles();

  return (
    <Box
      className={
        `${classes.comparisonChip} ${change >= 0 ? classes.percentageUp : classes.percentageDown}`
      }
    >
      {change >= 0 ? (
        <ArrowUpwardIcon fontSize='inherit' />
      ) : (
        <ArrowDownwardIcon fontSize='inherit' />
      )}
      {change < 0 ? -round(change) :
        round(change)
      }%
    </Box>
  )
}

function ParameterFeedbackSkeleton() {
  return (
    <Box display='flex' gap='20px'>
      <Skeleton variant="rectangular" width={30} height={140} />
      <Box display='flex' flexDirection='column' gap='8px'>
        <Skeleton variant="rectangular" width={200} height={30} />
        <Skeleton variant="rectangular" width={300} height={90} />
      </Box>
    </Box>
  )
}

function ParameterFeedback({
  title, score = 0, category = 'overall', change = 0, showComparison = true,
  info, loading = false
}) {
  const data = getCommentAndColor(score);

  const questions = useStore(state => state.questions, shallow);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion, shallow);

  const { lowestScoredQues } = React.useMemo(() => {
    const totalAttemptedQuestions = questions.reduce(
      (count, item) => count + (!item.isError ? 1 : 0)
      , 0
    );
    let lowestScoredQues = []

    function getLowestScores(arr, count) {
      const currentCat = category === 'speech' ? 'communication' : 'content';

      return arr
        .sort((a, b) => {
          const aAverage = a?.[currentCat]?.ratings?.OVERALL || 0
          const bAverage = b?.[currentCat]?.ratings?.OVERALL || 0

          return aAverage - bAverage;
        })
        .slice(0, count);
    }

    if (totalAttemptedQuestions >= 5) {
      lowestScoredQues = getLowestScores(questions, 3);
    }

    return { lowestScoredQues }

  }, [questions, category])

  const handleChangeQuestion = (question) => {
    setCurrentQuestion(question.serialNumber);
    scrollToElementById('question-section')
  }

  return loading ? <ParameterFeedbackSkeleton /> : (
    <Box display='flex' gap='20px'>
      <VerticalProgressBar progress={score} color={data.bgColor} />
      <Box>
        <Typography variant='h5-medium' color='neutral.clr-800'>
          {title}&nbsp;
          {
            info 
              ? <InfoIconWithTooltip style={{ fontSize: '20px' }} title={info} placement='top' />
              : null
          }
        </Typography>
        <Box style={{ display: 'flex', gap: '4px', marginTop: '4px' }}>
          <Chip 
            content={getTwoDigitNo(round(score))} 
            color={data.color} bgColor={data.bgColor} 
            sx={chipSx} typographyVariant='h5-bold' 
          />
          <Chip content={data.comment} color={data.color} bgColor={data.bgColor} sx={chipSx} />
          <br />
          {
            showComparison 
            ? <BootstrapTooltip 
                title={`Reflects changes in average ${category} score after your last attempt.`} 
                placement='top'
              >
                <Box>
                  <ComparisonChip change={change} /> 
                </Box>
              </BootstrapTooltip>
            : null
          }
        </Box>
        <Box mt={2}>
          <Typography variant='body01-semiBold'>
            {data[category]}
          </Typography>
        </Box>

        {
          lowestScoredQues.length ?
            <Box mt={2} style={{ display: 'flex', gap: '8px' }}>
              <Typography variant='body01-bold'>
                Lowest scores:
              </Typography>
              {
                lowestScoredQues.map((ques, index) => {
                  return (
                    <Chip
                      key={index}
                      content={`Q${ques?.serialNumber + 1 || index + 1}`}
                      color={'#FFE6E3'}
                      bgColor={'#C51407'}
                      sx={{ padding: '2px 4px', borderRadius: '4px', cursor: 'pointer' }}
                      typographyVariant='body02-bold'
                      onClick={() => { handleChangeQuestion(ques) }}
                    />
                  )
                })
              }
            </Box> : null
        }
      </Box>
    </Box>
  )
}

function OverallPerformance({
  template, summaryLoading, attemptsLoading,
  overallChange, showComparison, contentChange, speechChange
}) {
  const classes = useStyles();
  const client = Session?.user?.client;
  const score = useStore(state => state.score, shallow);
  const isGuestApp = useStore(state => state.isGuestApp, shallow);
  const allAttempts = useStore(state => state.allAttempts, shallow);
  const setAttemptId = useStore(state => state.setAttemptId, shallow);
  const attemptId = useStore(state => state.attemptId, shallow);
  const content = useStore(state => state.content, shallow);
  const speech = useStore(state => state.speech, shallow);
  const mobile = React.useMemo(() => isMobile(), []);

  const currentAttempt = React.useMemo(() => {
    return allAttempts.find(attempt => attempt._id === attemptId);
  }, [attemptId, allAttempts]);

  return (
    <>
      {
        (!mobile && !isGuestApp) ?
          <WhiteBox id='view-scores-container' style={{ padding: '20px' }}>
            <WhiteBoxHeader
              id='view-scores'
              heading={
                summaryLoading ? <HeaderSkeleton /> :
                  template.type === templateTypes.PATHWAY
                    ? <PathwayHeader template={template} />
                    : <TandTHeader template={template} attempt={currentAttempt} />
              }
              style={{ marginBottom: '0px' }}
            >
              {
                (!isGuestApp) && <SimpleSelect
                  label='Select attempt'
                  options={allAttempts}
                  onSelect={(data) => setAttemptId(data._id)}
                  value={{ _id: attemptId }}
                  defaultValue={{ _id: attemptId }}
                  loading={attemptsLoading}
                />
              }
            </WhiteBoxHeader>
          </WhiteBox> : null
      }

      <WhiteBox style={{ padding: '0px' }}>
        <Box className={classes.sectionRoot}>
          <Box className={classes.meterBox}>
            <Typography variant='h5-medium'>
              Overall Score&nbsp;
              <InfoIconWithTooltip
                style={{ fontSize: '20px' }}
                title={
                  `Your overall score is a total of your ${client?.metadata?.performanceWeightage?.communication 
                  || 50}% speech and ${client?.metadata?.performanceWeightage?.content || 50}% content`
                }
                placement='top'
              />
            </Typography>

            {
              summaryLoading ?
                <Skeleton variant="rectangular" width={150} height={100} />
                :
                <Meter
                  value={getTwoDigitNo(round(score))}
                  breakpoint1={50} breakpoint2={70}
                  width={150}
                />
            }
            {
              (showComparison && !summaryLoading)
                ? <BootstrapTooltip
                    title={'Reflects changes in average score after your last attempt.'} 
                    placement='top'
                  >
                    <Box>
                      <ComparisonChip change={overallChange} /> 
                    </Box>
                  </BootstrapTooltip>
                : null
            }
          </Box>
          <Box className={classes.overallSections}>
            <ParameterFeedback
              category='speech'
              title='Speech Score'
              score={speech}
              change={speechChange}
              showComparison={showComparison}
              info='Your overall Speech Score of this attempt'
              loading={summaryLoading}
            />
          </Box>
          <Box className={classes.overallSections}>
            <ParameterFeedback
              category='content'
              title='Content Score'
              score={content}
              change={contentChange}
              showComparison={showComparison}
              info='Your overall Content Score of this attempt'
              loading={summaryLoading}
            />
          </Box>
        </Box>
      </WhiteBox>
    </>
  );
}

export default OverallPerformance;