export const state = [
  {
    _id: "andaman_and_nicobar_islands",
    name: "Andaman & Nicobar Islands",
    districts: [
      { _id: "andaman_and_nicobar_islands", name: "ANDAMAN & NICOBAR ISLANDS" },
      { _id: "nicobars", name: "Nicobars" },
      { _id: "north__and_middle_andaman", name: "North  & Middle Andaman" },
      { _id: "south_andaman", name: "South Andaman" }
    ]
  },  
  {
    _id: "andhra_pradesh",
    name: "Andhra Pradesh",
    districts: [
      { _id: "anantapur", name: "Anantapur" },
      { _id: "chittoor", name: "Chittoor" },
      { _id: "east_godavari", name: "East Godavari" },
      { _id: "guntur", name: "Guntur" },
      { _id: "ysr_kadapa", name: "YSR Kadapa" },
      { _id: "krishna", name: "Krishna" },
      { _id: "kurnool", name: "Kurnool" },
      { _id: "nellore", name: "Nellore" },
      { _id: "prakasam", name: "Prakasam" },
      { _id: "srikakulam", name: "Srikakulam" },
      { _id: "visakhapatnam", name: "Visakhapatnam" },
      { _id: "vizianagaram", name: "Vizianagaram" },
      { _id: "west_godavari", name: "West Godavari" }
    ]
  },
  {
    _id: "arunachal_pradesh",
    name: "Arunachal Pradesh",
    districts: [
      { _id: "anjaw", name: "Anjaw" },
      { _id: "changlang", name: "Changlang" },
      { _id: "dibang_valley", name: "Dibang Valley" },
      { _id: "east_kameng", name: "East Kameng" },
      { _id: "east_siang", name: "East Siang" },
      { _id: "kra_daadi", name: "Kra Daadi" },
      { _id: "kurung_kumey", name: "Kurung Kumey" },
      { _id: "lohit", name: "Lohit" },
      { _id: "longding", name: "Longding" },
      { _id: "lower_dibang_valley", name: "Lower Dibang Valley" },
      { _id: "lower_siang", name: "Lower Siang" },
      { _id: "lower_subansiri", name: "Lower Subansiri" },
      { _id: "namsai", name: "Namsai" },
      { _id: "pakke_kessang", name: "Pakke-Kessang" },
      { _id: "papum_pare", name: "Papum Pare" },
      { _id: "shi_yomi", name: "Shi Yomi" },
      { _id: "siang", name: "Siang" },
      { _id: "tawang", name: "Tawang" },
      { _id: "tirap", name: "Tirap" },
      { _id: "upper_siang", name: "Upper Siang" },
      { _id: "upper_subansiri", name: "Upper Subansiri" },
      { _id: "west_kameng", name: "West Kameng" },
      { _id: "west_siang", name: "West Siang" }
    ]
  },
  {
    _id: "assam",
    name: "Assam",
    districts: [
      { _id: "baksa", name: "Baksa" },
      { _id: "barpeta", name: "Barpeta" },
      { _id: "biswanath", name: "Biswanath" },
      { _id: "bongaigaon", name: "Bongaigaon" },
      { _id: "cachar", name: "Cachar" },
      { _id: "charaideo", name: "Charaideo" },
      { _id: "chirang", name: "Chirang" },
      { _id: "darrang", name: "Darrang" },
      { _id: "dhemaji", name: "Dhemaji" },
      { _id: "dhubri", name: "Dhubri" },
      { _id: "dibrugarh", name: "Dibrugarh" },
      {_id : 'dima_hasao' , name : 'Dima Hasao'},
      { _id: "goalpara", name: "Goalpara" },
      { _id: "golaghat", name: "Golaghat" },
      { _id: "hailakandi", name: "Hailakandi" },
      { _id: "hojai", name: "Hojai" },
      { _id: "jorhat", name: "Jorhat" },
      { _id: "kamrup", name: "Kamrup" },
      { _id: "kamrup_metropolitan", name: "Kamrup Metropolitan" },
      { _id: "karbi_anglong", name: "Karbi Anglong" },
      { _id: "karimganj", name: "Karimganj" },
      { _id: "kokrajhar", name: "Kokrajhar" },
      { _id: "lakhimpur", name: "Lakhimpur" },
      { _id: "majuli", name: "Majuli" },
      { _id: "morigaon", name: "Morigaon" },
      { _id: "nagaon", name: "Nagaon" },
      { _id: "nalbari", name: "Nalbari" },
      { _id: "dima_haso", name: "Dima Hasao" },
      { _id: "sivasagar", name: "Sivasagar" },
      { _id: "sonitpur", name: "Sonitpur" },
      { _id: "south_salmara_mankachar", name: "South Salmara-Mankachar" },
      { _id: "tinsukia", name: "Tinsukia" },
      { _id: "udalguri", name: "Udalguri" },
      { _id: "west_karbi_anglong", name: "West Karbi Anglong" }
    ]
  },      
  {
    _id: "bihar",
    name: "Bihar",
    districts: [
      { _id: "araria", name: "Araria" },
      { _id: "arwal", name: "Arwal" },
      { _id: "aurangabad", name: "Aurangabad" },
      { _id: "banka", name: "Banka" },
      { _id: "begusarai", name: "Begusarai" },
      { _id: "bhagalpur", name: "Bhagalpur" },
      { _id: "bhojpur", name: "Bhojpur" },
      { _id: "buxar", name: "Buxar" },
      { _id: "darbhanga", name: "Darbhanga" },
      {_id : "gopalganj" , name : "Gopalganj"},
      { _id: "gaya", name: "Gaya" },
      { _id: "jamui", name: "Jamui" },
      { _id: "jehanabad", name: "Jehanabad" },
      {_id : "kaimur" , name : "Kaimur" },
      { _id: "katihar", name: "Katihar" },
      { _id: "khagaria", name: "Khagaria" },
      { _id: "kishanganj", name: "Kishanganj" },
      { _id: "lakhisarai", name: "Lakhisarai" },
      { _id: "madhepura", name: "Madhepura" },
      { _id: "madhubani", name: "Madhubani" },
      { _id: "munger", name: "Munger" },
      { _id: "muzaffarpur", name: "Muzaffarpur" },
      { _id: "nalanda", name: "Nalanda" },
      {_id : "nawada" , name : "Nawada" },
      { _id: "pashchim_champaran", name: "Pashchim Champaran" },
      { _id: "patna", name: "Patna" },
      { _id: "purba_champaran", name: "Purba Champaran" },
      { _id: "purnia", name: "Purnia" },
      { _id: "rohtas", name: "Rohtas" },
      { _id: "saharsa", name: "Saharsa" },
      { _id: "samastipur", name: "Samastipur" },
      { _id: "saran", name: "Saran" },
      { _id: "sheikhpura", name: "Sheikhpura" },
      {_id : "sheohar" , name : "Sheohar"},
      { _id: "sitamarhi", name: "Sitamarhi" },
      { _id: "siwan", name: "Siwan" },
      {_id : "supaul" , name : "Supaul"},
      { _id: "vaishali", name: "Vaishali" },
      { _id: "west_champaran", name: "West Champaran" },
      { _id: "buxar", name: "Buxar" },
      { _id: "sheohar", name: "Sheohar" },
      { _id: "supaul", name: "Supaul" },
      { _id: "khagaria", name: "Khagaria" },
      { _id: "madhepura", name: "Madhepura" },
      { _id: "madhubani", name: "Madhubani" },
      { _id: "muzaffarpur", name: "Muzaffarpur" },
      {_id : "vaishali" , name : "vaishali" },
    ]
  },  
  {
    _id: "chandigarh",
    name: "Chandigarh",
    districts: [
      { _id: "chandigarh", name: "Chandigarh" }
    ]
  },
  {
    _id: "chhattisgarh",
    name: "Chhattisgarh",
    districts: [
      { _id: "balod", name: "Balod" },
      { _id: "baloda_bazar", name: "Baloda Bazar" },
      { _id: "balrampur", name: "Balrampur" },
      { _id: "bastar", name: "Bastar" },
      { _id: "bemetara", name: "Bemetara" },
      { _id: "bijapur", name: "Bijapur" },
      { _id: "bilaspur", name: "Bilaspur" },
      { _id: "dakshin_bastar_dantewada", name: "Dakshin Bastar Dantewada" },
      { _id: "dhamtari", name: "Dhamtari" },
      { _id: "durg", name: "Durg" },
      { _id: "gariaband", name: "Gariaband" },
      { _id: "gaurela_pendra_marwahi", name: "Gaurela-Pendra-Marwahi" },
      { _id: "janjgir_champa", name: "Janjgir-Champa" },
      { _id: "jashpur", name: "Jashpur" },
      { _id: "kabirdham", name: "Kabirdham" },
      { _id: "kanker", name: "Kanker" },
      { _id: "kondagaon", name: "Kondagaon" },
      { _id: "korba", name: "Korba" },
      { _id: "koriya", name: "Koriya" },
      { _id: "mahasamund", name: "Mahasamund" },
      { _id: "mungeli", name: "Mungeli" },
      { _id: "narayanpur", name: "Narayanpur" },
      { _id: "raigarh", name: "Raigarh" },
      { _id: "raipur", name: "Raipur" },
      { _id: "rajnandgaon", name: "Rajnandgaon" },
      { _id: "sukma", name: "Sukma" },
      { _id: "surajpur", name: "Surajpur" },
      { _id: "surguja", name: "Surguja" },
      { _id: "uttar_bastar_kanker", name: "Uttar Bastar Kanker" }
    ]
  },
  {
    _id: "dadra_and_nagar_haveli_and_daman_and_diu",
    name: "Dadra and Nagar Haveli and Daman and Diu",
    districts: [
      { _id: "dadra_and_nagar_haveli", name: "Dadra and Nagar Haveli" },
      { _id: "daman", name: "Daman" },
      { _id: "diu", name: "Diu" }
    ]
  },
  {
    _id: "delhi",
    name: "Delhi",
    districts: [
      { _id: "central_delhi", name: "Central Delhi" },
      { _id: "east_delhi", name: "East Delhi" },
      { _id: "new_delhi", name: "New Delhi" },
      { _id: "north_delhi", name: "North Delhi" },
      { _id: "north_east_delhi", name: "North East Delhi" },
      { _id: "north_west_delhi", name: "North West Delhi" },
      { _id: "south_delhi", name: "South Delhi" },
      { _id: "south_east_delhi", name: "South East Delhi" },
      { _id: "south_west_delhi", name: "South West Delhi" },
      { _id: "west_delhi", name: "West Delhi" }
    ]
  },
  {
    _id: "goa",
    name: "Goa",
    districts: [
      { _id: "north_goa", name: "North Goa" },
      { _id: "south_goa", name: "South Goa" }
    ]
  },
  {
    _id: "gujarat",
    name: "Gujarat",
    districts: [
      { _id: "ahmedabad", name: "Ahmedabad" },
      { _id: "amreli", name: "Amreli" },
      { _id: "anand", name: "Anand" },
      { _id: "aravalli", name: "Aravalli" },
      { _id: "banaskantha", name: "Banaskantha" },
      { _id: "bharuch", name: "Bharuch" },
      { _id: "bhavnagar", name: "Bhavnagar" },
      { _id: "botad", name: "Botad" },
      { _id: "chhota_udaipur", name: "Chhota Udaipur" },
      { _id: "dahod", name: "Dahod" },
      { _id: "dang", name: "Dang" },
      { _id: "devbhoomi_dwarka", name: "Devbhoomi Dwarka" },
      { _id: "gandhinagar", name: "Gandhinagar" },
      { _id: "gir_somnath", name: "Gir Somnath" },
      { _id: "jamnagar", name: "Jamnagar" },
      { _id: "junagadh", name: "Junagadh" },
      { _id: "kachchh", name: "Kachchh" },
      { _id: "kheda", name: "Kheda" },
      { _id: "mahisagar", name: "Mahisagar" },
      { _id: "mehsana", name: "Mehsana" },
      { _id: "morbi", name: "Morbi" },
      { _id: "narmada", name: "Narmada" },
      { _id: "navsari", name: "Navsari" },
      { _id: "panchmahal", name: "Panchmahal" },
      { _id: "patan", name: "Patan" },
      { _id: "porbandar", name: "Porbandar" },
      { _id: "rajkot", name: "Rajkot" },
      { _id: "sabarkantha", name: "Sabarkantha" },
      { _id: "surat", name: "Surat" },
      { _id: "surendranagar", name: "Surendranagar" },
      { _id: "tapi", name: "Tapi" },
      { _id: "vadodara", name: "Vadodara" },
      { _id: "valsad", name: "Valsad" }
    ]
  },
  {
    _id: "haryana",
    name: "Haryana",
    districts: [
      { _id: "ambala", name: "Ambala" },
      { _id: "bhiwani", name: "Bhiwani" },
      { _id: "charkhi_dadri", name: "Charkhi Dadri" },
      { _id: "faridabad", name: "Faridabad" },
      { _id: "fatehabad", name: "Fatehabad" },
      { _id: "gurugram", name: "Gurugram" },
      { _id: "hisar", name: "Hisar" },
      { _id: "jhajjar", name: "Jhajjar" },
      { _id: "jind", name: "Jind" },
      { _id: "kaithal", name: "Kaithal" },
      { _id: "karnal", name: "Karnal" },
      { _id: "kurukshetra", name: "Kurukshetra" },
      {_id : "mewat" , name : "Mewat" },
      { _id: "mahendragarh", name: "Mahendragarh" },
      { _id: "nuh", name: "Nuh" },
      { _id: "palwal", name: "Palwal" },
      { _id: "panchkula", name: "Panchkula" },
      { _id: "panipat", name: "Panipat" },
      { _id: "rewari", name: "Rewari" },
      { _id: "rohtak", name: "Rohtak" },
      { _id: "sirsa", name: "Sirsa" },
      { _id: "sonipat", name: "Sonipat" },
      { _id: "yamunanagar", name: "Yamunanagar" }
    ]
  },
  {
    _id: "himachal_pradesh",
    name: "Himachal Pradesh",
    districts: [
      { _id: "bilaspur", name: "Bilaspur" },
      { _id: "chamba", name: "Chamba" },
      { _id: "hamirpur", name: "Hamirpur" },
      { _id: "kangra", name: "Kangra" },
      { _id: "kinnaur", name: "Kinnaur" },
      { _id: "kullu", name: "Kullu" },
      { _id: "lahaul_spiti", name: "Lahaul and Spiti" },
      { _id: "mandi", name: "Mandi" },
      { _id: "shimla", name: "Shimla" },
      { _id: "sirmaur", name: "Sirmaur" },
      { _id: "solan", name: "Solan" },
      { _id: "una", name: "Una" }
    ]
  },
  {
    _id: "jammu_and_kashmir",
    name: "Jammu and Kashmir",
    districts: [
      { _id: "anantnag", name: "Anantnag" },
      { _id: "bandipora", name: "Bandipora" },
      { _id: "baramulla", name: "Baramulla" },
      { _id: "budgam", name: "Budgam" },
      { _id: "doda", name: "Doda" },
      {_id : "ganderba" , name : "Ganderba" },
      { _id: "jammu", name: "Jammu" },
      { _id : "kargil" , name : "Kargil" },
      { _id: "kathua", name: "Kathua" },
      { _id: "kishtwar", name: "Kishtwar" },
      { _id: "kulgam", name: "Kulgam" },
      { _id: "kupwara", name: "Kupwara" },
      { _id : "leh" , name : "Leh" },
      { _id: "punch", name: "Poonch" },
      { _id: "pulwama", name: "Pulwama" },
      {_id : "ramban" , name : "Ramban" },
      { _id: "rajouri", name: "Rajouri" },
      { _id: "reasi", name: "Reasi" },
      { _id: "samba", name: "Samba" },
      { _id: "shopian", name: "Shopian" },
      { _id: "srinagar", name: "Srinagar" },
      { _id: "udhampur", name: "Udhampur" }
    ]
  },
  {
    _id: "jharkhand",
    name: "Jharkhand",
    districts: [
      { _id: "bokaro", name: "Bokaro" },
      { _id: "chatra", name: "Chatra" },
      { _id: "deoghar", name: "Deoghar" },
      { _id: "dhanbad", name: "Dhanbad" },
      { _id: "dumka", name: "Dumka" },
      { _id: "east_singhbhum", name: "East Singhbhum" },
      { _id: "garhwa", name: "Garhwa" },
      { _id: "giridih", name: "Giridih" },
      { _id: "godda", name: "Godda" },
      { _id: "gumla", name: "Gumla" },
      { _id: "hazaribagh", name: "Hazaribagh" },
      { _id : "jamtara" , name : "Jamtara" },
      { _id: "khunti", name: "Khunti" },
      { _id: "kodarma", name: "Kodarma" },
      { _id: "latehar", name: "Latehar" },
      { _id: "liquor", name: "Liquor" },
      { _id: "lohardaga", name: "Lohardaga" },
      { _id: "pakur", name: "Pakur" },
      { _id: "palamu", name: "Palamu" },
      { _id : "pashchimi_singhbhum" , name : "Pashchimi Sighbhum"},
      { _id : "purbi_singhbhum" , name : "Purbi Singhbum" },
      { _id: "ramgarh", name: "Ramgarh" },
      { _id: "ranchi", name: "Ranchi" },
      { _id: "sahibganj", name: "Sahibganj" },
      { _id: "simdega", name: "Simdega" },
      { _id: "west_singhbhum", name: "West Singhbhum" }
    ]
  },
  {
    _id: "karnataka",
    name: "Karnataka",
    districts: [
      { _id: "bagalkot", name: "Bagalkot" },
      { _id: "ballari", name: "Ballari" },
      { _id: "belagavi", name: "Belagavi" },
      { _id: "bengaluru", name: "Bengaluru" },
      { _id: "bengaluru_rural", name: "Bengaluru Rural" },
      { _id: "bengaluru_urban", name: "Bengaluru Urban" },
      { _id: "bidar", name: "Bidar" },
      { _id : "bijapur" , name : "Bijapur" },
      { _id: "chamarajanagar", name: "Chamarajanagar" },
      { _id: "chikkamagaluru", name: "Chikkamagaluru" },
      { _id: "chikkaballapur", name: "Chikkaballapur" },
      { _id: "chitradurga", name: "Chitradurga" },
      { _id: "dakshina_kannada", name: "Dakshina Kannada" },
      { _id: "davangere", name: "Davangere" },
      { _id: "dharwad", name: "Dharwad" },
      { _id: "gadag", name: "Gadag" },
      { _id : "gulbarga" , name : "Gulbarga" },
      { _id: "hassan", name: "Hassan" },
      { _id: "haveri", name: "Haveri" },
      { _id: "kalaburagi", name: "Kalaburagi" },
      { _id: "kodagu", name: "Kodagu" },
      { _id: "kolar", name: "Kolar" },
      { _id: "koppal", name: "Koppal" },
      { _id: "mandya", name: "Mandya" },
      { _id: "mysuru", name: "Mysuru" },
      { _id: "raichur", name: "Raichur" },
      { _id: "ramanagara", name: "Ramanagara" },
      { _id: "shimoga", name: "Shimoga" },
      { _id: "tumakuru", name: "Tumakuru" },
      { _id: "udupi", name: "Udupi" },
      { _id: "uttara_kannada", name: "Uttara Kannada" },
      { _id: "vijayapura", name: "Vijayapura" },
      { _id: "yadgir", name: "Yadgir" }
    ]
  },
  {
    _id: "kerala",
    name: "Kerala",
    districts: [
      { _id: "alappuzha", name: "Alappuzha" },
      { _id: "ernakulam", name: "Ernakulam" },
      { _id: "idukki", name: "Idukki" },
      { _id: "kannur", name: "Kannur" },
      { _id: "kasaragod", name: "Kasaragod" },
      { _id: "kollam", name: "Kollam" },
      { _id: "kottayam", name: "Kottayam" },
      { _id: "kozhikode", name: "Kozhikode" },
      { _id: "malappuram", name: "Malappuram" },
      { _id: "palakkad", name: "Palakkad" },
      { _id: "pathanamthitta", name: "Pathanamthitta" },
      { _id: "thrissur", name: "Thrissur" },
      { _id: "thiruvananthapuram", name: "Thiruvananthapuram" },
      { _id: "wayanad", name: "Wayanad" }
    ]
  },   
  {
    _id: "lakshadweep",
    name: "Lakshadweep",
    districts: [
      { _id: "agatti", name: "Agatti" },
      { _id: "andover", name: "Andover" },
      { _id: "bangaram", name: "Bangaram" },
      { _id: "kavaratti", name: "Kavaratti" },
      { _id: "minicoy", name: "Minicoy" },
      { _id: "kalapeni", name: "Kalapeni" }
    ]
  },             
  {
    _id: "madhya_pradesh",
    name: "Madhya Pradesh",
    districts: [
      { _id: "agar_malwa", name: "Agar Malwa" },
      { _id: "alirajpur", name: "Alirajpur" },
      { _id: "anuppur", name: "Anuppur" },
      { _id: "ashok_nagar", name: "Ashok Nagar" },
      { _id: "balaghat", name: "Balaghat" },
      { _id: "barwani", name: "Barwani" },
      { _id: "betul", name: "Betul" },
      { _id: "bhind", name: "Bhind" },
      { _id: "bhopal", name: "Bhopal" },
      { _id: "burhanpur", name: "Burhanpur" },
      { _id: "chhatarpur", name: "Chhatarpur" },
      { _id: "chhindwara", name: "Chhindwara" },
      { _id: "damoh", name: "Damoh" },
      { _id: "datia", name: "Datia" },
      { _id : "dewas" , name : "Dewas" },
      { _id: "dhar", name: "Dhar" },
      { _id: "dindori", name: "Dindori" },
      { _id : "guna" , name : "Guna" },
      { _id : "gwalior" , name : "Gwalior"},
      { _id: "gorakhpur", name: "Gorakhpur" },
      { _id: "harda", name: "Harda" },
      { _id: "hoshangabad", name: "Hoshangabad" },
      { _id: "indore", name: "Indore" },
      { _id: "jabalpur", name: "Jabalpur" },
      { _id: "jhabua", name: "Jhabua" },
      { _id: "katni", name: "Katni" },
      { _id: "khandwa", name: "Khandwa" },
      { _id: "khargone", name: "Khargone" },
      { _id: "mandla", name: "Mandla" },
      { _id: "mandsaur", name: "Mandsaur" },
      { _id: "morena", name: "Morena" },
      { _id: "narsinghpur", name: "Narsinghpur" },
      { _id: "nemawar", name: "Nemawar" },
      { _id: "neemuch", name: "Neemuch" },
      { _id: "panna", name: "Panna" },
      { _id : "raisen" , name : "Raisen"},
      { _id : "ratlam" , name : "Ratlam" },
      { _id : "rajgarh" , name : "Rajgarh"},
      { _id: "rewa", name: "Rewa" },
      { _id: "sagar", name: "Sagar" },
      { _id: "satna", name: "Satna" },
      { _id: "sehore", name: "Sehore" },
      { _id: "seoni", name: "Seoni" },
      { _id : "shajapur", name : "Shajapur"},
      { _id: "shivpuri", name: "Shivpuri" },
      { _id: "sidhi", name: "Sidhi" },
      { _id: "singrauli", name: "Singrauli" },
      { _id: "tikamgarh", name: "Tikamgarh" },
      { _id: "udaypur", name: "Udaypur" },
      { _id: "umaria", name: "Umaria" },
      { _id : "ujjain" , name : "Ujjain" },
      { _id: "vidisha", name: "Vidisha" }
    ]
  },  
  {
    _id: "maharashtra",
    name: "Maharashtra",
    districts: [
      { _id: "ahmednagar", name: "Ahmednagar" },
      { _id: "akola", name: "Akola" },
      { _id: "amravati", name: "Amravati" },
      { _id: "aurangabad", name: "Aurangabad" },
      { _id: "beed", name: "Beed" },
      { _id: "bhandara", name: "Bhandara" },
      { _id: "buldhana", name: "Buldhana" },
      { _id: "chandrapur", name: "Chandrapur" },
      { _id: "dhule", name: "Dhule" },
      { _id: "gadchiroli", name: "Gadchiroli" },
      { _id: "gondia", name: "Gondia" },
      { _id: "hingoli", name: "Hingoli" },
      { _id: "jalgaon", name: "Jalgaon" },
      { _id: "jalna", name: "Jalna" },
      { _id: "kolhapur", name: "Kolhapur" },
      { _id: "latur", name: "Latur" },
      { _id: "mumbai_city", name: "Mumbai City" },
      { _id: "mumbai_suburban", name: "Mumbai Suburban" },
      { _id: "nagpur", name: "Nagpur" },
      { _id: "nanded", name: "Nanded" },
      { _id: "nandurbar", name: "Nandurbar" },
      { _id: "nashik", name: "Nashik" },
      { _id : "osmanabad" , name : "Osmanabad"},
      { _id: "parbhani", name: "Parbhani" },
      { _id: "pune", name: "Pune" },
      { _id: "raigad", name: "Raigad" },
      { _id: "ratnagiri", name: "Ratnagiri" },
      { _id: "sangli", name: "Sangli" },
      { _id: "satara", name: "Satara" },
      { _id: "sindhudurg", name: "Sindhudurg" },
      { _id: "solapur", name: "Solapur" },
      { _id: "thane", name: "Thane" },
      { _id: "washim", name: "Washim" },
      { _id: "wardha", name: "Wardha" },
      { _id: "yavatmal", name: "Yavatmal" }
    ]
  },  
  {
    _id: "manipur",
    name: "Manipur",
    districts: [
      { _id: "bishnupur", name: "Bishnupur" },
      { _id: "chandel", name: "Chandel" },
      { _id: "churachandpur", name: "Churachandpur" },
      { _id: "imphal_east", name: "Imphal East" },
      { _id: "imphal_west", name: "Imphal West" },
      { _id: "kangpokpi", name: "Kangpokpi" },
      { _id: "noney", name: "Noney" },
      { _id: "phek", name: "Phek" },
      { _id: "senapati", name: "Senapati" },
      { _id: "tamenglong", name: "Tamenglong" },
      { _id: "tengnoupal", name: "Tengnoupal" },
      { _id: "thoubal", name: "Thoubal" },
      { _id: "ukhrul", name: "Ukhrul" }
    ]
  },
  {
    _id: "meghalaya",
    name: "Meghalaya",
    districts: [
      { _id: "east_jaintia_hills", name: "East Jaintia Hills" },
      { _id: "east_khasi_hills", name: "East Khasi Hills" },
      { _id: "west_jaintia_hills", name: "West Jaintia Hills" },
      { _id: "west_khasi_hills", name: "West Khasi Hills" },
      { _id: "ribhoi", name: "Ribhoi" },
      { _id: "west_garo_hills", name: "West Garo Hills" },
      { _id: "east_garo_hills", name: "East Garo Hills" },
      { _id: "south_west_garo_hills", name: "South West Garo Hills" },
      { _id: "south_khasi_hills", name: "South Khasi Hills" },
      { _id: "southeast_khasi_hills", name: "Southeast Khasi Hills" }
    ]
  },
  {
    _id: "mizoram",
    name: "Mizoram",
    districts: [
      { _id: "aizawl", name: "Aizawl" },
      { _id: "Champhai", name: "Champhai" },
      { _id: "Kolasib", name: "Kolasib" },
      { _id: "Lawngtlai", name: "Lawngtlai" },
      { _id: "Lunglei", name: "Lunglei" },
      { _id: "Mamit", name: "Mamit" },
      { _id: "Serchhip", name: "Serchhip" },
      { _id: "Siaha", name: "Siaha" },
      { _id: "Hnahthial", name: "Hnahthial" },
      { _id: "Khawzawl", name: "Khawzawl" },
      { _id: "Saitual", name: "Saitual" },
      { _id: "Biate", name: "Biate" }
    ]
  },
  {
    _id: "nagaland",
    name: "Nagaland",
    districts: [
      { _id: "dimapur", name: "Dimapur" },
      { _id: "kohima", name: "Kohima" },
      { _id: "mokokchung", name: "Mokokchung" },
      { _id: "mon", name: "Mon" },
      { _id: "tuensang", name: "Tuensang" },
      { _id: "wokha", name: "Wokha" },
      { _id: "zunheboto", name: "Zunheboto" },
      { _id: "peren", name: "Peren" },
      { _id: "kiphire", name: "Kiphire" },
      { _id: "longleng", name: "Longleng" }
    ]
  },
  {
    _id: "odisha",
    name: "Odisha",
    districts: [
      { _id: "akhyapada", name: "Akhyapada" },
      { _id: "angul", name: "Angul" },
      { _id: "bargarh", name: "Bargarh" },
      { _id: "baripada", name: "Baripada" },
      { _id: "bhadrak", name: "Bhadrak" },
      { _id: "balangir", name: "Balangir" },
      { _id: "balasore", name: "Balasore" },
      { _id: "cuttack", name: "Cuttack" },
      { _id: "deogarh", name: "Deogarh" },
      { _id: "dhenkanal", name: "Dhenkanal" },
      { _id: "gajapati", name: "Gajapati" },
      { _id: "ganjam", name: "Ganjam" },
      { _id: "jagatsinghpur", name: "Jagatsinghpur" },
      { _id: "jharsuguda", name: "Jharsuguda" },
      { _id: "jajpur", name: "Jajpur" },
      { _id: "khurda", name: "Khurda" },
      { _id: "kendrapara", name: "Kendrapara" },
      { _id: "kandhamal", name: "Kandhamal" },
      { _id: "koraput", name: "Koraput" },
      { _id: "kalahandi", name: "Kalahandi" },
      { _id: "malkangiri", name: "Malkangiri" },
      { _id: "mayurbhanj", name: "Mayurbhanj" },
      { _id: "nabarangpur", name: "Nabarangpur" },
      { _id: "nayagarh", name: "Nayagarh" },
      { _id: "puri", name: "Puri" },
      { _id: "rayagada", name: "Rayagada" },
      { _id: "sambalpur", name: "Sambalpur" },
      { _id: "sonapur", name: "Sonapur" },
      { _id: "sundargarh", name: "Sundargarh" }
    ]
  },
  {
    _id: "puducherry",
    name: "Puducherry",
    districts: [
      { _id: "puducherry", name: "Puducherry" },
      { _id: "karaikal", name: "Karaikal" },
      { _id: "mahe", name: "Mahe" },
      { _id: "yanam", name: "Yanam" }
    ]
  },
  {
    _id: "punjab",
    name: "Punjab",
    districts: [
      { _id: "amritsar", name: "Amritsar" },
      { _id: "barnala", name: "Barnala" },
      { _id: "bathinda", name: "Bathinda" },
      { _id: "faridkot", name: "Faridkot" },
      { _id: "fatehgarh_sahib", name: "Fatehgarh Sahib" },
      { _id: "firozpur", name: "Firozpur" },
      { _id: "gurdaspur", name: "Gurdaspur" },
      { _id: "hoshiarpur", name: "Hoshiarpur" },
      { _id: "jalandhar", name: "Jalandhar" },
      { _id: "kapurthala", name: "Kapurthala" },
      { _id: "mansa", name: "Mansa" },
      { _id: "moga", name: "Moga" },
      { _id: "muktsar", name: "Muktsar" },
      { _id: "patiala", name: "Patiala" },
      { _id: "ropar", name: "Rupnagar (Ropar)" },
      { _id: "s.a.s._nagar", name: "S.A.S. Nagar (Mohali)" },
      { _id: "sangrur", name: "Sangrur" },
      { _id: "shaheed_bhagat_singh_nagar", name: "Shaheed Bhagat Singh Nagar" },
      { _id: "tarn_taran", name: "Tarn Taran" },
      { _id: "fazilka", name: "Fazilka" },
      { _id: "pathankot", name: "Pathankot" }
    ]
  },
  {
    _id: "rajasthan",
    name: "Rajasthan",
    districts: [
      { _id: "ajmer", name: "Ajmer" },
      { _id: "alwar", name: "Alwar" },
      { _id: "banswara", name: "Banswara" },
      { _id: "baran", name: "Baran" },
      { _id: "barmer", name: "Barmer" },
      { _id: "bharatpur", name: "Bharatpur" },
      { _id: "bhilwara", name: "Bhilwara" },
      { _id: "bikaner", name: "Bikaner" },
      { _id: "bundi", name: "Bundi" },
      { _id: "chittorgarh", name: "Chittorgarh" },
      { _id: "churu", name: "Churu" },
      { _id: "dholpur", name: "Dholpur" },
      { _id: "dungarpur", name: "Dungarpur" },
      { _id: "hanumangarh", name: "Hanumangarh" },
      { _id : "jaipur" , name : "Jaipur"},
      { _id: "jaisalmer", name: "Jaisalmer" },
      { _id: "jalor", name: "Jalor" },
      { _id: "jhalawar", name: "Jhalawar" },
      { _id: "jhunjhunu", name: "Jhunjhunu" },
      { _id: "karauli", name: "Karauli" },
      { _id: "kota", name: "Kota" },
      { _id: "nagaur", name: "Nagaur" },
      { _id: "pali", name: "Pali" },
      { _id: "rajsamand", name: "Rajsamand" },
      { _id: "sawai_madhopur", name: "Sawai Madhopur" },
      { _id: "sikar", name: "Sikar" },
      { _id: "sirohi", name: "Sirohi" },
      { _id: "tonk", name: "Tonk" },
      { _id: "udaipur", name: "Udaipur" }
    ]
  },
  {
    _id: "sikkim",
    name: "Sikkim",
    districts: [
      { _id: "east_sikkim", name: "East Sikkim" },
      { _id: "west_sikkim", name: "West Sikkim" },
      { _id: "north_sikkim", name: "North Sikkim" },
      { _id: "south_sikkim", name: "South Sikkim" }
    ]
  },    
  {
    _id: "tamil_nadu",
    name: "Tamil Nadu",
    districts: [
      { _id: "ariyalur", name: "Ariyalur" },
      { _id: "chennai", name: "Chennai" },
      { _id: "coimbatore", name: "Coimbatore" },
      { _id: "cuddalore", name: "Cuddalore" },
      { _id: "dharmapuri", name: "Dharmapuri" },
      { _id: "dindigul", name: "Dindigul" },
      { _id: "erode", name: "Erode" },
      { _id: "kallakurichi", name: "Kallakurichi" },
      { _id: "kancheepuram", name: "Kancheepuram" },
      { _id: "karur", name: "Karur" },
      { _id: "krishnagiri", name: "Krishnagiri" },
      { _id: "madurai", name: "Madurai" },
      { _id: "nagapattinam", name: "Nagapattinam" },
      { _id: "namakkal", name: "Namakkal" },
      { _id: "the_nilgiris", name: "The Nilgiris" },
      { _id: "perambalur", name: "Perambalur" },
      { _id: "pudukkottai", name: "Pudukkottai" },
      { _id: "ramanathapuram", name: "Ramanathapuram" },
      { _id: "salem", name: "Salem" },
      { _id: "sivaganga", name: "Sivaganga" },
      { _id: "thanjavur", name: "Thanjavur" },
      { _id: "theni", name: "Theni" },
      { _id: "thoothukudi", name: "Thoothukudi" },
      { _id: "tiruchirappalli", name: "Tiruchirappalli" },
      { _id: "tirunelveli", name: "Tirunelveli" },
      { _id: "tiruvarur", name: "Tiruvarur" },
      { _id: "vellore", name: "Vellore" },
      { _id: "villupuram", name: "Villupuram" },
      { _id: "virudhunagar", name: "Virudhunagar" }
    ]
  },
  {
    _id: "telangana",
    name: "Telangana",
    districts: [
      { _id: "adilabad", name: "Adilabad" },
      { _id: "bhadradri_kothagudem", name: "Bhadradri Kothagudem" },
      { _id: "hyderabad", name: "Hyderabad" },
      { _id: "jagtial", name: "Jagtial" },
      { _id: "jangaon", name: "Jangaon" },
      { _id: "janagaon", name: "Janagaon" },
      { _id: "khammam", name: "Khammam" },
      { _id: "karimnagar", name: "Karimnagar" },
      { _id: "kamareddy", name: "Kamareddy" },
      { _id: "kothagudem", name: "Kothagudem" },
      { _id: "mahabubnagar", name: "Mahabubnagar" },
      { _id: "medak", name: "Medak" },
      { _id: "medchal", name: "Medchal" },
      { _id: "nalgonda", name: "Nalgonda" },
      { _id: "nirmal", name: "Nirmal" },
      { _id: "nizamabad", name: "Nizamabad" },
      { _id: "peddapalli", name: "Peddapalli" },
      { _id: "rangareddy", name: "Rangareddy" },
      { _id: "rajanna_sircilla", name: "Rajanna Sircilla" },
      { _id: "sangareddy", name: "Sangareddy" },
      { _id: "siddipet", name: "Siddipet" },
      { _id: "warangal", name: "Warangal" },
      { _id: "khammam", name: "Khammam" }
    ]
  },
  {
    _id: "tripura",
    name: "Tripura",
    districts: [
      { _id: "agartala", name: "Agartala" },
      { _id: "belonia", name: "Belonia" },
      { _id: "dhalai", name: "Dhalai" },
      { _id: "gumti", name: "Gumti" },
      { _id: "khowai", name: "Khowai" },
      { _id: "north_tripura", name: "North Tripura" },
      { _id: "south_tripura", name: "South Tripura" },
      { _id: "unakoti", name: "Unakoti" },
      { _id: "west_tripura", name: "West Tripura" }
    ]
  },      
  {
    _id: "uttar_pradesh",
    name: "Uttar Pradesh",
    districts: [
      {_id: "agra", name: "Agra" },
      {_id: "aligarh", name: "Aligarh" },
      {_id: "ambedkar_nagar", name: "Ambedkar Nagar" },
      {_id: "amethi", name: "Amethi" },
      {_id: "amroha", name: "Amroha" },
      {_id: "auraiya", name: "Auraiya" },
      {_id: "ayodhya", name: "Ayodhya" },
      {_id: "azamgarh", name: "Azamgarh" },
      {_id: "badaun", name: "Badaun" },
      {_id: "baghpat", name: "Baghpat" },
      {_id: "bahraich", name: "Bahraich" },
      {_id: "ballia", name: "Ballia" },
      {_id: "balrampur", name: "Balrampur" },
      {_id: "banda", name: "Banda" },
      {_id: "barabanki", name: "Barabanki" },
      {_id: "bareilly", name: "Bareilly" },
      {_id: "basti", name: "Basti" },
      {_id: "bijnor", name: "Bijnor" },
      {_id: "bulandshahr", name: "Bulandshahr" },
      {_id: "chandauli", name: "Chandauli" },
      {_id: "chitrakoot", name: "Chitrakoot" },
      {_id: "deoria", name: "Deoria" },
      {_id: "etah", name: "Etah" },
      {_id: "etawah", name: "Etawah" },
      {_id: "farrukhabad", name: "Farrukhabad" },
      {_id: "fatehpur", name: "Fatehpur" },
      {_id: "firozabad", name: "Firozabad" },
      {_id: "gautam_buddh_nagar", name: "Gautam Buddh Nagar" },
      {_id: "ghaziabad", name: "Ghaziabad" },
      {_id: "ghazipur", name: "Ghazipur" },
      {_id: "gonda", name: "Gonda" },
      {_id: "gorakhpur", name: "Gorakhpur" },
      {_id: "hamirpur", name: "Hamirpur" },
      {_id: "hapur", name: "Hapur" },
      {_id: "hardoi", name: "Hardoi" },
      {_id: "hathras", name: "Hathras" },
      {_id: "jalaun", name: "Jalaun" },
      {_id: "jaunpur", name: "Jaunpur" },
      {_id: "jhansi", name: "Jhansi" },
      {_id: "kanpur_dehat", name: "Kanpur Dehat" },
      {_id: "kanpur_nagar", name: "Kanpur Nagar" },
      {_id: "kasganj", name: "Kasganj" },
      {_id: "kaushambi", name: "Kaushambi" },
      {_id: "kheri", name: "Kheri" },
      {_id: "kushinagar", name: "Kushinagar" },
      {_id: "lalitpur", name: "Lalitpur" },
      {_id: "lucknow", name: "Lucknow" },
      {_id: "maharajganj", name: "Maharajganj" },
      {_id: "mahoba", name: "Mahoba" },
      {_id: "mainpuri", name: "Mainpuri" },
      {_id: "mathura", name: "Mathura" },
      {_id: "mau", name: "Mau" },
      {_id: "meerut", name: "Meerut" },
      {_id: "mirzapur", name: "Mirzapur" },
      {_id: "moradabad", name: "Moradabad" },
      {_id: "muzaffarnagar", name: "Muzaffarnagar" },
      {_id: "pilibhit", name: "Pilibhit" },
      {_id: "pratapgarh", name: "Pratapgarh" },
      {_id: "prayagraj", name: "Prayagraj" },
      {_id: "rae_bareli", name: "Rae Bareli" },
      {_id: "rampur", name: "Rampur" },
      {_id: "saharanpur", name: "Saharanpur" },
      {_id: "sambhal", name: "Sambhal" },
      {_id: "sant_kabir_nagar", name: "Sant Kabir Nagar" },
      {_id: "sant_ravidas_nagar", name: "Sant Ravidas Nagar" },
      {_id: "shahjahanpur", name: "Shahjahanpur" },
      {_id: "shamli", name: "Shamli" },
      {_id: "shravasti", name: "Shravasti" },
      {_id: "siddharthnagar", name: "Siddharthnagar" },
      {_id: "sitapur", name: "Sitapur" },
      {_id: "sonbhadra", name: "Sonbhadra" },
      {_id: "sultanpur", name: "Sultanpur" },
      {_id: "unnao", name: "Unnao" },
      {_id: "varanasi", name: "Varanasi" }
    ]
  },  
  {
    _id: "uttarakhand",
    name: "Uttarakhand",
    districts: [
      { _id: "almora", name: "Almora" },
      { _id: "bageshwar", name: "Bageshwar" },
      { _id: "baheri", name: "Baheri" }, 
      { _id: "bardhiyana", name: "Bardhiyana" }, 
      { _id: "dehradun", name: "Dehradun" },
      { _id: "haridwar", name: "Haridwar" },
      { _id: "nainital", name: "Nainital" },
      { _id: "pithoragarh", name: "Pithoragarh" },
      { _id: "pauri", name: "Pauri Garhwal" },
      { _id: "rudraprayag", name: "Rudraprayag" },
      { _id: "tehri", name: "Tehri Garhwal" },
      { _id: "udham_singh_nagar", name: "Udham Singh Nagar" },
      { _id: "chamoli", name: "Chamoli" },
      { _id: "champawat", name: "Champawat" },
      { _id: "uttarkashi", name: "Uttarkashi" }
    ]
  },  
  {
    _id: "west_bengal",
    name: "West Bengal",
    districts: [
      { _id: "alipurduar", name: "Alipurduar" },
      { _id: "bankura", name: "Bankura" },
      { _id: "bardhaman", name: "Bardhaman" },
      { _id: "birbhum", name: "Birbhum" },
      { _id: "dakshin_dinajpur", name: "Dakshin Dinajpur" },
      { _id: "darjeeling", name: "Darjeeling" },
      { _id: "hooghly", name: "Hooghly" },
      { _id: "howrah", name: "Howrah" },
      { _id: "jalpaiguri", name: "Jalpaiguri" },
      { _id: "kalimpong", name: "Kalimpong" },
      { _id: "kooch_behar", name: "Kooch Behar" },
      { _id: "malda", name: "Malda" },
      { _id: "murshidabad", name: "Murshidabad" },
      { _id: "nadia", name: "Nadia" },
      { _id: "north_24_parganas", name: "North 24 Parganas" },
      { _id: "paschim_bardhaman", name: "Paschim Bardhaman" },
      { _id: "paschim_medinipur", name: "Paschim Medinipur" },
      { _id: "purba_bardhaman", name: "Purba Bardhaman" },
      { _id: "purba_medinipur", name: "Purba Medinipur" },
      { _id: "purulia", name: "Purulia" },
      { _id: "south_24_parganas", name: "South 24 Parganas" },
      { _id: "uttar_dinajpur", name: "Uttar Dinajpur" }
    ]
  },  
];