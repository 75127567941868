import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { getCommentAndColor } from './PerformanceReview/helpers';
import { round } from 'utils';
import InfoIconWithTooltip, { BootstrapTooltip } from './InfoIconWithTooltip';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const useStyles = makeStyles((theme) => ({
    progressContainer: {
        position: 'relative',
        display: 'inline-flex',
        backgroundColor: theme.palette.neutral['clr-100'],
        borderRadius: "50%",
    },
    progressTextContainer: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.shades['clr-white-900'],
        borderRadius: "50%",
        margin: "10%",
    },
    percentageUp: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        borderRadius: 4,
        padding: theme.spacing(0, 1),
        border: '1px solid',
        borderColor: theme.palette.success['clr-500'],
        color: theme.palette.success['clr-500'],
        ...theme.typography['body01-bold']
      },
      percentageDown: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        borderRadius: 4,
        border: '1px solid',
        padding: theme.spacing(0, 1),
        borderColor: theme.palette.danger['clr-500'],
        color: theme.palette.danger['clr-500'],
        ...theme.typography['body01-bold']
    },
    label: {
        display: 'flex', alignItems: 'center', borderRadius: '16px',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2)
    }, dot: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
    }, comment: {
        borderRadius: '4px',
        padding: '2px 8px 2px 8px',
        minWidth: '168px',
        width: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));
function CircularProgressWithLabel(props) {
    const classes = useStyles();

    return (
        <Box
            className={classes.progressContainer}
            width={props.size || 140} height={props.size || 140}
        >
            <CircularProgress
                variant="determinate"
                sx={{ color: props.bgcolor, }}
                value={props.value}
                thickness={props.thickness}
                size={props.size}
            />
            <Box className={classes.progressTextContainer}>
                <Typography variant={props.fontVariant} color={props.fontColor}>
                    {`${round(props.value)}`}
                </Typography>


                {(props.showComparison) &&
                  <BootstrapTooltip
                    title={`Reflects changes in average ${props.category} score after your last attempt.`} 
                    placement='top'
                  >
                    <Box
                        className={(
                            props.percentageChange >= 0 ?
                                classes.percentageUp :
                                classes.percentageDown
                        )}
                        color={props.percentageChange >= 0 ? "#00C7BE" : "#FF2D55"}
                    >
                        {props.percentageChange >= 0 ? (
                            <ArrowUpwardIcon fontSize='inherit' />
                        ) : (
                            <ArrowDownwardIcon fontSize='inherit' />
                        )}
                        {props.percentageChange < 0 ? -round(props.percentageChange) :
                            round(props.percentageChange)
                        }%
                    </Box>
                  </BootstrapTooltip>
                }
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export default function CircleProgressbarWithInfo(
    {
        value = 0, size = 164, fontVariant = "h4-semiBold", labelVariant='h5-semiBold', thickness = 4, 
        category='speech',
        tooltip = '', label = '', showComparison = false, percentageChange = 0, ...props
    }
) {
    const data = getCommentAndColor(value);
    const classes = useStyles();

    return (
        <Box
            display='flex' flexDirection='column'
            justifyContent={'center'} alignItems={'center'}
        >
            <CircularProgressWithLabel
                value={value}
                bgcolor={data.color}
                fontColor={data.fontColor}
                thickness={thickness}
                size={size}
                category={category}
                fontVariant={fontVariant}
                showComparison={showComparison}
                percentageChange={percentageChange}
                {...props}
            />

            {
                (label) &&
                <Box className={classes.label}>
                    <Typography variant={labelVariant} color='neutral.clr-700'>
                        {label}
                    </Typography>

                    {(tooltip) && <InfoIconWithTooltip
                        placement='right'
                        title={tooltip}
                        fontSize='medium'
                    />}
                </Box>
            }

            <Box
                className={classes.comment} mt={2}
                sx={{ backgroundColor: data.bgColor }}
            >
                <Typography variant={'body01-bold'} color={data.fontColor}>
                    {data.comment}
                </Typography>
            </Box>
        </Box>
    );
}
