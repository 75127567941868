import { isNumber } from "lodash";
import theme from "theme";

export const CommentAndColor = {
    VERY_GOOD: {
        index: 2,
        comment: 'Bravo',
        label: 'VERY_GOOD',
        tag: 'Bravo',
        color: '#00664A',
        fontColor: '#00664A',
        bgColor: '#DCFCE7',
        overallComment: 'You are doing great ✨',
        img: 'https://assets.languify.in/images/good-emoji.svg',
        content: 'Your content is excellent! Keep up the great work. Consider adding even more depth and breadth to your material to maintain your high standard. Continuously seek feedback to further refine your content.',
        speech: 'Your speech is excellent! Continue practicing to maintain your high standard. Try incorporating more dynamic gestures and expressions to enhance your delivery.',
        feedback: "Your overall score is very good, indicating that you have exceptional verbal communication skills that allow you to connect with others on a deeper level.",
        subFeatureFeedback: {
            'CONFIDENCE': 'Your confidence was outstanding, commanding attention and conveying assurance in your responses.',
            'DELIVERY': 'Vocal delivery was exemplary, showcasing a dynamic range that captivated attention.',
            'FLUENCY': 'Your fluency is exceptional; you communicate your ideas seamlessly and with confidence.',
            'WORD_CHOICE': 'Your vocabulary was outstanding, showcasing a rich and varied choice of words that enhanced your communication.',
            'LOGIC_FLOW': 'You exhibited excellent logic flow, presenting ideas in a coherent and compelling manner.',
            'RELEVANCE': 'Your inputs were impeccably relevant, demonstrating a keen understanding of the subject matter.',
            'SIMILARITY': 'Your responses were highly similar to the ideal responses, clearly reflecting a strong understanding of the questions.',
        }
    },
    GOOD: {
        index: 2,
        comment: 'Good',
        label: 'GOOD',
        color: '#00664A',
        fontColor: '#00664A',
        tag: 'Good',
        bgColor: '#DCFCE7',
        overallComment: 'You are doing great ✨',
        img: 'https://assets.languify.in/images/good-emoji.svg',
        content: 'Your content is well-researched and informative. Now, work on adding your unique voice and perspective to make it truly stand out.',
        speech: 'Good job! Your speech is engaging and easy to follow. Now, work on incorporating more expressive elements like pauses and emphasis to truly captivate your audience.',
        feedback: 'Congratulations, your overall score is good! This means you have solid verbal communication skills that enable you to effectively express yourself and connect with others.',
        subFeatureFeedback: {
            'CONFIDENCE': 'Your confidence was good, allowing you to engage well with the questions posed.',
            'DELIVERY': ' Vocal delivery was effective, with appropriate tone and pace enhancing your communication.',
            'FLUENCY': 'Your fluency is commendable; you articulate your thoughts clearly and effectively.',
            'WORD_CHOICE': ' Your vocabulary was strong, effectively conveying your ideas with precise language.',
            'LOGIC_FLOW': 'You followed a logical structure, making your points easy to understand.',
            'RELEVANCE': 'Your inputs were largely relevant and effectively connected to the interview topics.',
            'SIMILARITY': 'Your responses were quite similar to ideal responses and aligned well with the questions asked.',
        }

    },
    FAIR: {
        index: 1,
        comment: 'Satisfactory',
        label: 'FAIR',
        color: '#664100',
        tag: 'Avg',
        fontColor: '#664100',
        bgColor: '#FFF6E5',
        overallComment: 'You will be there soon',
        img: 'https://assets.languify.in/images/avg-emoji.svg',
        content: 'Your content is good, but it can be more engaging. Add interesting details, examples, and anecdotes to make your points more compelling. Ensure your content is well-structured and easy to follow.',
        speech: 'Your speech is good, but there is room for improvement. Focus on varying your tone and pace to keep the audience engaged. Practice speaking in front of others to gain confidence.',
        feedback: "Your overall score is fair, indicating that you have some strengths in verbal communication but also areas for improvement. Consider using clear language and practicing empathy.",
        subFeatureFeedback: {
            'CONFIDENCE': 'You displayed some confidence, though it fluctuated and could be more consistent.',
            'DELIVERY': 'Vocal delivery was clear but could benefit from more variation in tone and pacing.',
            'FLUENCY': 'Your fluency is acceptable, but strive for a more natural flow in your responses.',
            'WORD_CHOICE': 'Your vocabulary was sufficient, but incorporating a wider range of terms would strengthen your answers.',
            'LOGIC_FLOW': 'You had some logical flow, though transitions could be smoother to improve coherence.',
            'RELEVANCE': 'While you mentioned some relevant information, They sometimes went off-topic.',
            'SIMILARITY': 'Your responses were somewhat similar to ideal responses, but some aspects strayed from the main topics.',
        }
    },
    POOR: {
        index: 0,
        comment: 'Needs improvement',
        label: 'POOR',
        color: '#C51407',
        fontColor: '#C51407',
        tag: 'Low',
        bgColor: '#FFE6E3',
        overallComment: 'Lets do some hard work',
        img: 'https://assets.languify.in/images/bad-emoji.svg',
        content: 'Focus on organizing your thoughts better. Ensure your key points are clear and well-supported by evidence. Research thoroughly and practice structuring your content logically.',
        speech: 'Work on your pronunciation, pace, and clarity. Practice speaking slowly and enunciating your words. Record yourself and listen to identify areas for improvement.',
        feedback: "Your overall score is poor, indicating that you may struggle to communicate effectively with others. Consider seeking feedback and practicing active listening to improve your communication skills.",
        subFeatureFeedback: {
            'CONFIDENCE': 'Lack of confidence was evident, affecting your overall presence during the interview.',
            'DELIVERY': 'Vocal delivery lacked clarity and varied significantly, making it difficult to understand your points.',
            'FLUENCY': 'Your fluency needs improvement; consider practicing smoother transitions between your points.',
            'WORD_CHOICE': 'Your vocabulary was limited, which restricted the depth of your responses.',
            'LOGIC_FLOW': ' Your answers lacked logical structure, making it hard to follow your reasoning.',
            'RELEVANCE': 'The information presented was largely irrelevant, making it difficult to assess your knowledge of the subject.',
            'SIMILARITY': 'Your responses did not adequately address the questions, lacking similarity to the ideal response.',
        }
    },
};

export const getOverallPerformanceFeedback = (speech = 0, content = 0) => {
    if (speech < 50 && content < 50) {
        return 'Focus on both content clarity and speech delivery. Practice organizing your thoughts better and work on your pronunciation and articulation.'
    }
    else if (speech < 70 && content < 50) {
        return 'While your speech is clear, your content needs more structure and depth. Ensure your key points are well-defined and supported by evidence.'
    }
    else if (speech < 90 && content < 50) {
        return 'Your presentation is engaging, but your ideas need more substance. Strengthen your arguments with research and examples.'
    }
    else if (speech <= 100 && content < 50) {
        return 'Your delivery is excellent, but the content lacks depth. Spend more time researching and organizing your thoughts and content to match your speech quality.'
    }

    if (speech < 50 && content < 70) {
        return 'Your content is decent, but your speech could use work. Practice speaking more clearly and confidently to improve your delivery.'
    }
    else if (speech < 70 && content < 70) {
        return " You have a good foundation, but there's room for improvement. Continue practicing both your speech and content for a more polished performance."
    }
    else if (speech < 90 && content < 70) {
        return 'Your speech is strong, but your content could use more depth. Expand on your ideas and provide more supporting evidence.'
    }
    else if (speech <= 100 && content < 70) {
        return 'Your speech is excellent, but your content could be more engaging. Add more interesting details and examples to elevate your content.'
    }

    if (speech < 50 && content < 90) {
        return 'Your content is strong, but your speech could use some improvement. Focus on clarity and delivery to enhance overall impact.'
    }
    else if (speech < 70 && content < 90) {
        return 'Your content is outstanding and well-presented. Work on refining your speech to make it more engaging and polished.'
    }
    else if (speech < 90 && content < 90) {
        return 'Well done! Both your speech and content are effective. Improvements are still needed. Keep refining both to maintain high standards of communication.'
    }
    else if (speech <= 100 && content < 90) {
        return 'Excellent job! Your speech is outstanding, and your content is excellent. Continue to showcase your expertise with compelling delivery and insightful content.'
    }

    if (speech < 50 && content <= 100) {
        return 'Your content is excellent, but your speech needs improvement. Focus on clarity and confidence in your delivery to match the quality of your content.'
    }
    else if (speech < 70 && content <= 100) {
        return 'Your content is outstanding, and your speech is good. Fine-tune your delivery to ensure your excellent content is communicated effectively.'
    }
    else if (speech < 90 && content <= 100) {
        return "Your content is excellent, and your speech is engaging. Please add expressive elements and depth to hold the audience's attention and maintain your high standard."
    }
    else if (speech <= 100 && content <= 100) {
        return 'Great job! Both your content and speech are excellent. Keep up the good work and continue refining your skills.'
    }
}

export const CategoryMap = {
    "FLUENCY": "FLUENCY",
    "WORD_CHOICE": "WORD_CHOICE",
    "DELIVERY": "DELIVERY",
    "CONFIDENCE": "CONFIDENCE",
    "SIMILARITY": "SIMILARITY",
    "RELEVANCE": "RELEVANCE",
    "LOGIC_FLOW": "LOGIC_FLOW",
};

export const SubCategoryMap = {
    "PAUSES": "PAUSES",
    "DISFLUENCY": "DISFLUENCY",
    "PACE": "PACE",
    "ENERGY": "ENERGY",
    "JARGON_WORDS": "JARGON_WORDS",
    "INFORMAL_WORDS": "INFORMAL_WORDS",
    "FILLER_WORDS": "FILLER_WORDS",
    "FILLER_PHRASES": "FILLER_PHRASES",
    "FILLER_UTTERANCES": "FILLER_UTTERANCES",
    "RARE_USED_WORDS": "RARE_USED_WORDS",
};

export const SubCategoryData = {
    "FILLER_WORDS": {
        category: 'FLUENCY',
        subCategory: "FILLER_WORDS",
        idealRange: { min: 0, max: 5 },
        data: { value: {} },
        valueGetter: (data) => (data?.percentage || 0)
    },
    "DISFLUENCY": {
        category: 'FLUENCY',
        subCategory: "DISFLUENCY",
        idealRange: { min: 0, max: 5 },
        data: { value: {} },
        valueGetter: (data) => (data?.percentage || 0)
    },
    "PACE": {
        category: 'DELIVERY',
        subCategory: "PACE",
        idealRange: { min: 100, max: 150 },
        data: { value: {} },
        valueGetter: (data) => (data?.value || 0),
        getTag: (value) => (value < 100 ? 'low' : (value <= 150 ? 'ideal' : 'high'))
    },
    "ENERGY": {
        category: 'DELIVERY',
        subCategory: "ENERGY",
        idealRange: { min: 1, max: 2 },
        valueGetter: (data) => (data?.value || 0),
        getTag: (value) => (value < 1 ? 'low' : (value <= 2 ? 'ideal' : 'high'))
    },
};

export const Categories = {
    "FLUENCY": "Fluency",
    "WORD_CHOICE": "Vocabulary",
    "DELIVERY": "Vocal Delivery",
    "CONFIDENCE": "Confidence",
    "SIMILARITY": "Similarity",
    "RELEVANCE": "Relevance",
    "LOGIC_FLOW": "Logic flow",
};

export const SubCategories = {
    "PAUSES": "Pauses",
    "DISFLUENCY": "Disfluency",
    "PACE": "Pace",
    "ENERGY": "Energy",
    "JARGON_WORDS": "Jargon words",
    "INFORMAL_WORDS": "Informal words",
    "FILLER_WORDS": "Fillers words",
    "FILLER_PHRASES": "Fillers phrases",
    "FILLER_UTTERANCES": "Fillers utterances",
    "RARE_USED_WORDS": "Rare used words",
};

export const CategoriesDescription = {
    "FLUENCY": "Ability to speak smoothly, with clear, continuous flow, and minimal pauses or disruptions",
    "WORD_CHOICE": "Collection of words and phrases a person uses when speaking or communicating",
    "DELIVERY": "How someone talks, focusing on how fast they speak & their energy level",
    "CONFIDENCE": "Clear, assertive communication with a positive tone, indicating self-assuredness and command of the topic",
    "SIMILARITY": "How much things are alike",
    "RELEVANCE": "How well the response addresses the topic or question's core requirement, staying on point",
    "LOGIC_FLOW": "Clear, sequential progression of ideas, connecting concepts cohesively to reach a well-supported conclusion"
};

export const CategoriesMetrics = {
    "FLUENCY": "It depends on how smoothly you deliver response without pauses or hesitations",
    "WORD_CHOICE": "It evaluates the range, diverseness and complexity of words used",
    "DELIVERY": "It depends on Speaking pace & Energy of your response",
    "CONFIDENCE": "It measures how assured and assertive you sound while speaking",
    "SIMILARITY": "It measures how closely your response matches to the ideal response",
    "RELEVANCE": "It evaluates the presence of domain-specific jargon and keywords",
    "LOGIC_FLOW": "It assesses clear, organized sequence of ideas in your response"
};

export const DummySampleAnswers = [
    "I am an experienced software engineer with over 5 years of experience in developing software applications. My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients. In my free time, I enjoy reading books on technology and programming.",
    "My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients. In my free time, I enjoy reading books on technology and programming.I am an experienced software engineer with over 5 years of experience in developing software applications."
];

export const DummyPersonalizedAnswer = " In my free time, I enjoy reading books on technology and programming. I am an experienced software engineer with over 5 years of experience in developing software applications. My areas of expertise include developing web applications, mobile applications, and working with various programming languages such as Java, Python, and JavaScript. I have a strong background in computer science, having completed my Bachelor's degree in Computer Science from a renowned university. Currently, I am working with XYZ Company, where I am responsible for designing and developing software solutions that meet the needs of our clients.";

export const DummyContentFeedback = "Aman, your introduction was so impressive it left us speechless! We were absolutely blown away by your impressive communication skills. Not only were you able to express yourself clearly and precisely, but you also have knocked out of the park with numerous designing projects. Furthermore, your mastery of the use of language is something nearly all professionals strive for. Clearly you've already got a step up on the competition! Keep up the amazing work!";

export const SubCategoriesDescription = {
    "PAUSES": "Frequency and duration of pauses in speech",
    "DISFLUENCY": "Frequency of disruptions and irregularities in speech",
    "PACE": "Speed of speech",
    "ENERGY": "Level of enthusiasm and energy conveyed through language",
    "JARGON_WORDS": "Use of specialized language or jargon",
    "INFORMAL_WORDS": "Use of casual and informal language",
    "FILLER_WORDS": "Filler words are short pauses to fill up the gap but they usually don't have any meaning",
    "FILLER_PHRASES": "Frequent use of unnecessary phrases and filler words",
    "FILLER_UTTERANCES": "(um, uh) are sounds used in speech during pauses",
    "RARE_USED_WORDS": "Unearth gems for your vocabulary - unique meaning, evocative power",
};

export const CategoryScoreTag = {
    CONFIDENCE: {
        POOR: 'You seemed underconfident while responding to the question.',
        FAIR: 'You have potential, though there’s room to boost your confidence further.',
        GOOD: 'Good confidence observed. keep honing it to reach your fullest potential.',
        VERY_GOOD: '',
    },
    SIMILARITY: {
        POOR: 'Your response needs significant improvement to align with the ideal answer.',
        FAIR: 'Your response is fairly aligned with the ideal answer, but can be improved.',
        GOOD: 'Your response is close to the ideal answer, with slight adjustments needed.',
        VERY_GOOD: '',
    },
    LOGIC_FLOW: {
        POOR: 'Your response lacks a clear structure, making it hard to follow your ideas.',
        FAIR: 'You’ve shared some good ideas, but the flow could be improved for better clarity.',
        GOOD: 'Your response is well-structured, minor improvements needed for better flow.',
        VERY_GOOD: '',
    },
}

export const CategoryTips = {
    WORD_CHOICE: {
        POOR: [
            "Try exploring different books or articles to pick up new words every day.",
        ],
        FAIR: [
            "Add some variety to your vocabulary to make your responses stand out.",
        ],
        GOOD: [
            "Keep pushing yourself with more advanced words; it’ll make your speech even richer!",
        ],
        VERY_GOOD: [
            "Outstanding work! Your vocabulary is impressive, showcasing a strong command of language and a commendable use of rare words. Additionally, you can read the blog below to further enhance your performance!",
        ],
    },
    CONFIDENCE: {
        POOR: [
            "Practice speaking in front of a mirror to boost self-assurance.",
        ],
        FAIR: [
            "Focus on positive self-talk and believe in your abilities.",
        ],
        GOOD: [
            "Keep practicing to build a natural, steady confidence in your delivery.",
        ],
        VERY_GOOD: [
            "Great job! Your confidence really shone through in your response. Continue to trust in your abilities and articulate your thoughts clearly. Additionally, you can read the blog below to further enhance your performance!",
        ],
    },
    SIMILARITY: {
        POOR: [
            "Focus on understanding the core concepts to build a stronger foundation.",
        ],
        FAIR: [
            "Statements were relevant but can improve with more practice to match the ideal answer",
        ],
        GOOD: [
            "Practice explaining key points clearly and concisely to strengthen your response.",
        ],
        VERY_GOOD: [
            "Well done! Your response is impressively aligned with the ideal answer! Always focus on key concepts, provide concise explanations. Additionally, you can read the blog below to further enhance your performance!",
        ],
    },
    RELEVANCE: {
        POOR: [
            "Try incorporating domain-specific terms to strengthen your response.",
        ],
        FAIR: [
            "Try incorporating domain-specific terms to strengthen your response.",
        ],
        GOOD: [
            "Good effort! Adding a few more domain-specific terms could enhance your score.",
        ],
        VERY_GOOD: [
            "Excellent job! Your response is rich in domain-specific vocabulary! Keep exploring relevant terminology to deepen your expertise. Additionally, you can read the blog below to further enhance your performance!",
        ],
    },
    LOGIC_FLOW: {
        POOR: [
            "Break down your response into clear steps to create a logical sequence.",
        ],
        FAIR: [
            "Try connecting each idea with transition phrases to create a smoother flow.",
        ],
        GOOD: [
            "Clarify connections between points for smoother flow.",
        ],
        VERY_GOOD: [
            "Fantastic! Your response demonstrates a strong logical progression, making your points easy to understand. Additionally, you can read the blog below to further enhance your performance!",
        ],
    },
};

export const PaceEnergyDynamicTips = {
    // pace_energy: tip
    'low_low': "Add enthusiasm to your tone and try to speed up your delivery slightly.",
    'low_ideal': "Increase your speaking pace a bit to match your energy level for better engagement.",
    'low_high': "Focus on a quicker pace while moderating your energy to maintain clarity.",
    'ideal_low': "Use vocal variety and emphasize key points to inject more energy into your delivery.",
    'ideal_ideal': "Great job! Your Speaking pace & Energy is Ideal in your response. Maintain this balance by consistently practicing your delivery style. Additionally, you can read the blog below to further enhance your performance!",
    'ideal_high': "Incorporate gestures and varied intonation to sustain your energetic delivery.",
    'high_low': "Slow down your pace and take deep breaths to boost your energy and clarity.",
    'high_ideal': "Slow your pace slightly to ensure your energy matches your delivery.",
    'high_high': "Reduce your pace and take pauses to help manage your energy effectively."
}

export const FeedbackTourSteps = [
    {
        id: "view-scores",
        title: "View Scores",
        message: "See your overall, speech & content score for the selected assessment. Click on “Detailed suggestions” to see your parameter wise score for each question.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "attempts-performance",
        title: "Your attempts performance",
        message: "See how did you perform in each question of selected attempt. Also, compare attempts performance for practice assessments in the right section.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "question-specific-feedback",
        title: "Question specific feedback",
        message: "Find question specific analysis of your answer on the content & speech parameters. Get parameter wise suggestions for the improvement.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "bookmark-question",
        title: "Bookmark question",
        message: "Save you important questions by clicking on bookmark icon, for future read & practice. Find your bookmarked question in the “Bookmarks page” in the side menu.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "improvement-improved-response",
        title: "Tips for improvement & Improved response",
        message: "Find what you should improve in your answer for getting better in content & speech.  Also, we are giving improved response of “your response”. So, you can get more clarity.",
        skipText: null,
        action: "Next",
        hideClose: false,
        data: {},
    },
    {
        id: "tips-for-improvement",
        title: "Tips for improvement",
        message: "Find blog & activity to get improved in the particular parameter. So that next time you can score better.",
        action: "Finish Tour for this Page",
        data: {}
    },
    {
        id: "replay-tutorial",
        title: "Replay Tutorial",
        message: "You can replay the tutorial for this page if you get stuck anywhere!",
        action: "Finish",
        data: {}
    }
];

export const templateTypes = {
    ASSIGNMENT: "assignment",
    PRACTICE: "practice",
    FOLLOW_UP: "follow_up",
    PERSONALIZED: "personalized",
    PATHWAY: "pathway"
}

export const Tags = {
    WORD_CHOICE: "Vocabulary consists of words and terms used in communication, typically with specific meanings.",
    CONFIDENCE: "Confidence is the unwavering assurance in one's speech, conveying self-assuredness and conviction without unnecessary additions.",
    SIMILARITY: "Degree of resemblance or likeness between the content presented by you and the expected or desired content.",
    RELEVANCE: "The extent to which the information provided aligns with the expected or desired context.",
    LOGIC_FLOW: "The organized sequence of steps that lead to a desired outcome, ensuring a logical and coherent sentence.",
}

export const DemoApplications = {
    inprep: "inprep",
    teachAndTrain: "teach-n-train"
}

export const ScoreTypes = [
    { _id: 'overall', name: 'Overall' },
    { _id: 'communication', name: 'Speech' },
    { _id: 'content', name: 'Content' },
]

export const InterviewQuestionTypes = [
    {
        _id: "technical",
        name: "Technical"
    },
    {
        _id: "situational",
        name: "Situational"
    },
];

export const ParameterRangeTags = {
    IDEAL: "IDEAL",
    NON_IDEAL: "NON_IDEAL"
}

export const ComparisonTags = {
    GOOD: 'good',
    OKAY: 'okay',
    REQUIRES_ATTENTION: 'requires attention'
}

export const HomeCategories = [
    "FILLER_WORDS", "PAUSES", "DISFLUENCY", "PACE", "ENERGY"
]

export const GamificationCategories = [
    "FILLER_WORDS", "JARGON_WORDS", "RARE_USED_WORDS", "DISFLUENCY", "PACE", "ENERGY"
]

export const HighlightCategoriesMap = {
    FILLER_WORDS: {
        subCategory: 'FILLER_WORDS',
        title: 'Filler words',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        info: 'Unnecessary gap-fillers in speech; best to minimize use.',
        value: [{ count: 0, positions: [] }],
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    REPETITION: {
        subCategory: 'REPETITION',
        title: 'Repetition',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        info: 'Excessive repeat of words; disrupt communication flow.',
        value: [{ count: 0, positions: [] }],
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    JARGON_WORDS: {
        subCategory: 'JARGON_WORDS',
        title: 'Jargon',
        colors: { primary: theme.palette.success['clr-700'], secondary: theme.palette.success['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: 'Specific terms enhancing professional communication.',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    INFORMAL_WORDS: {
        subCategory: 'INFORMAL_WORDS',
        title: 'Informal words',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: 'Casual language; lessen formality in certain settings.',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    RARE_USED_WORDS: {
        subCategory: 'RARE_USED_WORDS',
        title: 'Rare words',
        colors: { primary: theme.palette.success['clr-700'], secondary: theme.palette.success['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: 'Uncommon words rarely spoken, not often found in everyday language.',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    },
    HINDI_SHARE: {
        subCategory: 'HINDI_SHARE',
        title: 'Hindi',
        colors: { primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100'] },
        value: [{ count: 0, positions: [] }],
        info: '',
        error: (value) => {
            return <span>Used {isNumber(value?.count) ? value?.count : 0} times</span>
        },
    }
}