import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import WhiteIconButton from 'components/WhiteIconButton';
import useAssessmentStore from "./assessmentStore"
import { AssessmentStates } from "./constants"
import { ProctoringContext } from 'contexts';
import { round } from 'utils';

const HEIGHT = '360px';

const sx = {
    container: {
        position: "relative",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 0,
        flexShrink: 0,
    },
    videoContainer: {
        overflow: "hidden",
        height: HEIGHT,
        width: HEIGHT,
        borderRadius: '6px',
    },
    videoContainerOff: {
        minWidth: HEIGHT,
        minHeight: HEIGHT,
        backgroundColor: "#353535",
        justifyContent: "center",
        alignItems: "center",
        padding: "1em",
        borderRadius: '6px',
    },
    icon: {
        position: "relative",
        bottom: 24,
        margin: "0 auto",
    },
    bufferingImg: {
        position: "absolute",
        minWidth: HEIGHT,
        minHeight: HEIGHT,
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '6px',
        zIndex: -1,
        boxShadow: '0px 0px 3px 0px #00000080'
    }
};

function VideoIcon(props) {
    return (
        <img
            alt="Camera"
            src="https://assets.languify.in/images/camera-off-icon.svg"
            width={48}
            {...props}
        />
    );
};

function BufferingImage() {

    return (
        <Box sx={sx.bufferingImg}>
            <Typography variant="h6-medium" color="neutral.clr-600">
                Buffering...
            </Typography>
        </Box>
    );
}

function VideoPreview() {
    const {
        activateImageProctoring, deactivateImageProctoring, onCameraDisable
    } = React.useContext(ProctoringContext);

    const assessmentState = useAssessmentStore(state => state.assessmentState);
    const template = useAssessmentStore(state => state.template);

    const [enabled, setEnabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const streamRef = React.useRef(null);
    const videoRef = useRef();

    const showComponent = React.useMemo(() => {
        return [
            AssessmentStates.SHOWING_TUTORIAL,
            AssessmentStates.RUNNING,
        ].includes(assessmentState);
    }, [assessmentState]);

    const toggleProctoring = (videoEnabled) => {
        if (template && assessmentState === AssessmentStates.RUNNING) {
            if (videoEnabled) {
                const {
                    proctorSettings = {}, metadata: { testTime } = {}
                } = template;

                if (proctorSettings?.imageCaptureProctoring) {
                    const captureRate = round((testTime * 1000) / 4);
                    activateImageProctoring(
                        captureRate, streamRef, videoRef
                    ).catch(console.error);
                }
            } else {
                deactivateImageProctoring(true);
            }
        }
    }

    React.useEffect(() => {
        if (!enabled) onCameraDisable();
    }, [enabled]);

    React.useEffect(() => {
        (async () => {
            if (showComponent && enabled) {
                setLoading(true);
                try {

                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: true,
                    });
                    streamRef.current = stream;
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream;
                        videoRef.current.play();
                    } else {
                        streamRef.current?.getTracks()?.forEach((track) => {
                            track.stop();
                        });
                        setLoading(false);
                    }
                } catch (error) {
                    console.error(error);
                    setEnabled(false);
                    setLoading(false);
                }
            } else {
                streamRef.current?.getTracks()?.forEach((track) => {
                    track.stop();
                });
                setLoading(false);
            }
            toggleProctoring(enabled);
        })();

        return () => {
            streamRef.current?.getTracks()?.forEach(function (track) {
                track.stop();
            });
            setLoading(false);
        }
    }, [enabled, showComponent]);

    return (
        <>
            <Box
                sx={sx.container}
                title="Click to toggle video"
                onClick={() => setEnabled(!enabled)}
            >
                <Box
                    sx={sx.videoContainer}
                    style={{ display: enabled ? "block" : "none" }}
                    height={HEIGHT}
                >
                    <video
                        key={enabled ? "video-on" : "video-off"}
                        playsInline
                        muted
                        onPlay={() => {
                            console.log('video-playing...');
                            setLoading(false);
                        }}
                        onError={() => { console.log('error') }}
                        ref={videoRef}
                        id="video"
                        style={{
                            display: loading ? "none" : "block",
                            width: `calc(${HEIGHT} * (25/9))`,
                            height: `calc(${HEIGHT} * (25/16))`,
                            marginLeft: `-100%`,
                            marginTop: `-25%`,
                            transform: "rotateY(180deg)",
                            WebkitTransform: "rotateY(180deg)",
                            MozTransform: "rotateY(180deg)",

                        }}
                    ></video>
                </Box>
                <Box
                    sx={sx.videoContainerOff}
                    style={{ display: enabled ? "none" : "flex" }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <VideoIcon />
                        <Typography variant="body2" color="#CACACA" mt={1}>
                            Click to turn on your camera
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {loading && <BufferingImage />}

            <WhiteIconButton
                style={sx.icon}
                onClick={() => setEnabled(!enabled)}
            >
                {enabled ? <VideocamIcon /> : <VideocamOffIcon />}
            </WhiteIconButton>
        </>
    );
}

export default VideoPreview;