import { useEffect, useState, useMemo, useContext } from "react";
import dayjs from "dayjs";
import { debounce } from 'lodash';
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { useNavigateWithClient } from "hooks";
import CustomButton, { IconButton } from "components/CustomButton";
import DataGrid from "components/DataGrid";
import StartAssessmentDialog from "dialogs/StartAssessmentDialog";
import { TourProviderContext, useServices, useSnackbar } from "contexts";
import { getRecentTemplateAnalytics } from "services";
import { PerformanceTourSteps } from "./constants";
import { getAverageScore, round } from "utils";
import Pagination from "components/Pagination";
import { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { SearchTextField } from "components/CustomFields";
import { FilterChips } from "components/Filter";
import NoAssessmentsFound from "components/NoAssessmentsFound";
import Chip from "components/Chip";
import { templateTypes } from "components/PerformanceReview/constants";
import { Typography } from "@mui/material";
import { BootstrapTooltip } from "components/InfoIconWithTooltip";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.secondary['clr-50'],
    ...theme.typography['body01-bold'],
    textAlign: "center", lineHeight: 'none',
    color: theme.palette.shades['clr-black-900'],
  },
  row: {
    color: theme.palette.neutral['clr-900'],
    '&:hover': {
      backgroundColor: `${theme.palette.neutral['clr-50']} !important`,
    },
    ...theme.typography['body01-medium'],
    cursor: "pointer",
  },
  tooltip: {
    ...theme.typography['body02-regular'],
  },
  LockRoot: {
    borderRadius: '50%',
    height: '34px', width: '34px',
    display: 'flex', alignItems: 'center', justifyContent: 'center'
  },
  templateName: {
    width: '120px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  }
}));

const filters = [
  { field: 'assignment', type: 'bool', label: 'Assignment', },
  { field: 'practice', type: 'bool', label: 'Practice', },
  { field: 'finished', type: 'bool', label: 'Finished', },
  { field: 'unfinished', type: 'bool', label: 'Unfinished', },
  { field: 'expired', type: 'bool', label: 'Expired', },
];

export function Status({ data }) {
  const theme = useTheme();

  const color = useMemo(() => {
    return ({
      Attempted: {
        primary: theme.palette.success['clr-700'], secondary: theme.palette.success['clr-100']
      },
      Pending: {
        primary: theme.palette.warning['clr-700'], secondary: theme.palette.warning['clr-100']
      },
      Expired: {
        primary: theme.palette.danger['clr-700'], secondary: theme.palette.danger['clr-100']
      }
    })
  }, [data]);

  return (
    <Box>
      <Chip
        content={data.status}
        color={color[data.status].primary}
        bgColor={color[data.status].secondary}
      />
    </Box>
  )
}

export default function AssessmentsTable({ batch, pathway }) {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const service = useServices();
  const theme = useTheme();
  const navigateWithClient = useNavigateWithClient();

  const { play } = useContext(TourProviderContext);

  const [loading, setLoading] = useState(false);
  const [allAssessments, setAllAssessments] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [page, setPage] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({})
  const [assessment, setAssessment] = useState();
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      field: "templateName",
      headerName: "Name",
      headerAlign: "center",
      align: "left",
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const { templateName } = row;

        return (
          <Box display='flex' gap={3} alignItems='center'>
            <Typography variant='body01-medium' className={classes.templateName}>
              { templateName }
            </Typography>
          </Box>
        )
      }
    },
    {
      field: "type",
      headerName: "Type",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      minWidth:118,
      renderCell: ({ row }) => { 
        if(row.type === templateTypes.PERSONALIZED){
          return "Mock Interview";
        }else{
          return row.type.replace(/_/g, " ");
        }
      }
    },
    {
      field: "publishOn",
      headerName: "Published On",
      align: "center",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: 0,
      minWidth: 130,
      renderCell: ({ row }) => (<Status data={row} />),
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "totalAttempts",
      headerName: "Attempts",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "center",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { totalAttempts, expiresOn, type, maxAllowedAttempts } = row;
        const attempted = totalAttempts > 0;

        return (
          (attempted && type !== templateTypes.ASSIGNMENT) ?
            <CustomButton
              sx={{ width: '100%' }}
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                setAssessment({...row, _id: row?.templateId || row._id});
              }}
              disabled={
                dayjs().isAfter(expiresOn) || batch?.removed ||
                (maxAllowedAttempts > 0 && totalAttempts === maxAllowedAttempts)
              }
            >
              Retake
            </CustomButton>
          :
            <CustomButton
              sx={{ width: '100%' }}
              onClick={(e) => {
                e.stopPropagation();
                if(type === templateTypes.PERSONALIZED && !row?.templateId){
                  navigateWithClient(`/personalized-interview/${row._id}`)
                }else{
                  setAssessment({...row, _id: row?.templateId || row._id})
                }
              }}
              disabled={
                (type === 'assignment' && attempted) ||
                dayjs().isAfter(expiresOn) || batch?.removed
              }
            >
              Attempt now
            </CustomButton>
        )
      },
    },
    {
      field: "feedback",
      headerName: "Feedback",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        const { totalAttempts } = row;
        const attempted = totalAttempts > 0;

        return attempted ?(
          <BootstrapTooltip 
            title={<>View feedback</>} 
            placement='top'
          >
          <Box>
            <IconButton>
              <RemoveRedEyeOutlinedIcon/>
            </IconButton>
          </Box>
          </BootstrapTooltip>
        )
        :
        <IconButton disabled={true}>
          <RemoveRedEyeOutlinedIcon/>
        </IconButton>
      },
    },
    {
      field: "overall",
      headerName: "Overall(Out of 100)",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: ({ row }) => {
        return round(getAverageScore(row.communicationOverall, row.contentOverall));
      }
    },
    {
      field: "communication",
      headerName: "Speech(Out of 100)",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: ({ row }) =>
        round(row?.communicationOverall) || 0,
    },
    {
      field: "content",
      headerName: "Content(Out of 100)",
      align: "center",
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: ({ row }) =>
        round(row.contentOverall) || 0,
    }
  ];

  useEffect(() => {
    if (!batch) return;

    (async () => {
      try {
        setLoading(true);

        let filters = {};

        if (pathway?.length) filters.pathway = pathway;

        const templateAnalytics = await service.callService(
          getRecentTemplateAnalytics, batch?._id, filters
        );

        templateAnalytics.forEach(t => {
          let status = "Attempted";

          if (t.totalAttempts === 0) {
            if (dayjs().isAfter(t.expiresOn)) {
              status = "Expired";
            } else {
              status = "Pending";
            }
          }
          t.status = status;
        });

        setAllAssessments(templateAnalytics);
      } catch (error) {
        console.log(error);
        snackbar.error('Unable to load attempts');
      } finally {
        setLoading(false);
      }
    })();
  }, [batch, pathway]);

  useEffect(() => {
    let {
      name, finished, unfinished, practice, assignment, expired
    } = appliedFilters;

    name = name && name.toLowerCase();

    if (!Object.keys(appliedFilters).length) {
      setAssessments(allAssessments);
      return;
    }

    setAssessments(allAssessments.filter((a) => {

      if (name && name !== '' && a.templateName.toLowerCase().includes(name)) {
        return true;
      }

      if (practice && 'practice' === a.type) return true;

      if (assignment && 'assignment' === a.type) return true;

      if (finished && a.status === "Finished") return true;

      if (unfinished && a.status === "Unfinished") return true;

      if (expired && a.status === "Expired") return true;

      return false;
    }))
  }, [appliedFilters, allAssessments]);

  useEffect(() => {
    if (play) {
      const showDummyBatches = allAssessments.length === 0;

      if (showDummyBatches) {
        setAllAssessments(PerformanceTourSteps[2].data.assessments);
      }

      return () => {
        if (showDummyBatches) setAllAssessments([]);
      };
    }
  }, [play]);

  const handleFilterChange = ({ field, value }) => {
    setAppliedFilters({});
    setAppliedFilters((afs) => {
      const newFilters = { ...afs };

      if (value && !Object.keys(appliedFilters).includes(field)) newFilters[field] = value;
      else delete newFilters[field];

      return newFilters;
    });
  };

  const handleChange = (e) => {
    const value = e.target.value.trim();
    if (value.length > 3 || value.length === 0)
      handleFilterChange({ field: "name", value: value });
    setSearchQuery(value);
  };

  const handleInput = debounce(handleChange, 500);

  return (
    <>
      <WhiteBox id="assessments-container">
        <WhiteBoxHeader
          id='assessments'
          heading="Your batch assessments"
          subheading="History of all the assessments published for your batch"
        >
          <SearchTextField
            placeholder="Search assessment..."
            onChange={handleInput}
            disabled={
              (!assessments.length && !searchQuery?.length > 0)
              || !allAssessments?.length
            }
          />
        </WhiteBoxHeader>

        <Divider />

        {
          !(allAssessments.length) ? null :
            <FilterChips
              title="Assessment filters"
              filters={filters}
              appliedFilters={appliedFilters}
              onChange={handleFilterChange}
              disabeled={
                !assessments.length &&
                (Object.keys(appliedFilters).length && searchQuery.length)
              }
            />
        }

        {
          assessments.length ?
            <DataGrid
              hideFooter
              loading={loading}
              paginationMode={'client'}
              filterMode={'client'}
              sortingMode={'client'}
              height="320px"
              rows={assessments}
              columns={columns}
              rowCount={assessments.length}
              pageSize={5}
              page={page}
              onPageChange={setPage}
              components={{ Pagination: null }}
              getRowClassName={(params) => `${classes.row}`}
              density="standard"
              noRowsMessage={"Seems like, you haven't taken any assessment yet!"}
              onRowClick={(params) => {
                if(params.row.totalAttempts !== 0){
                  if(params.row?.templateId && params.row.type === templateTypes.PERSONALIZED){
                    navigateWithClient(`/performance/assessment/${params.row?.templateId}`)
                  }else{
                    navigateWithClient(`/performance/assessment/${params.id}`)
                  }
                }}}
            />
            :
            allAssessments.length ?
              <NoAssessmentsFound
                icon={'https://assets.languify.in/images/no-attempt.png'}
                label={
                  searchQuery?.length ? 'No assessment found' : 'No result found for the applied filter'
                }
                filters={searchQuery?.length ? undefined : filters}
                clearFilters={() => setAppliedFilters({})}
              />
              :
              <NoAssessmentsFound
                icon={'https://assets.languify.in/images/no-attempt.png'}
                label={<>You have not attempted any assessment so far!<br />once attempted you can see assessment details here</>}
              />
        }
        {
          !assessments.length ? null :
            <Box p={2} display="flex" justifyContent="center" mt={2}>
              <Pagination
                count={Math.ceil(assessments.length / 5)}
                page={page}
                setPage={setPage}
              />
            </Box>
        }
      </WhiteBox>
      <StartAssessmentDialog
        onClose={() => setAssessment(undefined)}
        open={!!assessment}
        template={{
          ...assessment,
          name: assessment?.templateName,
          metadata: { testTime: assessment?.testTime }
        }}
        clientMock={assessment?.type === 'personalized' ? true : false}
      />
    </>
  );
}