import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Skeleton from '@mui/material/Skeleton';

import InfoIconWithTooltip, { BootstrapTooltip } from "components/InfoIconWithTooltip";
import { round } from "utils";

const useStyles = makeStyles(theme=> ({
  container: {
    width: 216, height: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: 'space-between',
    borderRadius: theme.spacing(2),
    background: theme.palette.shades['clr-white-900'],
    margin: 0, padding: theme.spacing(5),
    boxShadow: '0px 0px 2px 0px #000000BF',
  },
  heading: {
    display:'flex', alignItems: 'center',
    gap: theme.spacing(1)
  },
  scores: {
    display: "flex",
    alignItems: 'center', height: '30px', 
    gap: theme.spacing(3)
  },
  percentage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0,1),
    borderRadius: theme.spacing(1),
    ...theme.typography['body01-bold'],
  },
  nudgeInc: {
    color: theme.palette.success['clr-500'],
    backgroundColor: theme.palette.success['clr-100']
  },
  nudgeDec: {
    color: theme.palette.danger['clr-500'],
    backgroundColor: theme.palette.danger['clr-100']
  },
  nudgeIcon: { fontSize: 16, marginRight: theme.spacing(1) }
}));

export const AnalyticsBriefSkeleton = () => {
  const classes = useStyles();

  return(
    <Box className={classes.container}>
      <Box>
        <Skeleton variant="rectangular" width="80%" height='30px' />
      </Box>
      <Box className={classes.scores}>
        <Skeleton variant="rectangular" width="60%" height='30px' />
      </Box>
    </Box>
  )
}

export default function AnalyticsBrief({
  title = "Average",
  data = "0",
  percentage = "0",
  increment = true,
  info,
  showComparision = true,
  subTitle = '',
  style={},
  attempts = 2,
  loading=false
}) {
  const classes = useStyles();

  return (
    (loading) ? <AnalyticsBriefSkeleton/> : 

    <Box className={classes.container} sx={style}>
      <Box className={classes.heading}>
        <Typography variant='h6-semiBold'>{title}</Typography>
        <InfoIconWithTooltip title={subTitle} sx={{fontSize: '18px'}} placement='top'/>
      </Box>

      <Box className={classes.scores}>
        <Typography variant='h2-bold'>
          {
            data > 0 
            ? round(data)
            : '--'
          }
        </Typography>

        {(showComparision && attempts > 1 && data>0) ? (
          <BootstrapTooltip title={info} placement='top'>
            <Box
              className={`${classes.percentage} ${increment ? classes.nudgeInc : classes.nudgeDec }`}
            >
              {
                (percentage === 0) ? "~ " :
                increment
                ? <ArrowUpwardIcon className={classes.nudgeIcon} />
                : <ArrowDownwardIcon className={classes.nudgeIcon} />
              }
              {percentage < 0 ? -round(percentage) : round(percentage)}%
            </Box>
          </BootstrapTooltip>
        ) : null}

      </Box>
    </Box>
  );
}
