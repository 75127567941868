import React from 'react';
import "./style.css";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Meter = ({ breakpoint1 = 50, breakpoint2 = 70, value = 90, width=140 }) => {
  const [meterValue, setMeterValue] = React.useState(0);

  React.useEffect(() => {
    let degree = 0;
    const startAngle = 60;
    const endAngle = 120;

    if (value <= breakpoint1) {
      degree = (value / breakpoint1) * startAngle;
    } else if (value <= breakpoint2) {
      degree = startAngle + ((value - breakpoint1) / (breakpoint2 - breakpoint1)) * (endAngle - startAngle);
    } else {
      degree = endAngle + ((value - breakpoint2) / (100 - breakpoint2)) * (180 - endAngle);
    }

    setMeterValue(degree);
  }, [value, breakpoint1, breakpoint2]);

  const { sliderColor= '#E2483C', sx={} } = React.useMemo(()=>{
    return {
      sliderColor: meterValue < 60 
        ? '#E2483C'
        : meterValue < 120 
          ? '#F5B847'
          : '#009970',
      
      sx: {
        width: `${width}px`, height: `${width/2}px`, borderRadius: `${width/2}px ${width/2}px 0px 0px`,
        transformOrigin: `center ${width/2}px`
      }
    }
  },[meterValue, width]);

  return (
    <Box
      display='flex' flexDirection='column' alignItems='center' justifyContent='flex-end'
      width='fit-content' height={`${width/2+10}px`}
    >
      <Box className="wrapper">
        <Box style={{ position: 'relative' }}>
          <Box 
            className="gauge" 
            style={{ width: `${width}px`, height: `${width/2}px`, borderRadius: sx.borderRadius}}
          >
            <Box className="slice-colors">
              <Box className="st slice-item meter" style={sx}></Box>
              <Box className="st slice-item meter" style={sx}></Box>
              <Box className="st slice-item meter" style={sx}></Box>
              <Box 
                className="slider slider-item"
                style={{ 
                  transform: `rotate(${meterValue+180}deg)`,
                  backgroundColor: sliderColor, ...sx
                }}
              ></Box>
            </Box>
            <Box className="gauge-center" style={{borderRadius: sx.borderRadius}}>
              <Box className='gauge-value'>
                <Typography variant='h5-bold' color='primary.main'>
                  {value}
                </Typography>
              </Box>
            </Box>
            <Box className='gauge-border'></Box>
          </Box>
          <Typography className='low-range' variant='overline-bold'>{breakpoint1}</Typography>
          <Typography className='high-range' variant='overline-bold'>{breakpoint2}</Typography>
          <Box className='breakpoint-marker1' style={{ borderTop: `${width/5}px solid #CCD4DE`}}></Box>
          <Box className='breakpoint-marker2' style={{ borderTop: `${width/5}px solid #CCD4DE`}}></Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Meter;