import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import CustomButton from './CustomButton';
import { useRef } from 'react';
import { getViewportDimensions } from '../utils';

const useStyles = makeStyles(theme => ({
  button: {
    width: 120
  },
  conatiner: {
    position: 'absolute',
    maxWidth: 300,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200
    }
  }
}));

const ToolTipPositionTransform = {
  "bottom-middle": { left: "50%", top: -20 },
  "top-middle": { left: "50%", bottom: -20 },
  "top-left": { bottom: 20, right: -20 },
  "top-right": { bottom: 20, left: -20 },
  "bottom-left": { top: 20, right: -20 },
  "bottom-right": { top: 20, left: -20 }
};


const getToolTipPoisition = (tipId, toolTipElement, tooltip) => {

  toolTipElement.scrollIntoView();
  const toolTipElementDomRect = toolTipElement.getBoundingClientRect();

  let toolTipPosition = {};

  const {
    width: tWidth,
    height: tHeight
  } = tooltip.current.getBoundingClientRect();

  const { width: vW, height: vH } = getViewportDimensions();

  let { left, top, bottom, right } = toolTipElementDomRect;
  const width = right - left;
  const height = bottom - top;
  bottom = vH - bottom;
  right = vW - right;

  let ttHorizontalPoisition;

  if (left > tWidth) {
    toolTipPosition.left = left - tWidth - 10;
    ttHorizontalPoisition = "left";
  }

  if (!ttHorizontalPoisition && right > tWidth) {
    toolTipPosition.right = right - tWidth - 10;
    ttHorizontalPoisition = "right";
  }

  if (!ttHorizontalPoisition) {
    ttHorizontalPoisition = `middle`;
    toolTipPosition.left = left + (width / 2) - (tWidth / 2);
  }

  let ttVerticalPosition;

  if (bottom > tHeight) {
    toolTipPosition.bottom =
      bottom - tHeight + height - (ttHorizontalPoisition === 'middle' ? 20 : -20);
    ttVerticalPosition = "bottom";
  }

  if (!ttVerticalPosition && top > tHeight) {
    toolTipPosition.top = top - tHeight + height -50;
    ttVerticalPosition = "top"
  }

  if (!ttVerticalPosition) {
    ttVerticalPosition = `bottom`;
    toolTipPosition.bottom = bottom - tHeight +50;
  }

  return {
    toolTipPosition,
    arrowPosition: `${ttVerticalPosition}-${ttHorizontalPoisition}`,
  };
};

function ToolTipArrow({ position }) {

  const ArrowIcon = React.useMemo(() => {
    if (position === "top-middle") return ArrowDropDownIcon;
    if (position === "bottom-middle") return ArrowDropUpIcon;
    if (position === "top-right" || position === "bottom-right") return ArrowLeftIcon;
    if (position === "top-left" || position === "bottom-left") return ArrowRightIcon;
    return ArrowDropDownIcon;
  }, [position]);

  return (
    <ArrowIcon
      sx={{
        position: 'absolute',
        ...ToolTipPositionTransform[position],
        color: '#fff',
        border: 'none'
      }}
      fontSize={"large"}
    />
  );
};

function TutorialTip({ number, tip, total, onSkip = (e) => { }, onNext = (e) => { } }) {
  const classes = useStyles();

  const [position, setPosition] = useState({ arrowPosition: "top-middle", toolTipPosition: {} });
  const tooltip = useRef();;

  useEffect(() => {
    let toolTipElement = document.getElementById(tip.id);
    if (toolTipElement) {
      setPosition(getToolTipPoisition(tip.id, toolTipElement, tooltip));
      const windowResizeListener = () => {
        setPosition(getToolTipPoisition(tip.id, toolTipElement, tooltip));
      }
      window.addEventListener('resize', windowResizeListener);

      return () => {
        window.removeEventListener('resize', windowResizeListener);
      }
    }
    else onNext();
  }, [tip]);

  return (
    <Box
      className={classes.conatiner}
      {...position.toolTipPosition}
      ref={tooltip}
    >
      <Box position="relative" padding={3}>
        <ToolTipArrow position={position.arrowPosition} />
        <Box
          display='flex'
          justifyContent='space-between' mb='10px'
        >
          <Typography variant='body2' color='#858C90'>
            Tutorial
          </Typography>
          <Typography variant='body2' color='#858C90'>
            {number} of {total}
          </Typography>
        </Box>
        <Typography variant='body1' color='#000'>
          {tip.message}
        </Typography>
        <Box
          display='flex'
          justifyContent='space-between'
          mt={2}
        >
          <CustomButton
            variant='contained'
            onClick={onNext}
            className={classes.button}
          >
            {number === total ? "Done" : "Next"}
          </CustomButton>
          {number !== total && (
            <CustomButton
              variant='text'
              onClick={onSkip}
              className={classes.button}
            >
              Skip
            </CustomButton>)}
        </Box>
      </Box>
    </Box>
  );
}

export default TutorialTip;
