import React from 'react';
import { Line } from 'react-chartjs-2';
import Box from '@mui/material/Box';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

import { round } from 'utils';
import { WhiteBoxHeader } from './AppShell';
import { SimpleSelect } from './CustomSelectFields';
import NoAssessmentsFound from './NoAssessmentsFound';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DefaultOptions = {
  maintainAspectRatio: true,
  responsive: true,
  aspectRatio: 5,
  interaction: { mode: 'index', intersect: false },
  stacked: false,
  elements: { line: { tension: 0.2 } },
  plugins: {
    title: { display: false },
    legend: { display: false },
    tooltip: {
      events: ['click', 'mousemove', 'mouseout'],
      enabled: false,
      position: "average",
    }
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      min: 0,
      max: 100,
      title: {
        display: true,
        text: 'Score',
      },
      ticks: {
        stepSize: 25,
        font: {
          family: '"Mulish", sans-serif',
          weight: 700,
          size: '14px',
          lineHeight: '120%',
          letterSpacing: '0px',
        }
      }
    },
    x: {
      ticks: {
        font: {
          family: '"Mulish", sans-serif',
          weight: 700,
          size: '14px',
          lineHeight: '120%',
          letterSpacing: '0px',
        }
      }
    },
  },
  layout: { padding: 5, }
};

const useStyles = makeStyles(theme => ({
  root: {
    my: 0,
    background: theme.palette.shades['clr-white-900'],
  },
  chartCotainer: {
    width: "100%",
    position: "relative",
    pl: "0%",
    pr: "0%",
  },
  xLabel: {
    ...theme.typography['body02-bold'],
    textAlign: 'center',
    color: theme.palette.neutral['clr-900']
  },
  tooltip: {
    position: "absolute",
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.shades['clr-offWhite'],
    padding: theme.spacing(2),
    transform: "all 200ms",
    pointerEvents: "none",
    minWidth: '150px',
    maxWidth: '250px',
    height: 'fit-content',
    boxShadow: '0px 2px 16px 0px #00000029',
    border: '2px solid'
  },
  tooltipHeading: {
    color: theme.palette.neutral['clr-700'],
    textTransform: "capitalize",
    padding: '0px', margin: '0px',
    ...theme.typography['body02-bold']
  },
  tooltipData: {
    paddingTop: theme.spacing(3),
    width: "100%",
    display: "flex",
    alignItems: "center", gap: theme.spacing(2)
  },
  tooltipDataValue: {
    ...theme.typography['body02-medium']
  },
  tooltipDataLabel: {
    textTransform: 'capitalize',
    ...theme.typography['body02-medium']
  },
  tooltipScoreIndicator: {
    width: '10px', height: '10px',
    borderRadius: '50%'
  },
  skeleton: {
    width:"100%", height:'300px',
    borderRadius: theme.spacing(2)
  }
}));

function PerformanceGraphHeader({
  hideAssessmentTypeFilter, assessmentType, setAssessmentType, parameter, setParameter,
}) {

  const assessmentTypes = React.useMemo(() => ([
    { _id: 'allAssessments', name: 'All Assessments' },
    { _id: 'practice', name: 'Practice' },
    { _id: 'assignment', name: 'Assignment' }
  ]), []);

  const parameters = React.useMemo(() => ([
    { _id: 'overall', name: 'Overall' },
    { _id: 'communication', name: 'Speech' },
    { _id: 'content', name: 'Content' }
  ]), []);

  return (
    <WhiteBoxHeader
      id='recent-attempts-analysis'
      heading='Recent Attempts Analytics'
      subheading="Overview of your performance for recent attempts"
    >
      <Box display="flex" alignItems='center' gap={2}>
        {
          hideAssessmentTypeFilter ? null :
            <SimpleSelect
              options={assessmentTypes}
              onSelect={data => setAssessmentType(data)}
              value={assessmentType}
              label='Assessment type'
            />
        }
        <SimpleSelect
          options={parameters}
          onSelect={data => setParameter(data)}
          value={parameter}
          label='Score type'
        />
      </Box>
    </WhiteBoxHeader>
  );
}

export const PerformanceGraphSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.chartCotainer}>
      <Skeleton variant="rectangular" className={classes.skeleton}/>
    </Box>
  )
}

export function PerformanceGraph(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [options, setOptions] = React.useState(DefaultOptions);

  options.onClick = (e) => {
    const dataIndex = e?.chart?.tooltip?.dataPoints?.[0]?.dataIndex;
    if (typeof dataIndex === "number") props.onClick(dataIndex)
  };

  const data = React.useMemo(() => {
    if (props.data) {
      const para = props?.parameter?._id.toLowerCase();

      return props.data.map(d => round(d[para]));
    }
  }, [props.parameter, props.data]);

  const colorScale = (mark => {
    if (mark >= 80) return {primary: '#00664A', secondary: '#DCFCE7'};
    if (mark > 40) return {primary: '#BF7900', secondary: '#FFF6E5'};
    return {primary: '#C51407', secondary: '#FFE6E3'};
  });

  React.useEffect(() => {
    return () => {
      const tooltipEl = document.getElementById("chartjs-tooltip");
      if (tooltipEl) {
        tooltipEl.remove();
      }
    };
  }, []);

  const getGraphToolTip = (context) => {
    const { chart } = context;
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.classList.add(classes.tooltip);
      document.body.appendChild(tooltipEl);
    }
    tooltipEl.innerHTML = "";

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    const dataIndex = tooltipModel.dataPoints?.[0].dataIndex;
    const dataToConsider = props?.data || [];

    const borderColor = colorScale(dataToConsider[dataIndex][props?.parameter?._id || 'overall']);
    tooltipEl.style.borderColor = borderColor.primary;
    tooltipEl.style.backgroundColor = borderColor.secondary;

    const title = props?.tooltipCallbacks(dataIndex) || 'title';

    const heading = document.createElement("p");
    heading.className = classes.tooltipHeading;
    heading.innerHTML = `${title}`;

    tooltipEl.appendChild(heading);

    const data = document.createElement("div");
    data.style.width = "100%";

    data.innerHTML = `
    <div class='${classes.tooltipData}'>
      <div class='${classes.tooltipDataLabel}'>
        ${props?.parameter === 'communication' ? 'speech' : props?.parameter?._id || 'overall'}:
      </div>
      <div class='${classes.tooltipDataValue}'>
        ${round(dataToConsider[dataIndex][props?.parameter?._id || 'overall'])}
      </div>
    <div>
    `

    tooltipEl.appendChild(data);

    // Display, position, and set styles for font
    const position = chart.canvas.getBoundingClientRect();
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 100 + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.clientHeight - 30 + 'px';
    tooltipEl.style.pointerEvents = 'none';

  };

  const labels = React.useMemo(() => {
    const _labels = props?.data?.map((item, index) => {
      return (props?.labelPrefix || '').concat(index + 1);
    });

    if (_labels?.length < 10) {
      for (let i = _labels.length; i < 10; i++) {
        _labels.push('')
      }
    }

    return _labels;
  }, [props.data, props.labelPrefix]);

  React.useEffect(() => {
    setOptions((options) => {
      const newOptions = { ...options };

      newOptions.plugins.tooltip.external = getGraphToolTip;

      newOptions.aspectRatio = props.aspectRatio;
      newOptions.scales.y.title = {
        ...newOptions.scales.y.title,
        color: theme.palette.neutral['clr-900'],
        font: {
          family: '"Mulish", sans-serif',
          weight: 700,
          size: '14px',
          lineHeight: '120%',
          letterSpacing: '0px',
        },
      }

      return newOptions;
    });
  }, [props.aspectRatio, props.tooltipCallbacks]);

  return (
    <Box className={classes.root} id='recent-attempts-analysis-container'>
      {!props.hideHeader && (
        <>
          <PerformanceGraphHeader
            hideAssessmentTypeFilter={props?.hideAssessmentTypeFilter || false}
            assessmentType={props.assessmentType}
            setAssessmentType={props.setAssessmentType}
            parameter={props.parameter}
            setParameter={props.setParameter}
          /> <br />
        </>
      )}
      {
        data?.length > 0 ?
          <Box className={classes.chartCotainer}>
            <Line
              style={{ cursor: "pointer" }}
              options={options}
              data={{
                labels,
                datasets: [
                  {
                    label: (props.parameter?._id === 'communication') ?
                      'Speech' :
                      props.parameter,
                    data: data,
                    borderWidth: 2,
                    borderColor: theme.palette.neutral['clr-400'],
                    backgroundColor: theme.palette.shades['clr-white-900'],
                    pointBorderWidth: 3,
                    yAxisID: 'y',
                    pointStyle: 'circle',
                    pointRadius: 5,
                    hoverRadius: 6,
                    pointBorderColor: data.map((score) => {
                      return colorScale(score).primary
                    }),
                    hoverBorderWidth: 5,
                  }
                ],
              }}
            />
            <Box className={classes.xLabel}>
              {props.xLabel || "Attempt"}
            </Box>
          </Box>
          : 
          <NoAssessmentsFound
            filters={
              props.parameter?._id !== 'overall' ||
              props.assessmentType?._id !== 'allAssessments'
            }
            clearFilters={() => {
              props.setParameter({ _id: 'overall', name: 'Overall' },);
              props.setAssessmentType( { _id: 'allAssessments', name: 'All Assessments' });
            }}
          />
      }
    </Box>
  );
}
