import React from 'react';
import Box from '@mui/material/Box';
import useStore from './store';
import {shallow} from 'zustand/shallow';
import CustomButton from 'components/CustomButton';
import RetakeIcon from '@mui/icons-material/Cached';

import { useSnackbar } from 'contexts';
import { getFeedbackAndAnswer } from 'services';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoIconWithTooltip from 'components/InfoIconWithTooltip';
import { templateTypes } from './constants';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap:theme.spacing(5),
        maxHeight: '356px',
        overflow: 'auto'
    },
    tipsContainer:{
        display:'flex',
        flexDirection:'column',
        gap:theme.spacing(1)
    },
    bulletPoints:{
        margin:'0px',
        paddingLeft:'20px'
    },
    error: {
        width: '100%',
        height: 200,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.shades['clr-white-900'],
        border: '1px solid',
        borderColor: theme.palette.neutral['clr-300']
    }
}));

function PersonalizedFeedback() {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const activeQuestion = useStore(state => state.questions?.[state.currentQuestion]);
    const currentQuestionIndex = useStore(state => state.currentQuestion, shallow);
    const questions = useStore(state => state.questions, shallow);
    const setQuestions = useStore(state => state.setQuestions, shallow);
    const template = useStore(state => state.template, shallow);

    const [loading, setLoading] = React.useState(false);

    const generateFeedbackAndAnswer = async () => {
        setLoading(true);
        try {
            const result = await getFeedbackAndAnswer(
                activeQuestion?.answerId,
                template?.domain,
                template.pathway ? true : false,
                template.metadata?.personalizedTemplateType === "exam" 
                  ? questions[currentQuestionIndex]?.metadata?.domainName : null
            );
            if (result) {
                const updatedQuestions = [...questions];
                updatedQuestions[currentQuestionIndex].personalizedAnswer = result.personalizedAnswer;
                updatedQuestions[currentQuestionIndex].content.feedback = result.personalizedFeedback;
                updatedQuestions[currentQuestionIndex].personalizedTips = result.personalizedTips;
                setQuestions(updatedQuestions);
            }
        } catch (error) {
            console.error("generate feedback error :- ", error);
            snackbar.error('Oo oh! unable to generate feedback, try again');
        } finally {
            setLoading(false);
        }
    }

    React.useEffect(() => {
        if (['pathway','personalized'].includes(template.type) && !activeQuestion?.personalizedTips?.length) {
            generateFeedbackAndAnswer();
        }
        if (activeQuestion.type === 'technical' && !activeQuestion.content.feedback) {
            generateFeedbackAndAnswer();
        }

        if (activeQuestion.type === 'situational' &&
            !(activeQuestion.content.feedback || activeQuestion.personalizedAnswer)) {
            generateFeedbackAndAnswer();
        }
    }, [currentQuestionIndex, questions, activeQuestion, template]);

    let { personalizedAnswer="", personalizedFeedback="" , personalizedTips=[] } = React.useMemo(() => {
      return {
          personalizedAnswer: questions[currentQuestionIndex]?.personalizedAnswer || "",
          personalizedFeedback: questions[currentQuestionIndex]?.content?.feedback || "",
          personalizedTips: questions[currentQuestionIndex]?.personalizedTips || [],
      };
  }, [ questions, currentQuestionIndex, loading ]);

    return (
        <>
        {
          (!loading && 
            (template.type === templateTypes.PERSONALIZED || template.type === templateTypes.PATHWAY) && 
            personalizedTips?.length > 0) 
          ? 
            <Box className={classes.tipsContainer}>
              <Typography variant="h6-medium" color="success.clr-700">
                  Tips for Improvement
              </Typography>
              <ul className={classes.bulletPoints}>
                {personalizedTips.map((tip, index) => (
                    <li key={index}>
                        <Typography mb='4px' variant='body01-semiBold' color='neutral.clr-800'>
                            {tip}
                        </Typography>
                    </li>
                ))}
              </ul>
            </Box>
          :
            (
              !loading && 
              (template.type === templateTypes.PRACTICE || template.type === templateTypes.ASSIGNMENT)
              && personalizedFeedback?.length
            ) 
            ?
              <Box className={classes.root} id='improvement-improved-response-container'>
                <Box display='flex' flexDirection='column' gap='4px'>
                    <Typography variant='h6-semiBold' color='neutral.clr-700' id='improvement-improved-response'>
                      Feedback on your response
                    </Typography>
                    <Typography variant='body01-medium' color='neutral.clr-900'>
                      {personalizedFeedback}
                    </Typography>
                </Box>
                {
                  (personalizedAnswer) && <Box display='flex' flexDirection='column' gap='4px'>
                    <Box display='flex' gap='4px' alignItems='center'>
                        <Typography variant='h6-semiBold' color='neutral.clr-700'>
                            Your improved Response 
                        </Typography>
                        <InfoIconWithTooltip 
                          title='This is a better version of your original response.' 
                          sx={{fontSize: '18px' }} placement='top'
                        />
                    </Box>
                
                    <Typography variant='body01-medium' color='neutral.clr-900'>
                        {personalizedAnswer}
                    </Typography>
                  </Box>
                }
              </Box> 
            : 
              (
                loading && 
                (!(personalizedFeedback?.length || personalizedAnswer?.length) || !personalizedTips?.length)
              ) 
              ?
                <Box width='100%'>
                  <Skeleton width={'50%'} height={'40px'} />
                  <Skeleton width={'100%'} variant="rounded" height={'120px'} />
                  <br />
                  <Skeleton width={'50%'} height={'40px'} />
                  <Skeleton width={'100%'} variant="rounded" height={'120px'} />
                </Box> 
              : 
                <Box className={classes.error}>
                  <Typography
                      variant='h5-medium' color='neutral.clr-500'
                      style={{ textAlign: 'center', lineHeight: '32px' }} mb={5}
                  >
                    Looks like feedback not generated!  <br />
                    Click button below to regenerate feedback :
                  </Typography>
                  <CustomButton
                    variant='outlined'
                    startIcon={<RetakeIcon fontSize='small' />}
                    onClick={generateFeedbackAndAnswer}
                  >
                      Click to generate
                  </CustomButton>
                </Box>
          }
        </>
    )
}

export default PersonalizedFeedback;
