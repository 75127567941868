import React from 'react'
import useStore from './PerformanceReview/store';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement,
} from 'chart.js';
import { shallow } from 'zustand/shallow';
import makeStyles from "@mui/styles/makeStyles";

import { round } from 'utils';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material'
import { DemoApplications } from './PerformanceReview/constants';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
);

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignSelf: 'center'
  },
  legendRoot: {
    marginRight: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(3)
  },
  tooltip: {
    position: "absolute",
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.neutral['clr-50'],
    padding: theme.spacing(2),
    transform: "all 200ms",
    pointerEvents: "none",
    minWidth: '160px',
    maxWidth: 'content-fit',
    height: 'fit-content',
    border: '1px solid',
    textAlign: 'start',
    borderColor: theme.palette.neutral['clr-500'],
    boxShadow: '0px 0px 8px 0px #1961BF4D'
  },
  tooltipHead: {
    ...theme.typography['body01-semiBold'],
    color: theme.palette.neutral['clr-700'],
    marginBottom: theme.spacing(2)
  },
  tooltipData: {
    ...theme.typography['body02-bold'],
    color: theme.palette.neutral['clr-700']
  },
}));

const Legends = () => {
  const classes = useStyles();
  const theme = useTheme();

  const data = [
    {
      label: 'Speech',
      color: theme.palette.secondary['clr-700']
    },
    {
      label: 'Content',
      color: theme.palette.tertiary.main
    }
  ]
  return (
    <Box className={classes.legendRoot}>
      {
        data.map((el, i) => {
          return (
            <Box key={i} display='flex' gap='4px' alignItems='center'>
              <Box
                style={{
                  width: '20px', height: '20px',
                  borderRadius: '4px',
                  backgroundColor: el.color
                }}
              ></Box>
              <Typography variant='body01-bold'>
                {el.label}
              </Typography>
            </Box>
          )
        })
      }
    </Box>
  )
}

const AttemptGraph = ({ recentAttempts, currentAttempt }) => {
  const classes = useStyles();
  const theme = useTheme();
  const attempts = (recentAttempts.filter((value, index) => index < 4)).reverse();
  const demoApplication = useStore(state => state.demoApplication, shallow);

  const getGraphToolTip = (context) => {
    const { tooltip, chart } = context;
    let tooltipEl = document.getElementById('custom-tooltip');

    if (!tooltip || tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'custom-tooltip';
      tooltipEl.classList.add(classes.tooltip);
      chart.canvas.style.position = "relative";
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    tooltipEl.innerHTML = "";

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltip.yAlign) {
      tooltipEl.classList.add(tooltip.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    // Set tooltip content
    const datasetIndex = tooltip.dataPoints[0].datasetIndex;
    const dataIndex = tooltip.dataPoints[0].dataIndex;
    const dataset = datasets[datasetIndex];
    const label = dataset.label;
    const value = dataset.data[dataIndex];
    const attemptNo = dataset?.attemptNo?.[dataIndex]

    tooltipEl.innerHTML = `
        <div>
          <div class='${classes.tooltipHead}'>Attempt No. ${attemptNo}</div>
          <div class='${classes.tooltipData}' style="color: ${label === 'Speech' ? '#8270DB' : '#2D9BB2'};">
          ${label}: ${value} 
          </div>
        </div>
      `;

    // Position tooltip
    const canvas = context.chart.canvas;
    const position = canvas.getBoundingClientRect();
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = `${position.left + tooltip.caretX - 190}px`;
    tooltipEl.style.top = `${position.top + tooltip.caretY + 20}px`;
  };

  let chartOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: getGraphToolTip,
      },
    },
    scales: {
      yAxis: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 25,
        },
      }
    },
  };

  React.useEffect(() => {
    chartOptions.plugins.tooltip.callbacks = {
      title: ([item]) => recentAttempts[item.dataIndex].name
    }
  })
  const labels = React.useMemo(() => {
    const _labels = attempts.map((x, i) => x.attemptNumber);
    const labelsLength = (demoApplication === DemoApplications.teachAndTrain ? 3 : 4)

    if (_labels.length < labelsLength) {
      for (let x = _labels.length; x < labelsLength; x++) _labels.push('');
    }

    return _labels;
  }, [attempts, demoApplication]);

  const datasets = React.useMemo(() => {
    const _datasets = [];
    _datasets.push({
      label: 'Speech',
      data: attempts.map(qs => round(qs.communication)),
      borderWidth: 1,
      attemptNo: attempts.map((x, i) => x.attemptNumber),
      borderColor: theme.palette.tertiary['clr-700'],
      backgroundColor: attempts.map((value, index) => (
        value._id === currentAttempt ?
          theme.palette.tertiary['clr-600'] : theme.palette.tertiary['clr-100']
      )),
      borderRadius: 5
    });
    _datasets.push({
      label: 'Content',
      data: attempts.map(qs => round(qs.content)),
      borderWidth: 1,
      attemptNo: attempts.map((x, i) => x.attemptNumber),
      borderColor: theme.palette.secondary['clr-900'],
      backgroundColor: attempts.map((value, index) => (
        value._id === currentAttempt ?
          theme.palette.secondary['clr-700'] : theme.palette.secondary['clr-500']
      )),
      borderRadius: 5
    });
    return _datasets;
  }, [attempts, currentAttempt]);

  return (
    <>
      <Box className={classes.root}>
        <Box width={"100%"} textAlign="right">
          <Legends />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant='body02-bold'
            style={{ transform: "rotate(-90deg)" }}
          >
            Score
          </Typography>
          <div style={{ width: "100%", minHeight: '100%' }}>
            <Bar
              options={chartOptions}
              data={{ labels, datasets }}
              height={'170px'}
            />
          </div>
        </Box>
        <div>
          <Typography
            variant='body02-bold'
            style={{ fontWeight: "600" }}
          >
            Attempt No.
          </Typography>
        </div>
      </Box>
    </>
  )
}

export default AttemptGraph;