import React, { useState, useCallback, useEffect } from 'react';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from 'dayjs';

import { SearchTextField } from 'components/CustomFields';
import { Session } from 'utils';
import { PersonalizedTemplateType } from 'components/CreateInterview/constants';
import CustomButton from 'components/CustomButton';
import HorizontalScrollNavigator from 'components/HorizontalScrollNavigator';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';
import TitleTooltip from 'components/TitleTooltip';
import Chip from 'components/Chip';

const useStyles = makeStyles(theme => ({
  headerChildern: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  footerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(4)
  },
  credentialsLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    height: '32px',
    width: '183px',
    borderRadius: theme.spacing(1),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 0px 3px 0px #00000080',
    minWidth: '393px',
    cursor: 'pointer',
    "&:hover": {
      boxShadow: "0px 0px 8px 0px #1961BF4D",
    },
    position:'relative'
  },
  headContainer:{
    display:'flex',
    justifyContent:'space-between'
  },
  headContent: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  footContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    minWidth: 0,
  },
  specificTagBox:{
    padding:theme.spacing(0.5,2),
    backgroundColor:theme.palette.primary['clr-50'],
    borderRadius:theme.spacing(1),
    display:'flex',
    alignItems:'center'
  },
  documentTagBox:{
    padding:theme.spacing(0.5,2),
    backgroundColor:theme.palette.warning['clr-100'],
    borderRadius:theme.spacing(1)
  },
  footHead: {
    width: '310px',
    height:'72px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  nameTag:{
    display: 'block',  
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0, 
    maxWidth: '280px', 
  },
  interviewDetails: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  interviewContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  noComponentRoot: {
    height:'100%', width:'100%', 
    display:'flex', justifyContent:'center', alignItems:'center',
    flexDirection: 'column', textAlign: 'center'
  },
  noAssessmentDesc: {
    margin: theme.spacing(1,0),
  },
  imgPosition:{
    position:'absolute',
    bottom:'0px',
    right:'0px'
  },
  infinityStone:{
    width:'25px',
    height:'25px',
    borderRadius:theme.spacing(5),
    border:`1px solid ${theme.palette.primary['clr-100']}`,
    backgroundColor: theme.palette.neutral['clr-50'],
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    boxShadow: '0px 2px 8px 0px #00000029',
    paddingRight:'1px'
  },
  tooltip: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.neutral['clr-700'], 
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', 
    borderRadius: '4px',
    padding: theme.spacing(1,2),
    ...theme.typography['body02-semiBold'],
    width:'152px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    textAlign:'center',
    border:`1px solid ${theme.palette.neutral['clr-500']}`
  },
  arrow: {
    color: theme.palette.shades['clr-white-900'],
    '&::before': {
      border:`1px solid ${theme.palette.neutral['clr-500']}`,  
      backgroundColor: theme.palette.shades['clr-white-900'],   
      boxSizing: 'border-box', 
    },
  },
  topicCard : {
    display:'flex',
    padding:theme.spacing(0.5,1),
    border:`1px solid ${theme.palette.primary['clr-100']}`,
    gap:theme.spacing(1),
    borderRadius:theme.spacing(1),
    width:'fit-content',
    alignItems:'center',
    '&:hover' : {
      backgroundColor : theme.palette.neutral['clr-50']
    }
  },
  scrollableText: {
    display: 'inline-block', 
    maxHeight: '3.6em', 
    lineHeight: '1.8em',
    whiteSpace: 'nowrap', 
    overflowX: 'auto',
    overflowY: 'hidden', 
    maxWidth: '361px',
  },
  dropdownBox: {
    width: 'auto', 
    maxWidth: '361px', 
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.warning['clr-100'],
    border: `1px solid ${theme.palette.neutral['clr-200']}`,
    position: 'absolute',
    left: '20px',
    marginTop: theme.spacing(1),
    overflowX: 'auto', 
    overflowY: 'hidden', 
    padding: theme.spacing(1, 1),
    boxShadow: `0px 2px 13px 0px #1961BF52`,
  },
  arrowIcon: {
    transition: 'transform 0.3s ease',
  },
  arrowIconOpen: {
    transform: 'rotate(90deg)',
  },
}));

export function NoResultComponent({ clearFilters, description='No assessments found' }) {
  const classes = useStyles();

  return (
    <Box className={classes.noComponentRoot} >
        <img
          height="120"
          src="https://assets.languify.in/images/assessment_illustration.svg"
          alt="Not-found"
        />
        <Typography className={classes.noAssessmentDesc} variant='h5-medium' color='neutral.clr-500'>
            {description}
        </Typography>
        {clearFilters && (
          <Box className={classes.clearFilters}>
              <CustomButton onClick={clearFilters}>
                  Clear filters
              </CustomButton>
          </Box>
        )}
    </Box>
  );
}

const InterviewTemplateCard = ({ template, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [topics , setTopics] = useState([])

  useEffect(()=> {
    if(template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.exam){
      setTopics(template?.metadata?.topics)   
    }
  },[template])

  const handleDropdownToggle = (e) => {
    e.stopPropagation();     
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCardClick = useCallback((e) => {
    if (e.target.closest('.topicCard')) return;
    onClick(template);
  }, [template, onClick]);

  return (
   <TitleTooltip
      placement='bottom' 
      component= {template?.name}
      disableHoverListener={template?.name.length < 40}
      positionStyle={{top : '-75px !important', left:'60px !important' , zIndex:2}}
      onClick={handleCardClick}
    >
      <Box
        className={classes.cardContainer}
        onClick={handleCardClick}
      >
        <Box display='flex' flexDirection='column' gap='4px'>
          <Box className={classes.headContainer}>
            <Box className={classes.headContent}>
                <Box className={classes.specificTagBox}>
                  <Typography variant='body02-bold' color='primary.clr-500'>
                    {template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.exam
                      ?  'UPSC Prep' : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.report
                        ? 'Content focused' : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.resume
                          ? 'Job role specific' : 'Company specific'}
                  </Typography>
                </Box>
                {(template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.exam) && (
                  <Box>
                    <Chip 
                      content={`${template?.metadata?.numberOfQuestions} questions`}
                      bgColor={theme.palette.warning['clr-100']}
                      color={theme.palette.warning['clr-700']}
                      typographyVariant='body02-bold'
                      sx={{
                        borderRadius: '4px',
                        padding : '4px'
                      }}
                    />
                  </Box>
                )}               
                {topics?.length === 0 ? (
                   <Box className={classes.documentTagBox}>
                    <Typography variant='body02-bold' color='warning.clr-700'>
                      {template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.report
                            ? 'Report' : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.resume
                              ? 'Resume' : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.jobDescription
                                ? 'Job description' : 'Job description + Resume'}
                    </Typography>
                  </Box>
                ) : (
                    <Box>
                      <Box onClick={handleDropdownToggle} className={classes.topicCard}>
                        <Typography variant="body02-bold" color="primary.clr-300" sx={{ textDecoration: 'underline' }}>
                          Topics {topics?.length}
                        </Typography>
                        <img
                          src="https://assets.languify.in/images/chevron-right-icon.svg"
                          alt="arrow"
                          className={`${classes.arrowIcon} ${isDropdownOpen ? classes.arrowIconOpen : ''}`}
                        />
                      </Box>
                      {isDropdownOpen && (
                        <Box className={classes.dropdownBox} onClick={(e) => e.stopPropagation()}>
                          <Typography
                            variant="body02-bold"
                            color="neutral.clr-900"
                            className={`${classes.scrollableText}`}
                          >
                            {topics
                              .map((item) => (item._id === 'optional_subject' ? 'Optional Subject' : item.topic))
                              .join(' · ')}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                )}        
            </Box>
            <Box className={classes.infinityStone}>
              <Tooltip 
                title={<Box>
                        <> You have unlimited attempts</>
                          <img 
                            src='https://assets.languify.in/images/sparkles.gif' 
                            alt='sparkels' 
                            width={12}
                            height={12}
                          />
                      </Box>} 
                arrow 
                placement="top"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: false, 
                    },
                  ],
                }}
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <img 
                  src='https://assets.languify.in/images/todo_infinity_icon.svg' 
                  alt='infinity'
                />
              </Tooltip>
            </Box>
          </Box>
        <Divider />
        <Box className={classes.footContent}>
          <Box className={classes.footHead}>
            <Typography variant='h4-semiBold'>{template?.name}</Typography>
          </Box>
          <Box className={classes.interviewDetails}>
            <Box className={classes.interviewContent}>
              <img
                src='https://assets.languify.in/images/refreshIcon.svg'
                alt='practice' 
              />
              <Typography variant='body01-semiBold' color='neutral.clr-700'>
                {template?.totalAttemptsCount}x Practiced
              </Typography>
            </Box>
          </Box>
        </Box>
        <img
          className={classes.imgPosition} 
          src= {
            template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.exam
            ?  'https://assets.languify.in/images/personalizedinterview_dafbgImg.png' 
            : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.report
              ? 'https://assets.languify.in/images/ReportBased.png' 
              : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.resume
                ? 'https://assets.languify.in/images/jobRoleSpecific.png' 
                : 'https://assets.languify.in/images/companySpecific.png'}
          alt='specific'
          width={60}
          height={60}
        />
      </Box>
    </Box>
   </TitleTooltip>
  );
};


const TodoList = ({ interviewTemplate }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  
  const [template, setTemplate] = useState();
  const [filteredTemplates, setFilteredTemplates] = useState(interviewTemplate);
  const [credits, setCredits] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const personalizedInterviewLimit = parseInt(Session?.user?.client?.metadata?.personalizedInterviewLimit?.user 
                                                || process.env.REACT_APP_PERSONALIZED_INTERVIEW_LIMIT)

    if(personalizedInterviewLimit){
      setCredits(personalizedInterviewLimit - interviewTemplate?.length);
    }
  
  }, [interviewTemplate, Session?.user]);

  useEffect(() => {
    const filtered = interviewTemplate?.filter((t) =>
        searchQuery === '' || t.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        if (a.totalAttemptsCount !== b.totalAttemptsCount) {
          return (a.totalAttemptsCount || 0) - (b.totalAttemptsCount || 0);
        }

        return dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1;
      });
  
    setFilteredTemplates(filtered);
  }, [searchQuery, interviewTemplate]);

  return (
    <WhiteBox sx={{ position: 'relative', marginTop: theme.spacing(4) }}>
      <WhiteBoxHeader
        id='to-do-list'
        heading="My personalized interviews"
        sx={{ marginBottom: theme.spacing(4) }}
        subheading='Reattempt your self-created interviews anytime'
      >
        <Box className={classes.headerChildern}>
          <SearchTextField
            placeholder="Find your interview"
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={!interviewTemplate?.length}
          />
          <CustomButton
            variant='contained'
            size='medium'
            onClick={() => navigate('create')}
            disabled={credits <= 0 }
          >
            Create new interview
          </CustomButton>
        </Box>
      </WhiteBoxHeader>
      <Divider />
      <Box height={280} my={5}>
        {filteredTemplates?.length ? 
         (
            <HorizontalScrollNavigator
              childrensLength={filteredTemplates?.length}
              scrollWidth={800}
            >
              {filteredTemplates.map((template, index) => (
                <InterviewTemplateCard
                  key={index}
                  template={template}
                  onClick={(t) => setTemplate(t)}
                />
              ))}

              <StartAssessmentDialog
                onClose={() => setTemplate(undefined)}
                open={!!template}
                template={template}
                userMock={true}
              />
            </HorizontalScrollNavigator>
          )
          : 
            <NoResultComponent 
              description={<>No matches found.<br /> Did you spell everything correctly?</>} 
            />
        }
      </Box>
      <Divider />
      <Box className={classes.footerBox}>
        <Box className={classes.footer}>
          <Typography variant='body01-semiBold' color='danger.clr-700'>*</Typography>
          <Typography variant='body01-semiBold' color='neutral.clr-800'>
            One credit will be deducted for each personalized interview you create.
          </Typography>
        </Box>
        <Box 
          className={classes.credentialsLeft} 
          sx={{backgroundColor : credits > 0 ? theme.palette.neutral['clr-50'] : theme.palette.danger['clr-100']}}
        >
          <Typography variant='body01-bold' color={credits > 0 ? 'primary.clr-500' : 'danger.clr-700'}>
            Credits left: {credits <= 0 ? 0 : credits}
          </Typography>
        </Box>
      </Box>
    </WhiteBox>
  );
}

export default TodoList;
