import useStore from './store';
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import FeedbackAudio from "./FeedbackAudio";
import WizardScores from './WizardScores';
import PreparationStatus from './PreparationStatus';

import { useMemo } from 'react';
import { WhiteBox } from "components/AppShell";
import { SimpleSelect } from "components/CustomSelectFields";
import { shallow } from "zustand/shallow";
import { getCommentAndColor } from './helpers';
import { Session } from 'utils';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  scoresContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '100%',
    gap: 20,
  },
}));

const getFeedbackIntro = (username) => {
  const index = Math.floor(Math.random() * 10);

  const oneliners = [
    `Hi ${username}, it was great speaking with you. Let's go over your performance.`,
    `Hello ${username}! It was a pleasure to hear your responses. Let's discuss your strengths and areas for growth.`,
    `Good talking with you, ${username}. Let's review some insights on your interview.`,
    `Hi ${username}, thanks for a thoughtful interview. Let's discuss about your performance.`,
    `Nice conversation, ${username}. Let's dive into how you did.`,
    `Thanks for joining us, ${username}. I’d love to share insights from the interview.`,
    `Hello ${username}! I appreciated our conversation—let’s discuss your performance.`,
    `Hello ${username}! Let’s walk through your performance today and identify some key takeaways.`,
    `Hi ${username}, let’s discuss your interview strengths and growth areas.`,
    `Hi ${username}, let’s discuss your interview’s strong points and focus areas.`,
  ]

  return oneliners[index] ?? oneliners[0];
}

export default function InterviewWizardPerformance(
  { showComparison, contentChange, speechChange, loading, attemptsLoading }
) {
  const classes = useStyles();

  const allAttempts = useStore(state => state.allAttempts, shallow);
  const setAttemptId = useStore(state => state.setAttemptId, shallow);
  const attemptId = useStore(state => state.attemptId, shallow);
  const isGuestApp = useStore(state => state.isGuestApp, shallow);

  const audioFeedback = useMemo(() => {
    let feedback = '';
    const intro = getFeedbackIntro(Session.user.name);
    const { analysis } = allAttempts.find(attempt => attempt._id === attemptId) || {};

    const ratings = {
      ...(analysis?.communication?.ratings ?? {}),
      ...(analysis?.content?.ratings ?? {})
    };

    feedback = Object.keys(ratings).reduce((prev, curr) => {
      const { subFeatureFeedback } = getCommentAndColor(ratings[curr]);
      return subFeatureFeedback[curr] ? `${prev} ${subFeatureFeedback[curr]}` : '';
    }, '');

    return `${intro} ${feedback}`;
  }, [attemptId, allAttempts]);

  return (
    <WhiteBox id='interview-wizard-performance'>

      <Box className={classes.header} >
        <FeedbackAudio audioText={audioFeedback} loading={loading} />

        {
          !isGuestApp &&
            <SimpleSelect
              label='Select attempt'
              options={allAttempts}
              onSelect={(data) => setAttemptId(data._id)}
              value={{ _id: attemptId }}
              defaultValue={{ _id: attemptId }}
              loading={attemptsLoading}
              disabled={isGuestApp}
            />
        }
      </Box>

      <Box className={classes.scoresContainer} mt={10}>
        <PreparationStatus loading={loading} />
        <WizardScores
          showComparison={showComparison}
          contentChange={contentChange}
          speechChange={speechChange}
          loading={loading}
        />
      </Box>

    </WhiteBox>
  );
}