import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";

import  { IconButton } from 'components/CustomButton';
import { SimpleSelect } from 'components/CustomSelectFields';
import { DafInterviewSteps, DocumentOptions, ReportOptions } from './constants';
import { useState } from 'react';
import CaseStudy from './CaseStudy';
import DafUpload from './DafUpload';
import DafTopicSelection from './DafTopicSelection';
import DafForm from './DafForm';
import useStore from './store';
import DafPreferenceSelection from './DafPreferenceSelection';

const useStyles = makeStyles((theme) => ({
  whiteBoxContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(7),
    alignItems:'center',
    padding:theme.spacing(7),
    backgroundColor : theme.palette.shades['clr-white-900'],
    borderRadius: '4px',
    position:'relative',
    width:'800px',
    height:'496px'
  },
  backArrow:{
    backgroundColor:theme.palette.shades['clr-white-900'] , 
    color:theme.palette.neutral.main,
    '&:hover': {
    backgroundColor: theme.palette.shades['clr-white-900'], 
  },
  },
  reportBasedContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(6),
    justifyContent:'center',
    alignItems:'center'
  },
  header:{
    display:'flex',
    gap:theme.spacing(1),
    height:'52px',
    width:'734px',
    gap:theme.spacing(13.5),
    alignItems:'center',
    borderBottom : `1px solid ${theme.palette.neutral['clr-200']}`
  },
  bottomContentContainer:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3.5)
  },
  scrollContainer:{
    display:'flex',
    flexDirection:'column',
    gap: theme.spacing(4),
    overflowY:'auto',
    width:'100%',
    alignItems:'center',
    maxHeight:'364px',
    height:'364px'
  }
}))


const ReportBasedInterview = ({
  setOpenSelectDocumentOptions,
  setOpenReportBasedInterview,
}) => {
  const classes = useStyles();
  
  const selectedDocument =  useStore(store => store.selectedDocument);

  const [ reportOption , setReportOption ] = useState('')
  const [ interviewTitle , setInterviewTitle ] = useState('Report based Interview')
  const [dafActiveStep , setDafActiveStep] = useState()

  useEffect(() => {
    if (selectedDocument === DocumentOptions.CASE_STUDY._id){
      setDafActiveStep(DafInterviewSteps.SELECT_REPORT._id)
    }
  }, [])

  useEffect(()=> {
    if(reportOption._id === ReportOptions[0]._id ){
      setInterviewTitle('UPSC Mock Interview')
    } else {
      setInterviewTitle('Report based Interview')
    }
  },[reportOption])

  const handleBackButton = () => {
    const steps = Object.values(DafInterviewSteps);

    const currentStepIndex = steps.findIndex(step => step._id === dafActiveStep);

    if (currentStepIndex > 0) {
      setDafActiveStep(steps[currentStepIndex - 1]._id);
    } else {
      if(reportOption){
        setReportOption('')
      }else{
        setOpenReportBasedInterview(false)
        setOpenSelectDocumentOptions(true)
      }
    } 
  };

  return (
    <Box className={classes.whiteBoxContainer}>
        <Box className={classes.reportBasedContainer}>
          <Box className={classes.header}>
            <IconButton
              variant='contained'
              className={classes.backArrow}
              onClick={handleBackButton}
            >
                <img 
                  src='https://assets.languify.in/images/arrow-left-icon.png'  
                  alt='back'
                  width={36}
                  height={36}
                />
            </IconButton>
            <Typography variant='h4-medium' color='neutral.clr-600'>
               {interviewTitle}
            </Typography>
          </Box>

          {dafActiveStep === DafInterviewSteps.FORM_PREVIEW._id  && <DafForm setDafActiveStep={setDafActiveStep}/>}

          {dafActiveStep === DafInterviewSteps.SELECT_PREFERENCE._id && <DafPreferenceSelection setDafActiveStep={setDafActiveStep}/>}

          {dafActiveStep === DafInterviewSteps.SELECT_DURATION_TOPIC._id && <DafTopicSelection />}

          {dafActiveStep === DafInterviewSteps.SELECT_REPORT._id && (
            <Box className={classes.scrollContainer}>
                  <SimpleSelect
                      value={reportOption}
                      options={ReportOptions}
                      label='Document type'
                      sx={{width: '484px', height:'48px'}}
                      menuStyle= {{width: '484px'}}
                      width='484px'
                      onSelect={(data)=> setReportOption(data)}
                      disabled={reportOption}
                    />
                  {reportOption && reportOption._id !== ReportOptions[0]._id && (
                    <CaseStudy />
                  )}
                  {reportOption && reportOption._id === ReportOptions[0]._id && (
                    <DafUpload  setDafActiveStep={setDafActiveStep}/>
                  )}
                </Box>
          )}       
        </Box>
       
    </Box>
  )
}

export default ReportBasedInterview