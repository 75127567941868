import Client from "./_client";
import Session from "../utils/Session";

export const getSignedUploadUrl = async (file, saveLocation, toPublic, uniqueName) => {
  const result = await new Client({
    path: "/uploads/generateSignedUploadUrl",
    payload: {
      contentType: file.type,
      fileName: file.name,
      location: saveLocation,
      toPublic,
      uniqueName
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to generate the upload url."
    );
  } else {
    return result.data;
  }
};

export const uploadFile = async (file, _blob, saveLocation, toPublic, uniqueName=true) => {
  const { url } = await getSignedUploadUrl(file, saveLocation, toPublic, uniqueName);

  let binary = atob(_blob.split(",")[1]);
  let array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  let blobData = new Blob([new Uint8Array(array)], { type: file.type });

  await fetch(url, {
    method: "PUT",
    body: blobData,
  });

  return url.split("?")[0];
};

export const uploadToS3Api=  async (
  { file, location })=>{
    const formData = new FormData();
    formData.append("file", file);
    formData.append("location",location);
    const baseUrl = process.env.REACT_APP_API_URL;
    const request= fetch(baseUrl+"/uploads/proctor-image",
    {
      method:"POST",
      headers: {
        "languify-application": "interview",
        userId: Session.userId,
        "languify-access-token": Session.accessToken,
        "languify-refresh-token": localStorage.getItem("languifyRefreshToken"),
        "languify-session-id": localStorage.getItem("languifySessionId"),
      },
      body:formData,
    });

    return request.then(result => {
      if (!(result.ok || result.code === 200)){
        throw new Error(
          result.message || "Uh Oh! Unable to generate the upload url."
        );
      }
      return result.json();
    },error=>{
      throw error;
    })
    .then(res=>res.data)
    .catch(error => {
      throw error;
    })
}

export const extractPdf = async (file) => {

    const formData = new FormData();
    formData.append("file", file);

    const baseUrl = process.env.REACT_APP_API_URL;
    const request= fetch(baseUrl+"/uploads/extract-pdf",
    {
      method:"POST",
      headers: {
        "languify-application": "interview",
        userId: Session.userId,
        "languify-access-token": Session.accessToken,
        "languify-refresh-token": localStorage.getItem("languifyRefreshToken"),
        "languify-session-id": localStorage.getItem("languifySessionId"),
      },
      body:formData,
    });

    return request.then(result => {
      if (!(result.ok || result.code === 200)){
        throw new Error(
          result.message || "Uh Oh! Unable to generate the upload url."
        );
      }
      return result.json();
    },error=>{
      throw error;
    })
    .then(res=>res.data)
    .catch(error => {
      throw error;
    })
  }

