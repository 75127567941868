import React from "react";
import useStore from "./store";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import CustomButton from "components/CustomButton";
import InfoIconWithTooltip from "components/InfoIconWithTooltip";

import { getCommentAndColor } from "./helpers";
import { shallow } from "zustand/shallow";
import { round } from "utils";
import { Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    border: '2px solid',
    width: '50%',
    padding: theme.spacing(4),
    borderColor: theme.palette.neutral['clr-200'],
    borderRadius: '4px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    gap: 4,
  },
  outerBar: {
    width: '100%',
    height: '25px',
    border: '2px solid',
    borderColor: theme.palette.neutral['clr-200'],
    display: 'flex',
    borderRadius: '4px',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.neutral['clr-50'],
  },
  divider: {
    height: '50px',
    border: '2px solid',
    borderColor: theme.palette.neutral['clr-300'],
    position: 'absolute',
    zIndex: 10,
  },
  '@keyframes smoothAnimation': {
    '0%': { width: '0%' },
    '100%': { width: (props) => `${props.scale}%` }
  },
  innerBar: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    animationName: '$smoothAnimation',
    animationDuration: '1s',
    animationTimingFunction: 'ease-in',
    animationFillMode: 'forwards',
    borderRadius: '4px 0px 0px 4px'
  }, scaleContainer: {
    width: '20px',
    height: 'fit-content',
    position: 'absolute',
    right: '-10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 4,
  },
  dotLine: {
    width: 0,
    height: '50px',
    borderLeft: '4px dotted',
    borderLeftColor: theme.palette.neutral['clr-500'],
    zIndex: 12,
  }, triangle: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid',
    borderBottomColor: theme.palette.neutral['clr-500']
  }, feedbackContainer: {
    width: '100%',
    height: 'fit-content',
    padding: 10,
    border: '1px dashed',
    borderColor: theme.palette.primary['clr-300'],
    borderRadius: '4px',
  }, tipContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    padding: 10,
    border: '2px dashed',
    borderColor: theme.palette.success['clr-700'],
    borderRadius: '4px',
  }, showMe: {
    alignSelf: 'end',
    textTransform: 'none',
    ...theme.typography['body01-link'],
    color: theme.palette.primary['clr-300']
  }, goodFeedback: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    padding: 10,
    border: '1px solid',
    borderColor: theme.palette.primary['clr-300'],
    borderRadius: '4px',
  }, img: {
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
  }, statusSkeleton: {
    height: '30px',
    margin: '10px 0px',
    borderRadius: '4px',
  }
}));

export function StatusBar({ scale = 0 }) {
  const classes = useStyles();
  const barElementRef = React.useRef(null);
  const { color, index } = getCommentAndColor(scale);

  React.useEffect(() => {
    const barElement = barElementRef.current;

    if (barElement) {
      barElement.classList.remove(classes.innerBar);

      void barElement.offsetWidth;

      barElement.classList.add(classes.innerBar);
      barElement.style.width = `${scale}%`;
      barElement.style.backgroundColor = color;
    }
  }, [scale]);

  return (
    <Box width='100%' my={10}>
      <Box className={classes.outerBar}>

        <Box className={classes.divider} left='70%'></Box>

        <Box ref={barElementRef}>
          <Box className={classes.scaleContainer}>
            <Typography variant="overline-bold" color='neutral.clr-900'>
              {scale}
            </Typography>
            <Box className={classes.dotLine}></Box>
            <Box className={classes.triangle}></Box>
          </Box>
        </Box>
      </Box>

    </Box>
  );
}

export default function PreparationStatus({ loading = false }) {
  const classes = useStyles();

  const score = round(useStore(state => state.score, shallow));

  return (
    <Box className={classes.container}>

      <Box className={classes.titleContainer} mb={5}>
        <Typography variant="h4-medium" color='neutral.clr-900'>
          My Preparation Status
        </Typography>
        <InfoIconWithTooltip
          title={'Your overall score is a total of your 50% speech and 50% content'}
          placement="right"
        />
      </Box>

      <Typography variant="body02-bold" color='secondary.clr-900' >
        Score of 70 or higher indicates interview readiness.
      </Typography>

      {
        (loading) ?
          <Skeleton variant='rectangular' className={classes.statusSkeleton} animation='wave' /> :
          <StatusBar scale={score} />
      }

      {
        loading &&
        <Skeleton variant='rectangular' className={classes.statusSkeleton} height={90} animation='wave' />
      }

      {
        loading &&
        <Skeleton variant='rectangular' className={classes.statusSkeleton} height={120} animation='wave' />
      }

      {
        (score < 50 && !loading) ?
          <><Box className={classes.feedbackContainer} mt={6}>
            <Typography variant="body01-semiBold" color='neutral.clr-800'>
              You need to practice quite hard to crack this job profile.
              You should take multiple attempts to get better & improve yourself.
            </Typography>
          </Box>

            <Box className={classes.tipContainer} mt={6}>
              <Typography
                variant="body01-bold" color='neutral.clr-800'
                style={{ fontStyle: 'italic' }}
              >
                Tip: Check out detailed feedback for each question,
                discover areas to improve, and find resources to help you grow!
              </Typography>

              <CustomButton
                className={classes.showMe} variant='text'
                href='#question-specific-feedback'
              >
                Show me
              </CustomButton>
            </Box></> : null
      }

      {
        (score < 70 && score >= 50 && !loading) ? <>
          <Box className={classes.feedbackContainer} mt={6}>
            <Typography variant="body01-semiBold" color='neutral.clr-800'>
              Just a bit more effort will get you there!
              A few more attempts will elevate your performance & will lead to great results.
            </Typography>
          </Box>

          <Box className={classes.tipContainer} mt={6}>
            <Typography
              variant="body01-bold" color='neutral.clr-800'
              style={{ fontStyle: 'italic' }}
            >
              Tip: Check out detailed feedback for each question,
              discover areas to improve, and find resources to help you grow!
            </Typography>

            <CustomButton
              className={classes.showMe} variant='text'
              href='#question-specific-feedback'
            >
              Show me
            </CustomButton>
          </Box> </> : null
      }

      {
        (score >= 70 && !loading) ?
          <>
            <Box className={classes.goodFeedback} mt={6}>
              <Typography variant="body01-semiBold" color='primary.clr-500'>
                You’ve put in the hard work. Now it’s time to show it off and
                impress the interviewers with confidence and poise!
              </Typography>
            </Box>

            <Box className={classes.img} mt={2}>
              <img
                src="https://assets.languify.in/images/interviewers.png"
                alt="interviewing"
                width={200}
              />
            </Box></> : null
      }

    </Box>
  );
}