import React, { useState, useEffect } from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  progressBar: {
    height: '12px',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary['clr-50']}`,
    backgroundColor: theme.palette.neutral['clr-50'], 
  },
  bar: {
    backgroundColor: theme.palette.primary['clr-100']
  },
  fileNameReceived: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  barCompleted: {
    backgroundColor: theme.palette.success['clr-700'], 
  },
  textWrap: {
    width: '420px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },
}));

const LinearProgressBar = ({ 
  fileNameReceived, 
  isFileUploaded, 
  error, 
  setProgressBar, 
  setRefresh,
  setFileNameReceived , 
  fileName ,
  isCaseStudy=false,
  setProgressionComplete=false
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [intervalSpeed, setIntervalSpeed] = useState(50);

  const resetStates = () => {
    setProgress(0);
    setIntervalSpeed(100);
  };

  useEffect(() => {
    if (error) {
      resetStates();
      setProgressBar(false)
      return; 
    }

    if (fileNameReceived) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          return Math.min(oldProgress + (isFileUploaded ? 10 : 2), 100);
        });
      }, intervalSpeed);

      return () => {
        clearInterval(timer);
      };
    }
  }, [fileNameReceived,isFileUploaded,error,intervalSpeed]);

  useEffect(() => {
    if (progress === 100 && !isCaseStudy) {
      const completionTimer = setTimeout(() => {
        setProgressBar(false);
        setRefresh(true);
        resetStates();
        setFileNameReceived(false);
      }, 500); 

      return () => clearTimeout(completionTimer); 
    } else if (progress === 100 && isCaseStudy){
      setProgressionComplete(true)
    }
  }, [progress, isCaseStudy]);

  useEffect(() => {
    if (isFileUploaded) {
      setIntervalSpeed(25); 
    }
  }, [isFileUploaded]);

  return (
    <Box className={classes.root}>
        <Box className={classes.fileNameReceived}>
        <img
          src="https://assets.languify.in/images/resumeFile.svg"
          alt="file"
        />
        <Typography className={classes.textWrap} variant="body01-medium" color="neutral.clr-600">
          {fileName}
        </Typography>
      </Box>
     
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={{
          root: classes.progressBar,
          bar: progress === 100 ? classes.barCompleted : classes.bar, 
        }}
      />
     <Typography variant='body01-bold' color='neutral.clr-700'>
        {error
          ? 'Upload failed!'
          : progress === 100
          ? <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <img 
                src='https://assets.languify.in/images/checkmark.svg' 
                alt='check' 
              />
                Successfully uploaded!
            </Box>
          : `${progress}% uploaded`}
      </Typography>
    </Box>
  );
};

export default LinearProgressBar;
