import React from 'react';
import { makeStyles, styled } from '@mui/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/material';

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    borderColor: theme.palette.neutral['clr-700'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.shades['clr-black-900'],
    boxShadow: '0px 0px 4px 0px #00000029',
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-700'],
    padding: 10,
    maxWidth: 300,
    ...theme.typography['body02-medium'],
  },
}));

export const WarningBootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    borderColor: theme.palette.warning['clr-700'],
    color: theme.palette.warning['clr-700'],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning['clr-100'],
    color: theme.palette.warning['clr-700'],
    boxShadow: '0px 0px 4px 0px #00000029',
    border: '1px solid',
    borderColor: theme.palette.warning['clr-700'],
    padding: 10,
    maxWidth: 300,
    ...theme.typography['body02-medium'],
  },
}))

const useStyles = makeStyles((theme) => ({
  info: {
    color: theme.palette.neutral.main,
    cursor: 'pointer',
  }
}));

function InfoIconWithTooltip({ title, placement = 'bottom', ...props }) {
  const classes = useStyles();

  return (
    <BootstrapTooltip title={title} placement={placement} {...props}>
      <InfoOutlinedIcon className={classes.info} />
    </BootstrapTooltip>
  );
};

export function ClickableTooltip({ title, placement = 'bottom', children, ...props }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <WarningBootstrapTooltip title={title} placement={placement} 
      onClose={handleTooltipClose}
      open={open}
      {...props}
    >
      <Box onClick={handleTooltipOpen}>
        {children}
      </Box>
    </WarningBootstrapTooltip>
  );
};



export default InfoIconWithTooltip;