import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';

import { WhiteBox } from './AppShell';
import CustomButton from './CustomButton';
import { useContext } from 'react';
import { TourProviderContext } from 'contexts';

const useStyles = makeStyles(theme=>({
  root: {
    display: 'flex', gap: theme.spacing(2)
  },
  content : { 
    display: 'flex', gap: theme.spacing(4), 
    flexDirection: 'column', justifyContent: 'center'
  }
}));

const ReplayTutorial = ({ start, steps}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { setSteps } = useContext(TourProviderContext);

  const handleStartTour = () => {
    setSteps(steps);
    start();
  }

  return (
    <WhiteBox style={{ padding: theme.spacing(2,6)}} id='replay-tutorial-container'>
      <Box className={classes.root} >
        <img 
          src='https://assets.languify.in/images/replayTutorial.png' 
          alt='Replay Tutorial'
          width={157}
          height={112}
        />
        <Box className={classes.content} id={'replay-tutorial'}>
          <Typography variant='h5-medium' color='neutral.clr-700'>
            You can replay the tutorial for this page 
          </Typography>
          <CustomButton
            variant='outlined'
            size='small'
            startIcon={<img width={24} height={24} src='https://assets.languify.in/images/play-icon.svg' alt='replay' />}
            sx={{ width: 'fit-content' , width:'200px' , minHeight : '40px' }}
            onClick={handleStartTour}
          >
            Replay Tutorial
          </CustomButton>
        </Box>
      </Box>
    </WhiteBox>
  )
}

export default ReplayTutorial;