import * as React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  barcontainer: {
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-200'],
    position: 'relative',
    top: '50%',
    minWidth: '35px',
    maxWidth: '35px',
    minHeight: '100%',
    borderRadius: '4px',
    overflow: 'hidden'
  },
  bar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    animation: '$grow 1.5s ease-out forwards',
    transformOrigin: 'bottom',
    display: 'flex',
    justifyContent: 'center',
  },
  '@keyframes grow': {
    from: {
      transform: 'scaleY(0)',
    },
  },
}));

export const VerticalProgressBar = ({ progress=0, color, total=100 }) => {
  const classes = useStyles();

  return (
    <Box className={classes.barcontainer}>
      <Box
        className={classes.bar}
        style={{
          height: `${progress}%`,
          backgroundColor: `${color}`,
        }}
      >
      </Box>
    </Box>
  );
};

export default VerticalProgressBar;
