import React from 'react';
import Box from '@mui/material/Box';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';
import OverallPerformance from './OverallPerformance';
import RateExperienceDialog from 'dialogs/RateExperienceDialog';
import useNavigateWithClient from 'hooks/useNavigateWithClient';
import AnalysisFailedDialog from 'dialogs/AnalysisFailedDialog';
import TourStartDialog from 'dialogs/TourStartDialog';
import SubscribeDialog from 'dialogs/SubscribeDialog';
import CustomButton from 'components/CustomButton';
import GraphAnalysis from './GraphAnalysis';
import Analysis from './Analysis';
import useStore from './store';
import dayjs from 'dayjs';
import ReplayTutorial from 'components/ReplayTutorial';
import InterviewWizardPerformance from './InterviewWizardPerformance';

import { shallow } from 'zustand/shallow';
import { useParams, useSearchParams } from "react-router-dom";
import { useServices } from 'contexts/SingleSessionProvider';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { getInterviewAttempts, getTemplateById } from 'services';
import {
    getInteractiveDemoFeedbackByToken,
    getInterviewFeedbackByAttemptId
}
    from 'services/interviewFeedback';
import { Session, getAverageScore, getPercentageChange, isMobile } from 'utils';
import { TourProviderContext } from 'contexts';
import { FeedbackTourSteps, templateTypes } from './constants';
import { Skeleton, Typography } from '@mui/material';
import { WhiteBox } from 'components/AppShell';
import { makeStyles } from '@mui/styles';
import { BootstrapTooltip } from 'components/InfoIconWithTooltip';

const useStyles = makeStyles((theme) => ({
    rateusContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.shades['clr-white-900'],
        border: '1px solid',
        borderLeft: 'none',
        borderColor: theme.palette.primary['clr-500'],
        padding: theme.spacing(2),
        position: 'absolute',
        right: 10,
        cursor: 'pointer',
        rotate: '180deg'
    },
    rateusTitle: {
        letterSpacing: 2,
        writingMode: 'vertical-lr',
        textOrientation: 'mixed',
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        [theme.breakpoints.down(725)]: {
            flexDirection: "column",
            gap: '15px'
        }
    },
    demoButton: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down(725)]: {
            justifyContent: "center",
        }
    },
    overallPerformanceSkeleton: {
      borderRadius: '4px', 
      width: '50%', height: '300px'
    }
}));

function PerformanceReview(props) {
    const { aid, tid } = useParams();
    const service = useServices();
    const snackbar = useSnackbar();
    const classes = useStyles();
    const navigate = useNavigateWithClient();
    const [searchParams] = useSearchParams();

    const init = useStore(store => store.init);
    const reset = useStore(store => store.reset);
    const template = useStore(store => store.template);
    const templateAttempts = useStore(store => store.templateAttempts);
    const setTemplate = useStore(store => store.setTemplate);
    const setAttemptId = useStore(state => state.setAttemptId);
    const content = useStore(state => state.content, shallow);
    const speech = useStore(state => state.speech, shallow);
    const removedFromBatch = useStore(state => state.removedFromBatch, shallow);
    const attemptId = useStore(state => state.attemptId, shallow);
    const allAttempts = useStore(state => state.allAttempts, shallow);
    const setAllAttempts = useStore(state => state.setAllAttempts, shallow);
    const [isGuestApp, setIsGuestApp] = useStore(
        state => [state.isGuestApp, state.setIsGuestApp], shallow
    );
    const [isDemoApp, setIsDemoApp] = useStore(
        state => [state.isDemoApp, state.setIsDemoApp], shallow
    );
    const { setSteps, playTour } = React.useContext(TourProviderContext);
    const [parameter, setParameter] = React.useState('overall');
    const [openFeedback, setOpenFeedback] = React.useState(false);
    const [assessment, setAssessment] = React.useState();
    const [openWelcomeDialog, setOpenWelcomeDialog] = React.useState(false);
    const [summaryLoading, setSummaryLoading] = React.useState(false);
    const [attemptsLoading, setAttemptsLoading] = React.useState(false);
    const [analysisFailed, setAnalysisFailed] = React.useState(false);
    const [openSubscribeDialog, setOpenSubscribeDialog] = React.useState(false);
    const [templateLoading, setTemplateLoading] = React.useState(false);

    const mobile = React.useMemo(() => isMobile(), []);

    React.useEffect(() => {
      reset();
    }, []);
    
    React.useEffect(() => {
        const b2c = searchParams.get('b2c');
        if (b2c && isGuestApp) {
            const timeout = setTimeout(() => {
                setOpenSubscribeDialog(true);
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [searchParams, isGuestApp]);

    React.useEffect(() => {
        if (aid) setAttemptId(aid);
    }, [aid]);

    // Services 
    React.useEffect(() => {
        if (!attemptId || !(aid || tid)) return;

        setTemplateLoading(true);
        setSummaryLoading(true);
        service.callService(getInterviewFeedbackByAttemptId, aid ? aid : attemptId)
            .then((feedback) => {
                if (!tid) setTemplate(feedback.template);
                init(feedback);
            })
            .catch((error) => {
                console.error(error);
                setAnalysisFailed(true);
            }).finally(() => {
                setTemplateLoading(false);
                setSummaryLoading(false);
            });
    }, [attemptId]);

    React.useEffect(() => {
      if (!tid) return;

      setTemplateLoading(true);
      service.callService(getTemplateById, tid)
          .then((template) => setTemplate(template))
          .catch((error) => {
              console.error(error);
          }).finally(()=>{
            setTemplateLoading(false);
          });
    }, [tid]);

    React.useEffect(() => {
      if (template._id && (aid || tid)) {
        setAttemptsLoading(true);

        const attemptFilters = {
          user: Session.userId,
          order: "desc",
          orderBy: "createdAt",
          include: "interviewTemplate",
          interviewTemplate: template._id
        };

        service.callService(getInterviewAttempts, attemptFilters)
          .then(({ interviewAttempts }) => {
            const _attempts = [];
            const filteredAttempts = interviewAttempts.filter(
                ({ finishedAt }) => (finishedAt !== null)
            );

            filteredAttempts.forEach((attempt, index) => {
              const content = attempt.analysis.content.ratings.OVERALL;
              const communication = attempt.analysis.communication.ratings.OVERALL;
              const _attemptNumber = filteredAttempts.length - index;

              _attempts.push({
                  _id: attempt._id,
                  content,
                  communication,
                  type: attempt?.interviewTemplate?.type,
                  overall: getAverageScore(communication, content),
                  templateName: attempt?.interviewTemplate?.name,
                  name: `Attempt - ${_attemptNumber < 10 ? '0' + _attemptNumber : _attemptNumber}`,
                  createdAt: attempt.createdAt,
                  attemptNumber: _attemptNumber,
                  index: index,
                  analysis: attempt.analysis,
                  finishedAt: attempt.finishedAt,
                  startedAt: attempt.startedAt
              });
            });

            setAllAttempts(_attempts);
            if (tid && _attempts[0]._id !== attemptId)
                setAttemptId(_attempts[0]._id);
          })
          .catch((error) => {
              console.error(error);
          })
          .finally(() => {
              setAttemptsLoading(false);
          })
      }
    }, [template, tid, aid]);

    React.useEffect(() => {
      const at = searchParams.get('at');
      if (!at) return;

      Session.accessToken = at;
      setIsGuestApp(true);

      setTemplateLoading(true);
      setSummaryLoading(true);
      service.callService(getInteractiveDemoFeedbackByToken, at)
        .then(feedback => {
          setIsDemoApp(feedback?.demoApplication?.length ? true : false);
          setTemplate(feedback.template)
          setAttemptId(feedback?._id);
          init(feedback);

          const _attempts = [];
          const filteredAttempts = feedback.interviewAttempts.filter(
              ({ finishedAt }) => (finishedAt !== null)
          );

          filteredAttempts.forEach((attempt, index) => {
            const content = attempt.analysis.content.ratings.OVERALL;
            const communication = attempt.analysis.communication.ratings.OVERALL;
            const _attemptNumber = filteredAttempts.length - index;

            _attempts.push({
                _id: attempt._id,
                content,
                communication,
                type: attempt?.interviewTemplate?.type,
                overall: getAverageScore(communication, content),
                templateName: attempt?.interviewTemplate?.name,
                name: `Attempt - ${_attemptNumber < 10 ? '0' + _attemptNumber : _attemptNumber}`,
                createdAt: attempt.createdAt,
                attemptNumber: _attemptNumber,
                index: index,
                analysis: attempt.analysis,
                finishedAt: attempt.finishedAt,
                startedAt: attempt.startedAt
            });;

            setAllAttempts(_attempts);
          })
        }).catch(error => {
          console.error(error);
          snackbar.error("Uh Oh! Unable to get your Performance analysis");
        }).finally(() => {
          setSummaryLoading(false);
          setTemplateLoading(false);
        });
    }, []);

    //Other methods
    React.useEffect(() => {
      if (!localStorage.getItem('v2.0-feedback-tour') && !mobile) {
        localStorage.setItem('v2.0-feedback-tour', true.toString());
        if (!isGuestApp) {
          setSteps(FeedbackTourSteps);
          setTimeout(() => playTour(), 3000);
        }
      }
    }, []);

    const redirectToLandingPage = () => {
      window.open(process.env.REACT_APP_LANGUIFY_PORTAL_URL + '#experience-live', '_self');
    };

    const handlePracticeAgain = () => {
      if (templateAttempts >= template.maxAllowedAttempts && template.maxAllowedAttempts > 0) {
          snackbar.error('You have reached max limit of attempts for this assessment!');
          return;
      }
      setAssessment(template);
    };

    const handleOverallPerformance = () => {
      if(template?.type === 'personalized' && template?.personalizedTemplateDraftId === null){
        navigate("/personalized-interview?performance");
      }else{
        navigate("/performance");
      }
    };

    const showPracticeButton = React.useMemo(() => {

        return (['practice', 'pathway', 'personalized'].includes(template?.type) &&
            (template.type !== 'pathway' ?
                (template.expiresOn && !dayjs(template.expiresOn).isBefore(new Date()))
                : true
            ) &&
            (template.maxAllowedAttempts > allAttempts.length || template.maxAllowedAttempts < 0)
        )
    }, [template, allAttempts]);

    const currentAttemptNo = React.useMemo(() => {
        const attemptIndex = allAttempts.findIndex(attempt => attempt._id === attemptId);
        return allAttempts.length - attemptIndex;
    }, [attemptId, allAttempts]);

    const { overallChange, contentChange, speechChange, showComparison } = React.useMemo(() => {
        const { attemptNumber } = allAttempts.find(attempt => attempt._id === attemptId) || {};
        let previousSpeechTotal = 0;
        let previousContentTotal = 0;
        let count = 0;
        allAttempts.forEach((attempt) => {
            if (attempt.attemptNumber < attemptNumber) {
                previousSpeechTotal += attempt.communication;
                previousContentTotal += attempt.content;
                count += 1;
            }
        });
        previousSpeechTotal /= count;
        previousContentTotal /= count;

        //overall averages
        let previousOverall = getAverageScore(previousSpeechTotal, previousContentTotal);
        let currentOverall = getAverageScore(speech, content);

        return {
            overallChange: getPercentageChange(currentOverall, previousOverall),
            contentChange: getPercentageChange(content, previousContentTotal),
            speechChange: getPercentageChange(speech, previousSpeechTotal),
            showComparison: (!isGuestApp && attemptNumber !== 1)
        }
    }, [allAttempts, content, speech, isGuestApp, attemptId]);

    return (
        <>
          {(templateLoading || !template?.type) ? 
            <>
              <WhiteBox>
                <Box display='flex' gap='10px'>
                  <Skeleton variant='rectangular' className={classes.overallPerformanceSkeleton}/>
                  <Skeleton variant='rectangular' className={classes.overallPerformanceSkeleton}/>
                </Box>
              </WhiteBox>
            </>
            :
            template.type === templateTypes.PERSONALIZED ?
              <InterviewWizardPerformance
                  showComparison={showComparison}
                  contentChange={contentChange}
                  speechChange={speechChange}
                  loading={summaryLoading}
                  attemptsLoading={attemptsLoading}
              />
              :
              <OverallPerformance
                  template={template}
                  summaryLoading={summaryLoading}
                  attemptsLoading={attemptsLoading}
                  overallChange={overallChange}
                  showComparison={showComparison}
                  contentChange={contentChange}
                  speechChange={speechChange}
              />
          }
          {
            (summaryLoading) ?
              <>
                <WhiteBox>
                    <Skeleton
                        variant='rectangular' height={300} sx={{ borderRadius: '4px' }}
                    />
                </WhiteBox>
                <WhiteBox>
                    <Skeleton
                        variant='rectangular' height={300} sx={{ borderRadius: '4px' }}
                    />
                </WhiteBox>
              </> :
              <>
                {
                  (!mobile && !isDemoApp)
                    ? <GraphAnalysis template={template} parameter={parameter} />
                    : null
                }

                {/* {
                  (!mobile && currentAttemptNo > 1 && !isGuestApp)
                      ? <Gamification
                          currentAttemptNo={currentAttemptNo}
                          disableRetake={!showPracticeButton}
                          handlePracticeAgain={handlePracticeAgain}
                      />
                      : null
                } */}

                <WhiteBox>
                  <Analysis />
                  <br />
                  {
                    (isGuestApp)
                      ? null
                      : <Box className={classes.buttonContainer}>
                          <CustomButton
                              variant='outlined'
                              size="large"
                              onClick={handleOverallPerformance}
                              startIcon={
                                <img width={24} src='https://assets.languify.in/images/chevron-left-icon.svg' alt='overall'/>
                              }
                              sx={{ mx: 2, }}
                          >
                              Overall Performance
                          </CustomButton>
                          {
                            showPracticeButton ?
                              <BootstrapTooltip
                                title={'Take one more attempt to boost your score'}
                                placement='top'
                              >
                                <Box>
                                  <CustomButton
                                      variant='contained'
                                      size="large"
                                      onClick={handlePracticeAgain}
                                      startIcon={
                                        <img width={24} src='https://assets.languify.in/images/retake-icon.svg' alt='retake' />
                                      }
                                      disabled={removedFromBatch}
                                      sx={{ mx: 2, }}
                                  >
                                      Practice Again
                                  </CustomButton> 
                                </Box>
                              </BootstrapTooltip>
                              : null
                          }
                      </Box>
                  }
                </WhiteBox>
              </>
          }

          {(!mobile && !isGuestApp) &&
            <Box className={classes.rateusContainer} onClick={() => setOpenFeedback(true)}>
                <Typography
                    variant="body01-bold" color='primary.clr-500'
                    className={classes.rateusTitle}
                >
                    FEEDBACK
                </Typography>
            </Box>
          }

          {
            (mobile || isGuestApp) ? null :
                <ReplayTutorial start={() => setOpenWelcomeDialog(true)} steps={FeedbackTourSteps} />
          }

          <RateExperienceDialog opendialog={openFeedback} setOpenFeedback={setOpenFeedback} />

          <TourStartDialog
            open={openWelcomeDialog}
              onClose={setOpenWelcomeDialog}
              title="Platform tutorial"
              description="Take a moment to tour the platform with us and learn how it can help you achieve your dream job"
          />

          <StartAssessmentDialog
            onClose={() => setAssessment(undefined)}
            open={!!assessment}
            template={assessment}
            {...(assessment?.metadata?.personalizedTemplateType === 'exam'
                ? { dafInterview: true }
                : assessment?.type === 'personalized'
                ? assessment.personalizedTemplateDraftId === null
                ? { userMock: true }
                : { clientMock: true }
                : {})}
          />


          <AnalysisFailedDialog
              open={analysisFailed}
              onClose={() => setAnalysisFailed(false)}
          />
          <SubscribeDialog open={openSubscribeDialog} />
        </>
    );
}

export default PerformanceReview;