import React from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import CenterFlexBox from 'components/CenterFlexBox';
import makeStyles from "@mui/styles/makeStyles";
import CustomButton from 'components/CustomButton';

import MUITooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const Tooltip = styled(({ className, ...props }) => (
    <MUITooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.shades['clr-white-900'],
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(12),
        borderRadius: 8,
        boxShadow: '-4px 4px 12px rgba(0, 0, 0, 0.1)',
        padding: theme.spacing(5)
    },
}));

const useStyles = makeStyles(theme => ({
    proctorSuccess: {
        backgroundColor: theme.palette.success['clr-100'], width: '100%', height: '100%',
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    },
    proctorWarning: {
        backgroundColor: theme.palette.danger['clr-100'], width: '100%', height: '100%',
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    },
    voilations: {
        padding: theme.spacing(0, 2),
        margin: 0,
        marginLeft: theme.spacing(2),
        ...theme.typography['body01-medium'],
        color: theme.palette.neutral['clr-700']
    }
}))

export function ProctorCell({ proctoringRecords = [], onViewCapturedImage }) {
    const classes = useStyles();
    let hasViolation = false;
    let moveAwayCount = 0;
    let tabSwitchCount = 0;
    let cameraDisabled = 0;
    let hasImageProctor = false;

    if (proctoringRecords.length) {
        proctoringRecords.forEach((pr) => {
            if (pr.type === 'CAMERA_DISABLED') {
                cameraDisabled++;
            }
            if (pr.type === 'IMAGE_PROCTOR') {
                hasImageProctor = true;
            }
            if (pr.type === 'GENERAL_PROCTOR') {
                if (pr.moveAwayCount > 0 || pr.tabSwitchCount > 0) {
                    hasViolation = true;
                    moveAwayCount = pr.moveAwayCount;
                    tabSwitchCount = pr.tabSwitchCount;
                }
            }
        });
    }

    return hasViolation ? (
        <Tooltip
            placement='right'
            title={
                <React.Fragment>
                    <CenterFlexBox justifyContent='flex-start' flexDirection='column'>
                        <WarningIcon color='error' /> &nbsp;&nbsp;
                        <Typography variant='h6-medium' color='warning.main'>
                            Violation Found
                        </Typography>
                    </CenterFlexBox>
                    <br />

                    <ul className={classes.voilations}>
                        {moveAwayCount !== 0 && <li style={{ marginBottom: 4 }}>
                            {moveAwayCount} times move away from the test screen.
                        </li>
                        }
                        {tabSwitchCount !== 0 && <li>
                            {tabSwitchCount} times switch between the tabs.
                        </li>
                        }
                        {cameraDisabled !== 0 && (<li>
                            {cameraDisabled} times disabled the camera.
                        </li>)}
                    </ul>
                    {hasImageProctor &&
                        <>
                            <br />
                            <br />
                            <CustomButton
                                fullWidth
                                size="small"
                                variant='outlined'
                                onClick={() => onViewCapturedImage(proctoringRecords)}
                                style={{ textTransform: 'none' }}
                            >
                                View Captured image
                            </CustomButton>
                        </>
                    }
                </React.Fragment>
            }
        >
            <Box className={classes.proctorWarning}>
                <img
                    src='https://assets.languify.in/images/dangerIcon.svg'
                    alt='danger' 
                />
            </Box>
        </Tooltip>
    ) : (
        <Tooltip
            placement='right'
            title={
                <React.Fragment>
                    <CenterFlexBox justifyContent='flex-start' flexDirection='column'>
                        <VerifiedIcon color='success' /> &nbsp;&nbsp;
                        <Typography variant='h6-medium' color='success.clr-700'>
                            No Violation Found
                        </Typography>
                        <Typography
                            variant='body01-medium' color='neutral.clr-700'
                            textAlign='center' my={3}
                        >
                            User was not found with any violations in the
                            assessment as per the guidelines.
                        </Typography>
                    </CenterFlexBox>
                    {hasImageProctor &&
                        <>
                            <CustomButton
                                fullWidth
                                size="small"
                                variant='outlined'
                                onClick={() => onViewCapturedImage(proctoringRecords)}
                                style={{ textTransform: 'none' }}
                            >
                                View Captured images
                            </CustomButton>
                        </>
                    }
                </React.Fragment>
            }
        >
            <Box className={classes.proctorSuccess}>
                <img
                    src='https://assets.languify.in/images/successIcon.svg'
                    alt='success' 
                />
            </Box>
        </Tooltip>
    );
}