import { useState, useMemo } from "react";
import { WhiteBox, WhiteBoxHeader } from "components/AppShell";
import { SimpleSelect } from "components/CustomSelectFields";
import { PerformanceGraph } from "components/PerformanceGraph";
import { getAverageScore, scrollToElementById } from "utils";
import { ScoreTypes, templateTypes } from "./constants";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import AttemptGraph from "components/AttemptGraph";
import {shallow} from "zustand/shallow";
import useStore from "./store";
import CustomButton from "components/CustomButton";
import StartAssessmentDialog from "dialogs/StartAssessmentDialog";

const useStyles = makeStyles((theme) => ({
  graphContainer: {
    display: 'flex',
    width: '100%',
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between',
    overflow: 'auto',
    '&::-webkit-scrollbar' : {
      height: '10px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F0F5FA',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BFCAD9',
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#8692A3',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#636F7F',
    }
  },
  scoreGraphContainer: {
    minWidth: 800,
  },
  scoreGraphContainerFull: {
    width: '100%',
    minWidth: "100%",
    minHeight: "100%",
  },
  oneAttemptBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.shades["clr-black-900"],
    minWidth: '100%',
    opacity:'50%',
    borderRadius:theme.spacing(1)
  },
  innerBox:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:theme.spacing(2),
    minHeight:'265px',
    backgroundColor:theme.palette.shades["clr-white-900"],
    width:'100%',
    margin:theme.spacing(11,16.5)
  }
}));


function UnlockCard({template, disableAction}) {
  const [startTemplate, setStartTemplate] = useState();

  return (
    <>
      <Box
        height='100%' padding={10} boxShadow='0px 0px 2px 0px #000000BF'
        borderRadius={1} backgroundColor='#424242BF' display='flex' justifyContent='center'
        alignItems='center'
      >
        <Box
          height={'100%'} backgroundColor='white' borderRadius={1} display='flex'
          flexDirection='column' justifyContent='center' alignItems='center' padding='28px 16px' width={400}
        >
          <Typography variant='h6-medium' color='shades.clr-black-900' style={{ textAlign: 'center' }} >
            Track your attempts performance
          </Typography>

          <img src='https://assets.languify.in/images/performace-graph.png' alt='Locked' height={120}/>

          <Typography mt={2} variant="overline-bold" color='primary.clr-300'>
            Each attempt brings you closer to your goal!
          </Typography>

          <Typography mt={3} variant="body01-semiBold" color='success.clr-700' textAlign='center'>
            Take one more attempt to boost your score in speech and content
          </Typography>

          <CustomButton 
            variant="outlined" 
            style={{ marginTop: '18px', width: '100%', }}
            onClick={() => setStartTemplate(template)}
            disabled={disableAction}
          >
            Practice again
          </CustomButton>
        </Box>
      </Box>
      <StartAssessmentDialog
        onClose={() => setStartTemplate(undefined)}
        open={!!startTemplate}
        template={startTemplate}
      />
    </>
  );
}

function AttemptGraphContainer({ allAttempts = [] }) {
  const attemptId = useStore(state => state.attemptId);

  const recentAttempts = useMemo(() => {
    const { index } = allAttempts?.find((attempt) => attempt._id === attemptId) || {};

    return (
      allAttempts.length <= 4 ?
        allAttempts :
        allAttempts.slice(index, index + 4)
    );
  }, [allAttempts, attemptId]);

  return (
    <WhiteBox style={{height: '100%', padding: '24px 20px'}}>
      <WhiteBoxHeader
        id="attempts-performance"
        heading='Attempts performance comparison'
        subheading='Compare your attempts & track progress'
        subheadingVariant="h6-medium"
      />
      <Box mt={8}>
        <AttemptGraph
          recentAttempts={recentAttempts}
          currentAttempt={attemptId}
        />
      </Box>
    </WhiteBox>
  )
}

export default function GraphAnalysis(props) {
  const classes = useStyles();

  const allAttempts = useStore(state => state.allAttempts, shallow);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion, shallow);
  const questions = useStore(state => state.questions, shallow);
  const isGuestApp = useStore(state => state.isGuestApp, shallow);
  const template = props.template || {};

  const handleQuesChange = (e) => {
    setCurrentQuestion(e);
    scrollToElementById('question-section')
  }

  const [scoreType, setScoreType] = useState({ _id: 'overall', name: 'Overall' });

  return (
    <Box display='flex' gap='12px' minHeight='fit-content' 
      height={template.type === templateTypes.ASSIGNMENT ? '480px' : '450px'}
    >
      <WhiteBox 
        style={{
          width: template.type === templateTypes.ASSIGNMENT ? '100%' : '55%', padding: '24px 20px'
        }} 
        id="attempts-performance-container"
      >
        <WhiteBoxHeader
          id="attempts-performance"
          heading='Question specific performance'
          subheading='Performance trends in individual questions'
          subheadingVariant="h6-medium"
        >
          <SimpleSelect
            label='Score type'
            options={ScoreTypes}
            onSelect={(data) => setScoreType(data)}
            value={scoreType}
            defaultValue={scoreType}
          />
        </WhiteBoxHeader>

        <Box className={classes.graphContainer} mt={8}>
          <Box
            className={
              template.type === templateTypes.ASSIGNMENT 
              ? classes.scoreGraphContainerFull 
              : classes.scoreGraphContainer
            }
          >
            <PerformanceGraph
              hideHeader
              xLabel="Questions"
              onClick={handleQuesChange}
              labelPrefix="Q"
              data={questions?.map((question) => {
                const communication = question?.communication?.ratings?.OVERALL || 0;
                const content = question?.content?.ratings?.OVERALL || 0;
                return {
                  communication,
                  content,
                  overall: getAverageScore(communication, content),
                  _id: question._id,
                };
              })}
              tooltipCallbacks={(index) => {
                return (`Q${index + 1}. ${questions?.[index]?.question?.length <= 35 ?
                  questions?.[index]?.question
                  :
                  questions?.[index].question?.slice(0, 35) + "..."
                  }`)
              }}
              parameter={scoreType}
              aspectRatio={template.type === templateTypes.ASSIGNMENT ? 4 : 3}
            />
          </Box>
        </Box>
        </WhiteBox>

        {
          template.type === templateTypes.ASSIGNMENT ? null :
            <Box style={{width:'45%'}}>
              {
                allAttempts?.length === 1 
                  ? <UnlockCard template={template} disableAction={isGuestApp}/> 
                  : <AttemptGraphContainer
                      allAttempts={allAttempts}
                      template={template}
                    />
              }
            </Box>
        }
    </Box>
  );
}